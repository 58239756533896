import { useMoralis } from "react-moralis";
import Moralis from "moralis-v1";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  MenuList,
  Stack,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import metamaskLogo from "assets/img/metamask-logo.png";
import { useState } from "react";
import ToastUtils from "helpers/toaster";

export default function ConnectWalletDialog({ showDialog, onDialogClose }) {
  const { authenticate, logout, enableWeb3 } = useMoralis();

  const [metamaskSelected, setMetamaskSelected] = useState(false);

  const connectMetamask = async () => {
    setMetamaskSelected(true);

    try {
      // Enable web3 to get user address and chain
      await enableWeb3({ throwOnError: true, provider: "metamask" });
      const { account, chainId } = Moralis;

      if (!account) {
        throw new Error(
          "Connecting to chain failed, as no connected account was found"
        );
      }
      if (!chainId) {
        throw new Error(
          "Connecting to chain failed, as no connected chain was found"
        );
      }

      // Get message to sign from the auth api
      const { message } = await Moralis.Cloud.run("requestMessage", {
        address: account,
        chain: parseInt(chainId, 16),
        network: "evm",
      });

      // Authenticate and login via parse
      const response = await authenticate({
        signingMessage: message,
        throwOnError: true,
      });

      if (response) {
        onDialogClose();
      } else {
        logout();
      }
    } catch (error) {
      ToastUtils.toastUtil("error", error.message || JSON.stringify(error));
      logout();
    } finally {
      setMetamaskSelected(false);
    }
  };

  return (
    <Dialog
      open={showDialog}
      onClose={onDialogClose}
      aria-labelledby="connect-wallet-dialog-title"
      aria-describedby="connect-wallet-dialog-description"
      disableScrollLock
    >
      <DialogTitle id="connect-wallet-dialog-title">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <h3 style={{ margin: "0px" }}>Connect Wallet</h3>
          </Stack>
          <IconButton
            onClick={onDialogClose}
            style={{ marginRight: "-16px", marginTop: "-16px" }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {metamaskSelected ? (
          <Stack direction="column" alignItems="center">
            <img
              src={metamaskLogo}
              alt="Metamask Logo"
              style={{ width: "48px", marginTop: "24px" }}
            ></img>
            <h3 style={{ marginTop: "8px" }}>Metamask</h3>
            <DialogContentText textAlign="center">
              Please sign the message to connect on your wallet.
            </DialogContentText>
          </Stack>
        ) : (
          <MenuList>
            <MenuItem onClick={connectMetamask}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                minWidth="256px"
              >
                <img
                  src={metamaskLogo}
                  alt="Metamask Logo"
                  style={{ width: "48px" }}
                ></img>
                <Stack width="100%" marginLeft="16px">
                  <h4>Metamask</h4>
                </Stack>
              </Stack>
            </MenuItem>
          </MenuList>
        )}
      </DialogContent>
    </Dialog>
  );
}
