import { useRef, useState } from "react";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import "assets/styles/piny.css";
import { useHistory } from "react-router-dom";

export default function CreateItem() {
  const history = useHistory();

  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnchorRef = useRef(null);

  const handleCreateItemClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <div
      id="create-item-div"
      ref={menuAnchorRef}
      aria-controls={menuOpen ? "create-menu" : undefined}
      aria-expanded={menuOpen ? "true" : undefined}
      aria-haspopup="true"
      onClick={handleCreateItemClick}
      style={{ cursor: "pointer" }}
    >
      <span className="headerMenuItem">Create</span>
      <Popper
        open={menuOpen}
        anchorEl={menuAnchorRef.current}
        role={undefined}
        placement="bottom"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList id="create-menu">
                  <MenuItem onClick={() => history.push(`/create/marker`)}>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      padding="8px 0px 8px 0px"
                    >
                      <AddLocationAltOutlinedIcon
                        style={{ width: "26px", height: "26px" }}
                      />
                      <span style={{ marginLeft: "4px" }}>Create Marker</span>
                    </Stack>
                  </MenuItem>
                  <MenuItem onClick={() => history.push(`/create/event`)}>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      padding="8px 0px 8px 0px"
                    >
                      <GroupAddIcon style={{ width: "26px", height: "26px" }} />
                      <span style={{ marginLeft: "4px" }}>Create Event</span>
                    </Stack>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
