import styled from "@emotion/styled";
import { Container, Stack, Typography, useMediaQuery } from "@mui/material";
import Header from "./header";
import logoWhite from "assets/img/logo/piny-logo-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faDiscord,
  faFacebook,
  faTelegram,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";

export const Flex = styled.div`
  display: flex;
`;

export default function Layout({ children }) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      style={{
        width: "100%",
        background:
          isSmall || location.pathname === "/"
            ? "white"
            : "linear-gradient(rgb(232 220 232) 0%, rgb(248 245 248) 100%) 0% 0% no-repeat padding-box padding-box white",
      }}
    >
      <Header />
      <div
        style={{
          marginTop: "80px",
          paddingBottom: isSmall ? "0px" : "80px",
          minHeight: isSmall ? "calc(100vh - 331px)" : "calc(100vh - 418px)",
        }}
      >
        {children}
      </div>
      <Stack
        sx={{
          backgroundColor: "rgba(133,41,153,1.00)",
          minHeight: "80px",
          padding: "48px 16px 40px 16px",
        }}
      >
        <Container maxWidth="md" sx={{ color: "white" }}>
          <Stack alignItems="center" width="100%">
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
              width="100%"
            >
              <a
                href="https://twitter.com"
                style={{ textDecoration: "none", color: "white" }}
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  style={{ fontSize: isSmall ? "24px" : "32px" }}
                />
              </a>
              <a
                href="https://twitter.com"
                style={{ textDecoration: "none", color: "white" }}
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ fontSize: isSmall ? "24px" : "32px" }}
                />
              </a>
              <a
                href="https://twitter.com"
                style={{ textDecoration: "none", color: "white" }}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ fontSize: isSmall ? "24px" : "32px" }}
                />
              </a>
              <a
                href="https://twitter.com"
                style={{ textDecoration: "none", color: "white" }}
              >
                <FontAwesomeIcon
                  icon={faTelegram}
                  style={{ fontSize: isSmall ? "24px" : "32px" }}
                />
              </a>
              <a
                href="https://twitter.com"
                style={{ textDecoration: "none", color: "white" }}
              >
                <FontAwesomeIcon
                  icon={faDiscord}
                  style={{ fontSize: isSmall ? "24px" : "32px" }}
                />
              </a>
            </Stack>
            <img
              src={logoWhite}
              alt="logo"
              style={{ height: "48px", marginTop: "64px" }}
            ></img>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", marginTop: "8px" }}
            >
              All rights reserved © 2023
            </Typography>
          </Stack>
        </Container>
      </Stack>
    </div>
  );
}
