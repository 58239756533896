export default function SquareMarkerIcon({ style }) {
  return (
    <div style={{ width: "24px", height: "24px", ...style }}>
      <svg viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H16L12,22L8,18H4A2,2 0 0,1 2,16V4A2,2 0 0,1 4,2M4,4V16H8.83L12,19.17L15.17,16H20V4H4Z"
        />
      </svg>
    </div>
  );
}
