import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";

export default function SuccessDialog({ showDialog, message, onCloseDialog }) {
  return (
    <Dialog open={showDialog} disableScrollLock>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <h3 style={{ margin: "0px" }}>Done</h3>
          </Stack>
          <IconButton
            onClick={() => {
              onCloseDialog();
            }}
            style={{ marginRight: "-16px", marginTop: "-16px" }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center">
          <TaskAltOutlinedIcon
            color="success"
            sx={{ width: "80px", height: "80px" }}
          />
          <DialogContentText sx={{ marginTop: "16px", textAlign: "center" }}>
            {message ? message : "Successfully processed"}
          </DialogContentText>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
