import { getChain, useMoralis } from "react-moralis";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { useEffect, useState } from "react";
import { getNetworkLogo } from "helpers/networks";
import { Link } from "react-router-dom";

export default function InvalidNetworkDialog({
  validChainId,
  cancelLink,
  cancelAction,
}) {
  const [showDialog, setShowDialog] = useState(false);

  const { chainId } = useMoralis();

  useEffect(() => {
    if (!validChainId || !chainId) {
      setShowDialog(false);
    }

    if (validChainId !== chainId) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
    }
  }, [validChainId, chainId]);

  return (
    <>
      {validChainId && chainId ? (
        <Dialog
          open={showDialog}
          aria-labelledby="invalid-network-dialog-title"
          aria-describedby="invalid-network-dialog-description"
          disableScrollLock
        >
          <DialogTitle
            id="invalid-network-dialog-title"
            sx={{ textAlign: "center" }}
          >
            Invalid Network
          </DialogTitle>
          <DialogContent>
            <Stack alignItems="center">
              <DangerousOutlinedIcon
                color="error"
                sx={{ width: "80px", height: "80px", marginTop: "16px" }}
              />
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                You are connected to
              </DialogContentText>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: "24px", marginTop: "16px" }}
              >
                <img
                  src={getNetworkLogo(chainId)}
                  alt={getChain(chainId).name}
                  style={{
                    maxWidth: "32px",
                    maxHeight: "32px",
                    borderRadius: "50%",
                  }}
                />
                <h4 style={{ marginLeft: "8px" }}>{getChain(chainId).name}</h4>
              </Stack>
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Please connect to
              </DialogContentText>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: "24px", marginTop: "16px" }}
              >
                <img
                  src={getNetworkLogo(validChainId)}
                  alt={getChain(validChainId).name}
                  style={{
                    maxWidth: "32px",
                    maxHeight: "32px",
                    borderRadius: "50%",
                  }}
                />
                <h4 style={{ marginLeft: "8px" }}>
                  {getChain(validChainId).name}
                </h4>
              </Stack>
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "16px" }}
              >
                to continue.
              </DialogContentText>
            </Stack>
          </DialogContent>
          {cancelLink || cancelAction ? (
            <DialogActions sx={{ padding: "16px", justifyContent: "center" }}>
              {cancelLink ? (
                <Link to={cancelLink} className="buttonLink">
                  <Button variant="outlined" onClick={() => {}}>
                    Cancel
                  </Button>
                </Link>
              ) : cancelAction ? (
                <Button variant="outlined" onClick={cancelAction}>
                  Cancel
                </Button>
              ) : null}
            </DialogActions>
          ) : null}
        </Dialog>
      ) : null}
    </>
  );
}
