import DoneIcon from "@mui/icons-material/Done";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ConnectWalletButton from "components/common/connectWalletButton";
import ProcessingIcon from "components/common/processingIcon";
import MarkerEditor from "components/markerEditor";
import { DEFAULT_MARKER_IMAGE_DATA } from "components/markerEditor/defaultImages";
import {
  getPinyWorldMintListenerAddress,
  getPinyWorldNftAddress,
} from "constant/ContractAddress";
import erc20abi from "contracts/abi/ERC20.json";
import mintlistenerabi from "contracts/abi/PinyWorldMintListener.json";
import pinyworldnftabi from "contracts/abi/PinyWorldNFT.json";
import {
  getEllipsisTxt,
  tokenValue,
  toTokenValue,
  ZERO_ADDRESS,
} from "helpers/formatters";
import { addMarkerToLocalStorage } from "helpers/localStorage";
import { getNetworkLogo, networkConfigs } from "helpers/networks";
import { useCallback, useEffect, useState } from "react";
import Blockies from "react-blockies";
import { getChain, useMoralis } from "react-moralis";
import { Link } from "react-router-dom";
import L from "leaflet";
import "assets/styles/piny.css";
import { getTokenMetadata } from "helpers/tokens";
import ToastUtils from "helpers/toaster";
import { computePaymentData } from "helpers/tokens";
import { freeMintTokenPaymentMethod } from "constant/paymentSettingTokens";
import { freeMintToken } from "constant/paymentSettingTokens";
import LeafletMap from "components/leafletMap";
import { Marker } from "react-leaflet";

export default function CreateMarkerPage({ props }) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const isVerySmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { Moralis, isWeb3Enabled, isAuthenticated, account, chainId } =
    useMoralis();

  const [markerName, setMarkerName] = useState("");
  const [markerDescription, setMarkerDescription] = useState("");
  const [markerImage, setMarkerImage] = useState(DEFAULT_MARKER_IMAGE_DATA);
  const [markerDragging, setMarkerDragging] = useState();
  const [markerPosition, setMarkerPosition] = useState(null);
  const [mapZoom, setMapZoom] = useState(3);
  const [showMarkerEditor, setShowMarkerEditor] = useState(false);
  const [mapMarker, setMapMarker] = useState();

  const [createMarkerPaymentSettingList, setCreateMarkerPaymentSettingList] =
    useState(null);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [checkingPaymentApproval, setCheckingPaymentApproval] = useState(false);
  const [approvingPayment, setApprovingPayment] = useState(false);
  const [showApprovingPaymentDialog, setShowApprovingPaymentDialog] =
    useState(false);
  const [paymentApproved, setPaymentApproved] = useState(false);
  const [accountFreeMintCount, setAccountFreeMintCount] = useState(0);

  const [showSubmitMarkerDialog, setShowSubmitMarkerDialog] = useState(false);
  const [submitMarkerConfirmed, setSubmitMarkerConfirmed] = useState(false);
  const [uploadingImageToIPFS, setUploadingImageToIPFS] = useState(false);
  const [submittingMarker, setSubmittingMarker] = useState(false);
  const [createdMarker, setCreatedMarker] = useState(false);
  const [redirectPageAfterCreate, setRedirectPageAfterCreate] = useState("");

  const computeMarkerScaledSize = useCallback(() => {
    if (!mapZoom || mapZoom * 16 < 48) {
      return 48;
    }

    if (mapZoom * 16 > 160) {
      return 160;
    }

    return mapZoom * 16;
  }, [mapZoom]);

  const fetchCreateMarkerPaymentSettingList = useCallback(async () => {
    if (!isWeb3Enabled || !isAuthenticated) {
      setCreateMarkerPaymentSettingList(null);
      return;
    }

    let options = {
      contractAddress: getPinyWorldMintListenerAddress(chainId),
      functionName: "getPaymentSettingList",
      abi: mintlistenerabi,
    };

    const paymentSettingList = await Moralis.executeFunction(options);

    const paymentSettingWithTokenInfoList = [];

    await Promise.all(
      paymentSettingList.map(async (ps) => {
        let tokenMetadata = await getTokenMetadata(
          Moralis,
          ps.tokenAddress,
          chainId
        );

        const transferAmount = tokenValue(
          ps.paymentSetting.transferAmount,
          tokenMetadata.decimals
        );

        if (transferAmount > 0) {
          paymentSettingWithTokenInfoList.push({
            token: tokenMetadata,
            amount: transferAmount,
            paymentAddress: ps.paymentSetting.transferAddress,
          });
        }
      })
    );

    paymentSettingWithTokenInfoList.sort((ps1, ps2) => {
      if (ps1 && ps1.token && ps1.token.symbol && ps2 && ps2.token) {
        return ps1.token.symbol.localeCompare(ps2.token.symbol);
      }

      return 0;
    });

    setCreateMarkerPaymentSettingList(paymentSettingWithTokenInfoList);
  }, [Moralis, chainId, isAuthenticated, isWeb3Enabled]);

  const checkPaymentApproval = useCallback(async () => {
    if (!paymentMethod || !isAuthenticated) {
      setCheckingPaymentApproval(false);
      return;
    }

    if (paymentMethod.token.address === freeMintToken.address) {
      setCheckingPaymentApproval(false);
      setPaymentApproved(true);
      return;
    }

    setCheckingPaymentApproval(true);
    setPaymentApproved(false);
    setInsufficientBalance(false);

    if (paymentMethod.token.address !== ZERO_ADDRESS) {
      let options = {
        contractAddress: paymentMethod.token.address,
        functionName: "allowance",
        abi: erc20abi,
        params: {
          owner: account,
          spender: getPinyWorldMintListenerAddress(chainId),
        },
      };

      const allowance = tokenValue(
        await Moralis.executeFunction(options),
        paymentMethod.token.decimals
      );

      if (allowance >= paymentMethod.amount) {
        setPaymentApproved(true);
      }

      options = {
        contractAddress: paymentMethod.token.address,
        functionName: "balanceOf",
        abi: erc20abi,
        params: {
          account: account,
        },
      };

      const balance = tokenValue(
        await Moralis.executeFunction(options),
        paymentMethod.token.decimals
      );

      if (balance < paymentMethod.amount) {
        setInsufficientBalance(true);
      }
    } else if (paymentMethod.token.address === ZERO_ADDRESS) {
      setPaymentApproved(true);

      const options = { chain: chainId };
      const balance = tokenValue(
        (await Moralis.Web3API.account.getNativeBalance(options))["balance"],
        paymentMethod.token.decimals
      );

      if (balance < paymentMethod.amount) {
        setInsufficientBalance(true);
      }
    }

    setCheckingPaymentApproval(false);
  }, [Moralis, account, isAuthenticated, chainId, paymentMethod]);

  const fetchAccountFreeMintCount = useCallback(async () => {
    if (!account) {
      if (accountFreeMintCount > 0) {
        setAccountFreeMintCount(0);
      }

      return;
    }

    let options = {
      contractAddress: getPinyWorldMintListenerAddress(chainId),
      functionName: "noMintFeeMap",
      abi: mintlistenerabi,
      params: {
        _address: account,
      },
    };

    setAccountFreeMintCount(await Moralis.executeFunction(options));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    fetchCreateMarkerPaymentSettingList();
  }, [fetchCreateMarkerPaymentSettingList]);

  useEffect(() => {
    checkPaymentApproval();
  }, [checkPaymentApproval]);

  useEffect(() => {
    fetchAccountFreeMintCount();
  }, [fetchAccountFreeMintCount]);

  const onMarkerNameChange = (event) => {
    setMarkerName(event.target.value);
  };

  const onMarkerDescriptionChange = (event) => {
    setMarkerDescription(event.target.value);
  };

  const onMapClick = (event) => {
    if (!markerDragging) {
      setMarkerPosition({ lat: event.latlng.lat, lng: event.latlng.lng });
    }
  };

  const onPaymentMethodChange = (event) => {
    if (event.target.value === "0x1") {
      setSelectedPaymentIndex(-1);
      return;
    }

    if (
      !createMarkerPaymentSettingList ||
      event.target.value > createMarkerPaymentSettingList.length
    ) {
      return;
    }

    setSelectedPaymentIndex(parseInt(event.target.value));
  };

  const approvePaymentMethod = async () => {
    if (!paymentMethod || !paymentMethod.token) {
      return;
    }

    setApprovingPayment(true);
    setShowApprovingPaymentDialog(true);

    const options = {
      contractAddress: paymentMethod.token.address,
      functionName: "approve",
      abi: erc20abi,
      params: {
        spender: getPinyWorldMintListenerAddress(chainId),
        amount: toTokenValue(
          paymentMethod.amount,
          paymentMethod.token.decimals
        ),
      },
    };

    try {
      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();

      setPaymentApproved(true);
    } catch (err) {
      if (err && err.code !== 4001) {
        ToastUtils.toastUtil("error", JSON.stringify(err));
      }
      return;
    } finally {
      setApprovingPayment(false);
      setShowApprovingPaymentDialog(false);
    }
  };

  const onSubmitButtonClicked = () => {
    setShowSubmitMarkerDialog(true);
  };

  const submitCreateMarker = async () => {
    if (
      !createMarkerPaymentSettingList ||
      (createMarkerPaymentSettingList.length > 0 && !paymentMethod)
    ) {
      return;
    }

    setSubmitMarkerConfirmed(true);
    setUploadingImageToIPFS(true);

    let imageIpfsPath, metadata, metadataIpfsPath;

    try {
      const imageFile = new Moralis.File("image.png", {
        base64: markerImage,
      });

      let path = await Moralis.Web3API.storage.uploadFolder({
        abi: [
          {
            path: "image.png",
            content: imageFile._data,
          },
        ],
      });

      // Get IPFS path from https://ipfs.moralis.io:2053/ipfs/QmRGnnNvpMD54ieTh96s7ECk9wrANkZ4H6hYGpHzEaHbHh/image.png
      imageIpfsPath = path[0].path.substring(34);

      metadata = {
        name: markerName,
        description: markerDescription,
        image: "ipfs://" + imageIpfsPath,
        attributes: [
          { trait_type: "Latitude", value: mapMarker.props.position.lat },
          {
            trait_type: "Longitude",
            value: mapMarker.props.position.lng,
          },
          { trait_type: "Map Zoom", value: mapZoom },
        ],
      };

      path = await Moralis.Web3API.storage.uploadFolder({
        abi: [
          {
            path: "metadata.json",
            content: metadata,
          },
        ],
      });

      metadataIpfsPath = path[0].path.substring(34);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
      setSubmittingMarker(false);
      setSubmitMarkerConfirmed(false);
      return;
    } finally {
      setUploadingImageToIPFS(false);
    }

    const options = {
      contractAddress: getPinyWorldNftAddress(chainId),
      functionName: "mint",
      abi: pinyworldnftabi,
      params: {
        _markerProps: [
          markerName,
          markerDescription,
          "" + mapMarker.props.position.lat,
          "" + mapMarker.props.position.lng,
          mapZoom,
          imageIpfsPath,
        ],
        _token: paymentMethod ? paymentMethod.token.address : ZERO_ADDRESS,
        _tokenURI: metadataIpfsPath,
      },
    };

    if (paymentMethod && paymentMethod.token.address === ZERO_ADDRESS) {
      options["msgValue"] = toTokenValue(
        paymentMethod.amount,
        paymentMethod.token.decimals
      );
    }

    setSubmittingMarker(true);

    try {
      const transaction = await Moralis.executeFunction(options);
      const result = await transaction.wait();

      setCreatedMarker(true);
      setRedirectPageAfterCreate(`/marker`);

      const mintEvent = result.events.find(
        (e) => e.event === "MintPinyWorldNFT"
      );

      if (
        mintEvent &&
        mintEvent.args &&
        mintEvent.args.id &&
        mintEvent.args.id.toString
      ) {
        const markerId = parseInt(mintEvent.args.id.toString());

        addMarkerToLocalStorage(networkConfigs[chainId].code, markerId, {
          ...metadata,
          minter: account,
          imageUrl: imageIpfsPath,
          tokenURI: metadataIpfsPath,
          mintPaymentData: computePaymentData(paymentMethod, chainId),
          createTime: new Date().getTime(),
          transactionHash: mintEvent.transactionHash,
        });

        setRedirectPageAfterCreate(
          `/marker/${networkConfigs[chainId].code}/${markerId}`
        );
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
      return;
    } finally {
      setSubmitMarkerConfirmed(false);
      setSubmittingMarker(false);
    }
  };

  useEffect(() => {
    if (!markerPosition || !markerImage) {
      setMapMarker(undefined);
      return;
    }

    const calcIconSize = computeMarkerScaledSize();

    setMapMarker(
      <Marker
        key="1"
        icon={
          new L.Icon({
            iconUrl: markerImage,
            iconSize: [calcIconSize, calcIconSize],
            iconAnchor: [calcIconSize / 2, calcIconSize],
          })
        }
        position={markerPosition}
        eventHandlers={{
          dragstart() {
            setMarkerDragging(true);
          },
          dragend(event) {
            setMarkerPosition({
              lat: event.target.getLatLng().lat,
              lng: event.target.getLatLng().lng,
            });

            setTimeout(() => {
              setMarkerDragging(false);
            }, 100);
          },
        }}
        draggable
      />
    );
  }, [markerImage, markerPosition, computeMarkerScaledSize]);

  useEffect(() => {
    if (selectedPaymentIndex === -1) {
      setPaymentMethod(freeMintTokenPaymentMethod);
    } else if (
      !createMarkerPaymentSettingList ||
      !createMarkerPaymentSettingList.length ||
      selectedPaymentIndex >= createMarkerPaymentSettingList.length
    ) {
      setPaymentMethod(null);
    } else {
      setPaymentMethod(createMarkerPaymentSettingList[selectedPaymentIndex]);
    }
  }, [createMarkerPaymentSettingList, selectedPaymentIndex]);

  return (
    <Container
      maxWidth="md"
      sx={{ backgroundColor: "white", paddingBottom: "48px" }}
    >
      <Stack>
        <h1>Create Marker</h1>

        <h3 className="formLabel">Name</h3>
        <TextField
          id="marker-name-input"
          variant="outlined"
          inputProps={{ maxLength: 64 }}
          value={markerName}
          onChange={onMarkerNameChange}
        />
        <span className="formInfo">Maximum 64 characters.</span>

        <h3 className="formLabel">Message</h3>
        <TextField
          id="marker-message-input"
          variant="outlined"
          multiline
          rows={4}
          inputProps={{ maxLength: 1024 }}
          value={markerDescription}
          onChange={onMarkerDescriptionChange}
        />
        <span className="formInfo">Maximum 1024 characters.</span>

        <h3 className="formLabel">Marker Image</h3>
        <Stack flexDirection={isSmall ? "column" : "row"} alignItems="center">
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            width="336px"
            height="336px"
            border="2px gray dashed"
            padding="8px"
            borderRadius="8px"
          >
            <img
              src={markerImage}
              alt="Marker"
              style={{ maxWidth: "336px", maxHeight: "336px" }}
            />
          </Stack>

          <Button
            variant="outlined"
            startIcon={<EditLocationOutlinedIcon />}
            style={{
              marginLeft: isSmall ? "" : "16px",
              marginTop: isSmall ? "16px" : "",
            }}
            onClick={() => {
              setShowMarkerEditor(true);
            }}
          >
            Edit
          </Button>
        </Stack>

        <h3
          className="formLabel"
          style={{ marginBottom: "0px", lineHeight: "1.5" }}
        >
          Location
        </h3>
        <span
          className="formInfo"
          style={{ marginTop: "0px", marginBottom: "4px" }}
        >
          Click on the map or drag marker to change location
        </span>

        <LeafletMap
          userLocation
          onClick={onMapClick}
          onZoomEnd={(map) => {
            setMapZoom(map.getZoom());
          }}
          whenReady={(map) => {
            setMarkerPosition({
              lat: map.getCenter().lat,
              lng: map.getCenter().lng,
            });
          }}
          style={{ borderRadius: "8px", height: "480px" }}
        >
          {mapMarker}
        </LeafletMap>

        {isAuthenticated && account ? (
          <>
            <h3 className="formLabel">Creator</h3>
            <Stack direction="row" alignItems="center">
              <Blockies seed={account.toLowerCase()} className="blockie" />
              <h4 style={{ margin: "0 0 0 8px " }}>
                {isVerySmall ? getEllipsisTxt(account, 15) : account}
              </h4>
            </Stack>
            <h3 className="formLabel">Network</h3>
            <Stack direction="row" alignItems="center">
              <img
                src={getNetworkLogo(chainId)}
                alt={getChain(chainId).name}
                style={{
                  maxWidth: "32px",
                  maxHeight: "32px",
                  borderRadius: "50%",
                }}
              />
              <h4 style={{ margin: "0 0 0 8px " }}>{getChain(chainId).name}</h4>
            </Stack>
          </>
        ) : null}

        {createMarkerPaymentSettingList &&
        createMarkerPaymentSettingList.length ? (
          <>
            <h3 className="formLabel">Payment Method</h3>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              defaultValue={0}
              onChange={onPaymentMethodChange}
            >
              {createMarkerPaymentSettingList &&
                createMarkerPaymentSettingList.map((ps, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={index}
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                            },
                          }}
                        />
                      }
                      label={`${ps.amount} ${ps.token.symbol}`}
                      sx={{ marginTop: index === 0 ? "-4px" : "-4px" }}
                    />
                  );
                })}
              {createMarkerPaymentSettingList &&
              createMarkerPaymentSettingList.length &&
              accountFreeMintCount > 0 ? (
                <FormControlLabel
                  key="0x1"
                  value="0x1"
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 28,
                        },
                      }}
                    />
                  }
                  label={`Free Mint (${accountFreeMintCount} left)`}
                />
              ) : null}
            </RadioGroup>
          </>
        ) : null}

        <Stack
          flexDirection="row"
          paddingTop="32px"
          alignItems="center"
          justifyContent="center"
        >
          {!isAuthenticated ? (
            <ConnectWalletButton />
          ) : createMarkerPaymentSettingList ? (
            <>
              {paymentMethod ? (
                <Button
                  variant="outlined"
                  color={
                    insufficientBalance
                      ? "error"
                      : paymentApproved
                      ? "success"
                      : "primary"
                  }
                  onClick={approvePaymentMethod}
                  disabled={
                    checkingPaymentApproval ||
                    insufficientBalance ||
                    paymentApproved ||
                    approvingPayment ||
                    createdMarker
                  }
                  endIcon={
                    checkingPaymentApproval || approvingPayment ? (
                      <ProcessingIcon />
                    ) : paymentApproved && !insufficientBalance ? (
                      <DoneIcon />
                    ) : null
                  }
                >
                  {checkingPaymentApproval
                    ? "Checking"
                    : approvingPayment
                    ? "Processing"
                    : insufficientBalance
                    ? "Insufficient Balance"
                    : paymentApproved
                    ? "Approved"
                    : "Approve Payment"}
                </Button>
              ) : null}

              <Button
                variant="outlined"
                onClick={onSubmitButtonClicked}
                disabled={
                  checkingPaymentApproval ||
                  approvingPayment ||
                  (paymentMethod && !paymentApproved) ||
                  insufficientBalance ||
                  submitMarkerConfirmed ||
                  createdMarker ||
                  !isWeb3Enabled
                }
                sx={{ marginLeft: "16px" }}
                endIcon={submitMarkerConfirmed ? <ProcessingIcon /> : null}
              >
                Submit
              </Button>
              {paymentMethod && !createdMarker ? (
                <Dialog open={showApprovingPaymentDialog} disableScrollLock>
                  <DialogTitle>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                      >
                        <h3 style={{ margin: "0px" }}>Approve Payment</h3>
                      </Stack>
                      <IconButton
                        onClick={() => {
                          setShowApprovingPaymentDialog(false);
                        }}
                        style={{ marginRight: "-16px", marginTop: "-16px" }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <Stack flexDirection="column" alignItems="center">
                      <h2>
                        {paymentMethod.amount} {paymentMethod.token.symbol}
                      </h2>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ height: "24px" }}
                      >
                        <img
                          src={getNetworkLogo(chainId)}
                          alt={getChain(chainId).name}
                          style={{
                            maxWidth: "24px",
                            maxHeight: "24px",
                            borderRadius: "50%",
                          }}
                        />
                        <span style={{ marginLeft: "8px" }}>
                          {getChain(chainId).name}
                        </span>
                      </Stack>
                    </Stack>
                    <DialogContentText
                      sx={{
                        paddingTop: "40px",
                        paddingBottom: "8px",
                        textAlign: "center",
                      }}
                    >
                      Please confirm transaction on your wallet
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              ) : null}

              {!createdMarker ? (
                <Dialog open={showSubmitMarkerDialog} disableScrollLock>
                  <DialogTitle>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                      >
                        <h3 style={{ margin: "0px" }}>Confirm</h3>
                      </Stack>
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <Stack flexDirection="column" alignItems="center">
                      <Typography textAlign="center">
                        Please be aware that you cannot change
                      </Typography>
                      <Typography variant="h6" textAlign="center">
                        Name
                      </Typography>
                      <Typography variant="h6" textAlign="center">
                        Message
                      </Typography>
                      <Typography variant="h6" textAlign="center">
                        Marker image
                      </Typography>
                      <Typography variant="h6" textAlign="center">
                        Location on the map
                      </Typography>

                      {paymentMethod &&
                      paymentMethod.token.address !== freeMintToken.address ? (
                        <>
                          <Typography textAlign="center">and</Typography>
                          <Typography textAlign="center">
                            you will pay
                          </Typography>
                          <Typography
                            variant="h5"
                            textAlign="center"
                            sx={{ margin: "8px" }}
                          >
                            {paymentMethod.amount} {paymentMethod.token.symbol}
                          </Typography>
                        </>
                      ) : null}

                      <Typography textAlign="center">
                        after confirming transaction on your wallet
                      </Typography>
                      <Typography
                        textAlign="center"
                        style={{
                          marginTop: "16px",
                          marginBottom: "4px",
                          textAlign: "center",
                        }}
                      >
                        Marker is being created on
                      </Typography>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ height: "24px" }}
                      >
                        <img
                          src={getNetworkLogo(chainId)}
                          alt={getChain(chainId).name}
                          style={{
                            maxWidth: "24px",
                            maxHeight: "24px",
                            borderRadius: "50%",
                          }}
                        />
                        <span style={{ marginLeft: "8px" }}>
                          {getChain(chainId).name}
                        </span>
                      </Stack>
                    </Stack>
                    {uploadingImageToIPFS || submittingMarker ? (
                      <DialogContentText
                        sx={{
                          paddingTop: "40px",
                          paddingBottom: "8px",
                          textAlign: "center",
                        }}
                      >
                        {uploadingImageToIPFS
                          ? "Uploading image to IPFS..."
                          : submittingMarker
                          ? "Please confirm transaction on your wallet"
                          : null}
                      </DialogContentText>
                    ) : null}
                  </DialogContent>
                  <DialogActions sx={{ padding: "16px" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      disabled={submitMarkerConfirmed}
                      onClick={() => {
                        setShowSubmitMarkerDialog(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={submitMarkerConfirmed}
                      endIcon={
                        submitMarkerConfirmed ? <ProcessingIcon /> : null
                      }
                      onClick={submitCreateMarker}
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : (
                <Dialog open={createdMarker} disableScrollLock>
                  <DialogTitle sx={{ textAlign: "center" }}>
                    Marker Created
                  </DialogTitle>
                  <DialogContent>
                    <Stack flexDirection="column" alignItems="center">
                      <WhereToVoteOutlinedIcon
                        sx={{
                          height: "120px",
                          width: "120px",
                          color: "green",
                        }}
                      />
                    </Stack>
                    <DialogContentText
                      sx={{ marginTop: "24px", textAlign: "center" }}
                    >
                      Congratulations!
                    </DialogContentText>
                    <DialogContentText
                      sx={{ marginTop: "16px", textAlign: "center" }}
                    >
                      Your marker is successfully created and pinned.
                    </DialogContentText>
                    <DialogContentText
                      sx={{ marginTop: "16px", textAlign: "center" }}
                    >
                      Click OK to go to your marker's page.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    sx={{ padding: "16px", justifyContent: "center" }}
                  >
                    <Link to={redirectPageAfterCreate} className="buttonLink">
                      <Button variant="outlined" onClick={() => {}}>
                        OK
                      </Button>
                    </Link>
                  </DialogActions>
                </Dialog>
              )}
            </>
          ) : null}
        </Stack>
      </Stack>
      <MarkerEditor
        showDialog={showMarkerEditor}
        onDialogClose={() => {
          setShowMarkerEditor(false);
        }}
        imageSetter={setMarkerImage}
      />
    </Container>
  );
}
