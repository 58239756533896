import { Slider, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import ColorPicker from "../colorPicker";
import { fabric } from "fabric";
import Crop169Icon from "@mui/icons-material/Crop169";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

export default function EditImage({ imageObject, objectToEditSetter }) {
  const [shape, setShape] = useState(imageObject.shape);
  const [opacity, setOpacity] = useState(imageObject.opacity * 100);
  const [border, setBorder] = useState(imageObject.strokeWidth * 10);
  const [borderColor, setBorderColor] = useState(imageObject.stroke);

  const onShapeChange = (e, value) => {
    if (!(value === "0" || value)) {
      return;
    }

    const canvas = imageObject.canvas;

    if (value === "0") {
      const photoFrameFabricObj = new fabric.Rect({
        width: imageObject.fill.source.width,
        height: imageObject.fill.source.height,
        left: imageObject.left,
        top: imageObject.top,
        scaleX: imageObject.scaleX,
        scaleY: imageObject.scaleY,
        fill: imageObject.fill,
        stroke: imageObject.stroke,
        strokeWidth: imageObject.strokeWidth,
        opacity: imageObject.opacity,
        shape: "0",
      });

      canvas.remove(imageObject);
      canvas.add(photoFrameFabricObj);
      canvas.setActiveObject(photoFrameFabricObj);

      objectToEditSetter(photoFrameFabricObj);
    } else if (value === "1") {
      const photoFrameFabricObj = new fabric.Ellipse({
        rx: imageObject.fill.source.width / 2,
        ry: imageObject.fill.source.height / 2,
        left: imageObject.left,
        top: imageObject.top,
        scaleX: imageObject.scaleX,
        scaleY: imageObject.scaleY,
        fill: imageObject.fill,
        stroke: imageObject.stroke,
        strokeWidth: imageObject.strokeWidth,
        opacity: imageObject.opacity,
        shape: "1",
      });

      canvas.remove(imageObject);
      canvas.add(photoFrameFabricObj);
      canvas.setActiveObject(photoFrameFabricObj);

      objectToEditSetter(photoFrameFabricObj);
    }

    canvas.renderAll();

    setShape(value);
  };

  const onOpacityChange = (e) => {
    imageObject.set({ opacity: e.target.value / 100 });
    imageObject.canvas.renderAll();

    setOpacity(e.target.value);
  };

  const onBorderChange = (e) => {
    imageObject.set({ strokeWidth: e.target.value });
    imageObject.canvas.renderAll();

    setBorder(e.target.value);
  };

  const onBorderColorChange = (color) => {
    imageObject.set({ stroke: color });
    imageObject.canvas.renderAll();

    setBorderColor(color);
  };

  useEffect(() => {
    setShape(imageObject.shape);
    setOpacity(imageObject.opacity * 100);
    setBorder(imageObject.strokeWidth);
    setBorderColor(imageObject.stroke);
  }, [imageObject]);

  return (
    <Stack flexDirection="column">
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack width="50%" flexDirection="row" alignItems="center">
          <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>Shape:</h5>
          <ToggleButtonGroup
            color="primary"
            value={shape}
            exclusive
            onChange={onShapeChange}
            size="small"
            sx={{ marginLeft: "4px" }}
          >
            <ToggleButton value="0" key="0">
              <Crop169Icon />
            </ToggleButton>
            <ToggleButton value="1" key="1">
              <RadioButtonUncheckedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Stack
          width="50%"
          flexDirection="row"
          alignItems="center"
          paddingLeft="8px"
        >
          <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>Opacity:</h5>
          <Slider
            sx={{ marginLeft: "12px", marginRight: "16px" }}
            onChange={onOpacityChange}
            value={opacity}
          ></Slider>
        </Stack>
      </Stack>

      <Stack
        flexDirection="row"
        justifyContent="space-between"
        marginTop="16px"
      >
        <Stack width="50%" flexDirection="row" alignItems="center">
          <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>Border:</h5>
          <Slider
            sx={{ marginLeft: "12px", marginRight: "16px" }}
            onChange={onBorderChange}
            value={border}
          ></Slider>
        </Stack>
        <Stack
          width="50%"
          flexDirection="row"
          alignItems="center"
          paddingLeft="8px"
        >
          <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>
            Border Color:
          </h5>
          <div style={{ marginLeft: "4px" }}>
            <ColorPicker color={borderColor} onChange={onBorderColorChange} />
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}
