import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InvalidNetworkDialog from "components/common/invalidNetworkDialog";
import ProcessingIcon from "components/common/processingIcon";
import SuccessDialog from "components/common/successDialog";
import {
  getPinyWorldMarketAddress,
  getPinyWorldNftAddress,
} from "constant/ContractAddress";
import paymentSettingTokens from "constant/paymentSettingTokens";
import erc20abi from "contracts/abi/ERC20.json";
import marketabi from "contracts/abi/PinyWorldMarket.json";
import pinyworldnftabi from "contracts/abi/PinyWorldNFT.json";
import { tokenValue, toTokenValue, ZERO_ADDRESS } from "helpers/formatters";
import ToastUtils from "helpers/toaster";
import { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { getTokenMetadata } from "helpers/tokens";
import { runContractFunction } from "helpers/web3utils";

export default function Listing({ markerChain, tokenId }) {
  const { Moralis, isAuthenticated, account, chainId } = useMoralis();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isVerySmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [tokenMethod, setTokenMethod] = useState(0);

  const [sellOrder, setSellOrder] = useState();
  const [canSell, setCanSell] = useState(false);
  const [canBuy, setCanBuy] = useState(false);
  const [canSetMinterFee, setCanSetMinterFee] = useState(false);

  const [showSellOrderDialog, setShowSellOrderDialog] = useState(false);
  const [isUpdateSellOrder, setIsUpdateSellOrder] = useState(false);
  const [selectedToken, setSelectedToken] = useState(0);
  const [tokenAddressInput, setTokenAddressInput] = useState("");
  const [tokenAddressError, setTokenAddressError] = useState("");
  const [tokenDataTimeout, setTokenDataTimeout] = useState();
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");

  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");
  const [minterFee, setMinterFee] = useState("");
  const [minterFeeError, setMinterFeeError] = useState("");

  const [fetchingTokenMetadata, setFetchingTokenMetadata] = useState(false);
  const [serviceFee, setServiceFee] = useState();
  const [tokenServiceFee, setTokenServiceFee] = useState();
  const [fetchedMinterFee, setFetchedMinterFee] = useState();

  const [processing, setProcessing] = useState(false);
  const [checkingApproval, setCheckingApproval] = useState(false);
  const [processingApproval, setProcessingApproval] = useState(false);
  const [processedApproval, setProcessedApproval] = useState(false);

  const [
    showCancelSellOrderConfirmDialog,
    setShowCancelSellOrderConfirmDialog,
  ] = useState(false);

  const [showBuyDialog, setShowBuyDialog] = useState(false);
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [checkingPaymentApproval, setCheckingPaymentApproval] = useState(false);
  const [approvingPayment, setApprovingPayment] = useState(false);
  const [paymentApproved, setPaymentApproved] = useState(false);
  const [buyConfirmed, setBuyConfirmed] = useState(false);
  const [showBuySuccessDialog, setShowBuySuccessDialog] = useState(false);

  const [showConfirmTransaction, setShowConfirmTransaction] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const isValidNumber = (value, decimals) => {
    const regex = /^\d+(\.\d+)?$/;

    if (!regex.test(value) || parseFloat(value) === 0) {
      return false;
    }

    if (decimals && parseInt(toTokenValue(value, decimals)) <= 0) {
      return false;
    }

    return true;
  };

  const fetchSellOrderData = useCallback(async () => {
    if (!tokenId || !markerChain) {
      setSellOrder(null);
      return;
    }

    let options = {
      chain: markerChain,
      address: getPinyWorldMarketAddress(markerChain),
      functionName: "getSellOrder",
      abi: marketabi,
      params: { _tokenId: tokenId },
    };

    const sellOrderData = await runContractFunction(options);

    options = {
      chain: markerChain,
      address: getPinyWorldMarketAddress(markerChain),
      functionName: "serviceFeePercentage",
      abi: marketabi,
    };

    setServiceFee(
      (parseFloat(await runContractFunction(options)) / 100).toFixed(2)
    );

    options = {
      chain: markerChain,
      address: getPinyWorldMarketAddress(markerChain),
      functionName: "getMinterFee",
      abi: marketabi,
      params: { _tokenId: tokenId },
    };

    let minterFeeData = await runContractFunction(options);

    minterFeeData = minterFeeData
      ? (parseFloat(minterFeeData) / 100).toFixed(2)
      : 0;

    setFetchedMinterFee(minterFeeData);

    if (sellOrderData[0]) {
      const tokenMetadata = await getTokenMetadata(
        Moralis,
        sellOrderData[2],
        markerChain
      );

      setSellOrder({
        seller: sellOrderData[1],
        price: tokenValue(sellOrderData[3], tokenMetadata.decimals),
        paymentToken: tokenMetadata,
        minterFee: minterFeeData,
      });
    } else {
      setSellOrder(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, tokenId, markerChain]);

  const computeCanSellOrBuy = useCallback(async () => {
    if (isAuthenticated && account) {
      let options = {
        chain: markerChain,
        address: getPinyWorldNftAddress(markerChain),
        functionName: "minterOf",
        abi: pinyworldnftabi,
        params: { _tokenId: tokenId },
      };

      const minter = await runContractFunction(options);

      if (sellOrder) {
        if (sellOrder.seller && sellOrder.seller.toLowerCase() === account) {
          setCanSell(true);
          setCanBuy(false);

          if (minter && minter.toLowerCase() === account) {
            setCanSetMinterFee(true);
          }
        } else {
          setCanBuy(true);
          setCanSell(false);
          setCanSetMinterFee(false);
        }
      } else {
        setCanBuy(false);

        options = {
          chain: markerChain,
          address: getPinyWorldNftAddress(markerChain),
          functionName: "ownerOf",
          abi: pinyworldnftabi,
          params: { tokenId: tokenId },
        };

        const owner = await runContractFunction(options);

        if (owner && owner.toLowerCase() === account) {
          setCanSell(true);
          if (minter && minter.toLowerCase() === account) {
            setCanSetMinterFee(true);
          }
        } else {
          setCanSell(false);
          setCanSetMinterFee(false);
        }
      }
    } else {
      setCanBuy(false);
      setCanSell(false);
      setCanSetMinterFee(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellOrder, account, isAuthenticated]);

  const fetchTokenMetadata = useCallback(async () => {
    if (tokenMethod === 0) {
      setFetchingTokenMetadata(false);
      setTokenAddressError("");
      setTokenAddress(paymentSettingTokens[markerChain][selectedToken].address);
      setTokenSymbol(paymentSettingTokens[markerChain][selectedToken].symbol);
      return;
    }

    setTokenAddress(tokenAddressInput);

    if (!tokenAddressInput) {
      setFetchingTokenMetadata(false);
      setTokenAddressError("");
      setTokenSymbol("");
      return;
    }

    if (!Moralis.web3Library.utils.isAddress(tokenAddressInput)) {
      setFetchingTokenMetadata(false);
      setTokenAddressError("Invalid Address");
      setTokenSymbol("");
      return;
    }

    setFetchingTokenMetadata(true);
    setTokenSymbol("");

    if (tokenDataTimeout) {
      clearTimeout(tokenDataTimeout);
    }

    setTokenDataTimeout(
      setTimeout(async () => {
        const metadata = await getTokenMetadata(
          Moralis,
          tokenAddressInput,
          markerChain
        );

        if (metadata && metadata.symbol) {
          setTokenAddressError("");
          setTokenSymbol(metadata.symbol);
        } else {
          setTokenAddressError("Token metadata not found");
          setTokenSymbol("");
        }

        setFetchingTokenMetadata(false);
      }, 2000)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenMethod, tokenAddressInput]);

  const fetchServiceFee = useCallback(async () => {
    setTokenServiceFee(null);

    if (
      !tokenAddress ||
      !serviceFee ||
      !Moralis.web3Library.utils.isAddress(tokenAddress)
    ) {
      return;
    }

    const options = {
      chain: markerChain,
      address: getPinyWorldMarketAddress(markerChain),
      functionName: "tokenServiceFeeSettingMap",
      abi: marketabi,
      params: { _tokenAddress: tokenAddress },
    };

    const tokenSetting = await runContractFunction(options);

    if (tokenSetting.enabled) {
      setTokenServiceFee(
        (parseFloat(tokenSetting.percentage) / 100).toFixed(2)
      );
    } else {
      setTokenServiceFee(serviceFee);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddress, serviceFee]);

  const checkPaymentApproval = useCallback(async () => {
    if (
      !sellOrder ||
      !sellOrder.paymentToken ||
      !isAuthenticated ||
      !account ||
      !showBuyDialog ||
      chainId !== markerChain
    ) {
      setCheckingPaymentApproval(false);
      return;
    }

    setCheckingPaymentApproval(true);
    setPaymentApproved(false);
    setInsufficientBalance(false);

    if (sellOrder.paymentToken.address !== ZERO_ADDRESS) {
      let options = {
        contractAddress: sellOrder.paymentToken.address,
        functionName: "allowance",
        abi: erc20abi,
        params: {
          owner: account,
          spender: getPinyWorldMarketAddress(markerChain),
        },
      };

      const allowance = tokenValue(
        await Moralis.executeFunction(options),
        sellOrder.paymentToken.decimals
      );

      if (allowance >= sellOrder.price) {
        setPaymentApproved(true);
      }

      options = {
        contractAddress: sellOrder.paymentToken.address,
        functionName: "balanceOf",
        abi: erc20abi,
        params: {
          account: account,
        },
      };

      const balance = tokenValue(
        await Moralis.executeFunction(options),
        sellOrder.paymentToken.decimals
      );

      if (balance < sellOrder.price) {
        setInsufficientBalance(true);
      }
    } else if (sellOrder.paymentToken.address === ZERO_ADDRESS) {
      setPaymentApproved(true);

      const options = { chain: markerChain };
      const balance = tokenValue(
        (await Moralis.Web3API.account.getNativeBalance(options))["balance"],
        sellOrder.paymentToken.decimals
      );

      if (balance < sellOrder.price) {
        setInsufficientBalance(true);
      }
    }

    setCheckingPaymentApproval(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Moralis, sellOrder, account, isAuthenticated, showBuyDialog, chainId]);

  useEffect(() => {
    fetchSellOrderData();
  }, [fetchSellOrderData]);

  useEffect(() => {
    computeCanSellOrBuy();
  }, [computeCanSellOrBuy]);

  useEffect(() => {
    fetchTokenMetadata();
  }, [fetchTokenMetadata]);

  useEffect(() => {
    fetchServiceFee();
  }, [fetchServiceFee]);

  useEffect(() => {
    checkPaymentApproval();
  }, [checkPaymentApproval]);

  const resetInput = () => {
    setTokenAddressInput("");
    setSelectedToken(0);
    setTokenMethod(0);
    setTokenAddress(paymentSettingTokens[markerChain][0].address);
    setTokenSymbol(paymentSettingTokens[markerChain][0].symbol);
    setPrice("");
    setMinterFee("");
    setSuccessMessage("");
    setShowSuccessDialog(false);
    setShowConfirmTransaction(false);
    setProcessing(false);
    setCheckingApproval(false);
    setProcessingApproval(false);
    setProcessedApproval(false);
    setShowConfirmTransaction(false);
    setTokenAddressError("");
    setPriceError("");
    setMinterFeeError("");
  };

  const onCreateSellOrderClick = () => {
    resetInput();

    setIsUpdateSellOrder(false);
    setShowSellOrderDialog(true);
  };

  const onUpdateSellOrderClick = () => {
    const paymentTokenIndex = paymentSettingTokens[markerChain].findIndex(
      (ps) =>
        ps.address.toLowerCase() ===
        sellOrder.paymentToken.address.toLowerCase()
    );

    if (paymentTokenIndex >= 0) {
      setTokenMethod(0);
      setSelectedToken(paymentTokenIndex);
      setTokenAddress(sellOrder.paymentToken.address);
      setTokenSymbol(
        paymentSettingTokens[markerChain][paymentTokenIndex].symbol
      );
      setTokenAddressInput("");
    } else {
      setTokenMethod(1);
      setTokenAddress(sellOrder.paymentToken.address);
      setTokenAddressInput(sellOrder.paymentToken.address);
    }

    setPrice(sellOrder.price);
    setMinterFee(sellOrder.minterFee);

    setIsUpdateSellOrder(true);
    setCheckingApproval(false);
    setProcessingApproval(false);
    setProcessedApproval(false);
    setShowConfirmTransaction(false);
    setShowSellOrderDialog(true);
  };

  const onTokenMethodChange = (event) => {
    setTokenMethod(parseInt(event.target.value));
  };

  const onSelectedTokenChange = (event) => {
    setSelectedToken(event.target.value);
    setTokenAddress(
      paymentSettingTokens[markerChain][event.target.value].address
    );
    setTokenSymbol(
      paymentSettingTokens[markerChain][event.target.value].symbol
    );
  };

  const onTokenAddressInputChange = (event) => {
    setTokenAddressInput(event.target.value);
  };

  const onPriceChange = (event) => {
    setPrice(event.target.value);

    if (!isValidNumber(event.target.value)) {
      setPriceError("Invalid Value");
    } else {
      setPriceError("");
    }
  };

  const onMinterFeeChange = (event) => {
    if (!event.target.value) {
      setMinterFee(event.target.value);
      setMinterFeeError("");
    } else if (!isValidNumber(event.target.value)) {
      setMinterFee(event.target.value);
      setMinterFeeError("Invalid Value");
    } else {
      const parsedValue = parseFloat(event.target.value);
      const fixedValue = parsedValue.toFixed(2);

      if (parseFloat(fixedValue) !== parsedValue) {
        setMinterFee(fixedValue);
      } else {
        setMinterFee(event.target.value);
      }

      if (parseFloat(fixedValue) > 25) {
        setMinterFeeError("Maximum 25%");
      } else {
        setMinterFeeError("");
      }
    }
  };

  const cancelEdit = () => {
    setShowSellOrderDialog(false);

    resetInput();
  };

  const saveSellOrder = async () => {
    setProcessedApproval(false);

    let hasError = false;

    if (tokenAddressError) {
      hasError = true;
    } else if (!Moralis.web3Library.utils.isAddress(tokenAddress)) {
      setTokenAddressError("Invalid address");
      hasError = true;
    } else {
      setTokenAddressError("");
    }

    if (!isValidNumber(price)) {
      setPriceError("Invalid Value");
      hasError = true;
    } else {
      setPriceError("");
    }

    if (canSetMinterFee) {
      if (!minterFee) {
        setMinterFeeError("");
      } else if (!isValidNumber(minterFee)) {
        setMinterFeeError("Invalid Value");
        hasError = true;
      } else {
        const parsedValue = parseFloat(minterFee);
        const fixedValue = parsedValue.toFixed(2);

        if (parseFloat(fixedValue) > 25) {
          setMinterFeeError("Maximum 25%");
          hasError = true;
        } else {
          setMinterFeeError("");
        }
      }
    }

    if (hasError || fetchingTokenMetadata || processing) {
      return;
    }

    setProcessing(true);

    const metadata = await getTokenMetadata(Moralis, tokenAddress, markerChain);

    if (!metadata || (metadata.decimals !== 0 && !metadata.decimals)) {
      setTokenAddressError("Invalid address");
      setProcessing(false);
      return;
    }

    if (!isValidNumber(price, metadata.decimals)) {
      setPriceError("Invalid value");
      setProcessing(false);
      return;
    }

    let options;

    if (!isUpdateSellOrder) {
      setCheckingApproval(true);

      options = {
        chain: markerChain,
        address: getPinyWorldNftAddress(markerChain),
        functionName: "getApproved",
        abi: pinyworldnftabi,
        params: {
          tokenId: tokenId,
        },
      };

      const approved = await runContractFunction(options);

      setCheckingApproval(false);

      if (
        !approved ||
        approved.toLowerCase() !==
          getPinyWorldMarketAddress(markerChain).toLowerCase()
      ) {
        options = {
          contractAddress: getPinyWorldNftAddress(markerChain),
          functionName: "approve",
          abi: pinyworldnftabi,
          params: {
            to: getPinyWorldMarketAddress(markerChain),
            tokenId: tokenId,
          },
        };

        setProcessingApproval(true);
        setShowConfirmTransaction(true);

        try {
          const transaction = await Moralis.executeFunction(options);
          await transaction.wait();

          setProcessedApproval(true);
        } catch (err) {
          ToastUtils.toastUtil("error", err.message || err);
          setShowConfirmTransaction(false);
          setProcessing(false);
          return;
        } finally {
          setProcessingApproval(false);
        }
      }
    }

    options = {
      contractAddress: getPinyWorldMarketAddress(markerChain),
      functionName: "saveSellOrder",
      abi: marketabi,
      params: {
        _tokenId: tokenId,
        _paymentTokenAddress: tokenAddress,
        _price: toTokenValue(price, metadata.decimals),
        _minterFee: minterFee ? (parseFloat(minterFee) * 100).toFixed(0) : 0,
      },
    };

    setShowConfirmTransaction(true);

    try {
      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();

      setSuccessMessage("Sell order successfully saved.");
      setShowSuccessDialog(true);
      setShowSellOrderDialog(false);

      options = {
        chain: markerChain,
        address: getPinyWorldMarketAddress(markerChain),
        functionName: "getSellOrder",
        abi: marketabi,
        params: { _tokenId: tokenId },
      };

      const sellOrderData = await runContractFunction(options);

      if (sellOrderData[0]) {
        const tokenMetadata = await getTokenMetadata(
          Moralis,
          sellOrderData[2],
          chainId
        );

        options = {
          chain: markerChain,
          address: getPinyWorldMarketAddress(markerChain),
          functionName: "getMinterFee",
          abi: marketabi,
          params: { _tokenId: tokenId },
        };

        const fetchedMinterFee = await runContractFunction(options);

        setSellOrder({
          seller: sellOrderData[1],
          price: tokenValue(sellOrderData[3], tokenMetadata.decimals),
          paymentToken: tokenMetadata,
          minterFee: fetchedMinterFee
            ? (parseFloat(fetchedMinterFee) / 100).toFixed(2)
            : 0,
        });
      } else {
        setSellOrder(null);
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
      return;
    } finally {
      setShowConfirmTransaction(false);
      setProcessing(false);
      setProcessedApproval(false);
    }
  };

  const onCancelSellOrderConfirm = async () => {
    setProcessing(true);

    const options = {
      contractAddress: getPinyWorldMarketAddress(markerChain),
      functionName: "cancelSellOrder",
      abi: marketabi,
      params: { _tokenId: tokenId },
    };

    setShowConfirmTransaction(true);

    try {
      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();

      setSuccessMessage("Sell order successfully cancelled.");
      setShowSuccessDialog(true);

      setSellOrder(null);
      setShowCancelSellOrderConfirmDialog(false);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
      return;
    } finally {
      setShowConfirmTransaction(false);
      setProcessing(false);
    }
  };

  const onSuccessDialogClose = () => {
    setSuccessMessage("");
    setShowSuccessDialog(false);
  };

  const onBuyButtonClick = () => {
    setInsufficientBalance(false);
    setCheckingPaymentApproval(false);
    setApprovingPayment(false);
    setPaymentApproved(false);
    setShowBuySuccessDialog(false);

    setShowConfirmTransaction(false);
    setSuccessMessage("");
    setShowSuccessDialog(false);

    setShowBuyDialog(true);
  };

  const approvePaymentMethod = async () => {
    if (
      !sellOrder ||
      !sellOrder.paymentToken ||
      !sellOrder.paymentToken.address
    ) {
      return;
    }

    setApprovingPayment(true);

    const options = {
      contractAddress: sellOrder.paymentToken.address,
      functionName: "approve",
      abi: erc20abi,
      params: {
        spender: getPinyWorldMarketAddress(chainId),
        amount: toTokenValue(sellOrder.price, sellOrder.paymentToken.decimals),
      },
    };

    setShowConfirmTransaction(true);

    try {
      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();

      setPaymentApproved(true);
    } catch (err) {
      ToastUtils.toastUtil(
        "error",
        (err.data && err.data.message) || err.message || JSON.stringify(err)
      );
      return;
    } finally {
      setApprovingPayment(false);
      setShowConfirmTransaction(false);
    }
  };

  const onConfirmBuy = async () => {
    if (
      !canBuy ||
      !sellOrder ||
      !sellOrder.paymentToken ||
      !sellOrder.paymentToken.address ||
      !paymentApproved
    ) {
      return;
    }

    setBuyConfirmed(true);

    const options = {
      contractAddress: getPinyWorldMarketAddress(markerChain),
      functionName: "buy",
      abi: marketabi,
      params: { _tokenId: tokenId },
    };

    if (sellOrder.paymentToken.address === ZERO_ADDRESS) {
      options["msgValue"] = toTokenValue(
        sellOrder.price,
        sellOrder.paymentToken.decimals
      );
    }

    setShowConfirmTransaction(true);

    try {
      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();

      setSuccessMessage("Buy is successfully completed.");
      setShowBuySuccessDialog(true);

      setSellOrder(null);
      setShowBuyDialog(false);
    } catch (err) {
      ToastUtils.toastUtil(
        "error",
        (err.data && err.data.message) || err.message || JSON.stringify(err)
      );
      setBuyConfirmed(false);
      return;
    } finally {
      setShowConfirmTransaction(false);
    }
  };

  const onBuySuccessDialogClose = () => {
    setSuccessMessage("");
    setShowBuySuccessDialog(false);
    setBuyConfirmed(false);
  };

  if (!(sellOrder || canSell || canBuy)) {
    return null;
  }

  return (
    <Stack
      sx={{ border: "1px solid", borderColor: "divider", borderRadius: "8px" }}
    >
      <Box
        sx={{
          padding: "8px",
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Typography variant="h5">Listing</Typography>
      </Box>

      <Stack
        flexDirection={isSmall ? "column" : "row"}
        alignItems={isSmall ? "" : "center"}
      >
        {sellOrder && sellOrder.price && sellOrder.paymentToken ? (
          <Stack padding="16px">
            <Typography variant="h4">
              {sellOrder.price} {sellOrder.paymentToken.symbol}
            </Typography>

            {sellOrder.minterFee && parseFloat(sellOrder.minterFee) > 0 ? (
              <Typography variant="body2" sx={{ marginTop: "8px" }}>
                Creator fee: {sellOrder.minterFee}%
              </Typography>
            ) : null}
          </Stack>
        ) : null}

        {canSell ? (
          sellOrder ? (
            <Box padding="16px" paddingTop={isSmall ? "0px" : ""}>
              <Button
                variant="outlined"
                onClick={() => setShowCancelSellOrderConfirmDialog(true)}
              >
                Cancel
              </Button>

              <Button
                variant="outlined"
                onClick={onUpdateSellOrderClick}
                sx={{ marginLeft: "16px" }}
              >
                Edit
              </Button>
            </Box>
          ) : (
            <Box padding="16px">
              <Button variant="outlined" onClick={onCreateSellOrderClick}>
                Create Sell Order
              </Button>
            </Box>
          )
        ) : null}

        {canBuy && sellOrder && sellOrder.price && sellOrder.paymentToken ? (
          <Box padding="16px" paddingTop={isSmall ? "0px" : ""}>
            <Button variant="outlined" onClick={onBuyButtonClick}>
              Buy
            </Button>
          </Box>
        ) : null}
      </Stack>

      {canSell ? (
        <Dialog open={showSellOrderDialog} disableScrollLock>
          <DialogTitle sx={{ textAlign: "center" }}>
            {isUpdateSellOrder ? "Edit Sell Order" : "Create Sell Order"}
          </DialogTitle>
          <DialogContent>
            <Stack mt="16px">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                defaultValue={0}
                value={tokenMethod}
                onChange={onTokenMethodChange}
                row
              >
                <FormControlLabel
                  key={0}
                  value={0}
                  control={<Radio />}
                  label="Select Token"
                />
                <FormControlLabel
                  key={1}
                  value={1}
                  control={<Radio />}
                  label="Enter Token Address"
                  sx={{ marginLeft: !isVerySmall ? "8px" : "" }}
                />
              </RadioGroup>

              {tokenMethod === 0 ? (
                <>
                  <h4 className="paymentSettingFormLabel">Select Token</h4>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedToken}
                    onChange={onSelectedTokenChange}
                    error={tokenAddressError ? true : false}
                  >
                    {paymentSettingTokens[markerChain].map((ps, index) => {
                      return (
                        <MenuItem key={ps.address} value={index}>
                          {ps.symbol}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {tokenAddressError ? (
                    <span className="formInfo" style={{ color: "red" }}>
                      {tokenAddressError}
                    </span>
                  ) : null}
                </>
              ) : (
                <>
                  <h4 className="paymentSettingFormLabel">Token Address</h4>
                  <OutlinedInput
                    id="token-address-input"
                    variant="outlined"
                    value={tokenAddressInput}
                    onChange={onTokenAddressInputChange}
                    error={tokenAddressError ? true : false}
                    endAdornment={
                      fetchingTokenMetadata ? (
                        <InputAdornment position="end">
                          <ProcessingIcon />
                        </InputAdornment>
                      ) : null
                    }
                  />
                  {tokenAddressError ? (
                    <span className="formInfo" style={{ color: "red" }}>
                      {tokenAddressError}
                    </span>
                  ) : null}
                </>
              )}

              {tokenServiceFee && parseFloat(tokenServiceFee) > 0 ? (
                <Stack flexDirection="row" alignItems="center">
                  <h4 className="paymentSettingFormLabel">Service Fee</h4>
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: "8px", marginTop: "16px" }}
                  >
                    {tokenServiceFee}%
                  </Typography>
                </Stack>
              ) : null}

              <h4 className="paymentSettingFormLabel">Price</h4>
              <OutlinedInput
                value={price}
                endAdornment={
                  tokenSymbol ? (
                    <InputAdornment position="end">
                      {tokenSymbol}
                    </InputAdornment>
                  ) : null
                }
                onChange={onPriceChange}
                error={priceError ? true : false}
              />
              {priceError ? (
                <span className="formInfo" style={{ color: "red" }}>
                  {priceError}
                </span>
              ) : null}

              {canSetMinterFee ? (
                <>
                  <Stack flexDirection="row" alignItems="center">
                    <h4 className="paymentSettingFormLabel">Creator Fee</h4>
                    <Tooltip
                      arrow
                      placement="top"
                      disableFocusListener
                      title={
                        <Stack maxWidth="200px">
                          <Typography variant="body2">
                            Earn a percentage of the sale price every time this
                            marker is sold.
                          </Typography>
                          <Typography variant="body2">
                            Maximum value is 25%.
                          </Typography>
                        </Stack>
                      }
                    >
                      <HelpOutlineIcon
                        sx={{
                          marginLeft: "6px",
                          marginTop: "12px",
                          width: "16px",
                          height: "16px",
                          color: "gray",
                        }}
                      />
                    </Tooltip>
                  </Stack>
                  <OutlinedInput
                    value={minterFee}
                    onChange={onMinterFeeChange}
                    error={minterFeeError ? true : false}
                    endAdornment={
                      <InputAdornment position="end">%</InputAdornment>
                    }
                  />
                  {minterFeeError ? (
                    <span className="formInfo" style={{ color: "red" }}>
                      {minterFeeError}
                    </span>
                  ) : null}
                </>
              ) : fetchedMinterFee ? (
                <Stack flexDirection="row" alignItems="center">
                  <h4 className="paymentSettingFormLabel">Creator Fee</h4>
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: "8px", marginTop: "16px" }}
                  >
                    {fetchedMinterFee}%
                  </Typography>
                </Stack>
              ) : null}

              {checkingApproval ? (
                <DialogContentText
                  sx={{ textAlign: "center", marginTop: "32px" }}
                >
                  Checking approval...
                </DialogContentText>
              ) : processingApproval ? (
                <DialogContentText
                  sx={{ textAlign: "center", marginTop: "32px" }}
                >
                  1/2 Processing token approval...
                </DialogContentText>
              ) : null}

              {processedApproval ? (
                <DialogContentText
                  sx={{ textAlign: "center", marginTop: "32px" }}
                >
                  2/2 Creating sell order...
                </DialogContentText>
              ) : null}

              {showConfirmTransaction ? (
                <DialogContentText
                  sx={{ textAlign: "center", marginTop: "32px" }}
                >
                  Please confirm transaction on your wallet
                </DialogContentText>
              ) : processing ? (
                <DialogContentText
                  sx={{ textAlign: "center", marginTop: "32px" }}
                >
                  Processing...
                </DialogContentText>
              ) : null}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ padding: "16px" }}>
            <Button
              variant="outlined"
              onClick={cancelEdit}
              disabled={processing}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              disabled={processing}
              endIcon={processing ? <ProcessingIcon /> : null}
              onClick={saveSellOrder}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      {canSell && sellOrder ? (
        <Dialog open={showCancelSellOrderConfirmDialog} disableScrollLock>
          <DialogTitle>Confirm Cancel</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel this sell order?
            </DialogContentText>

            {showConfirmTransaction ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Please confirm transaction on your wallet
              </DialogContentText>
            ) : processing ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Processing...
              </DialogContentText>
            ) : null}
          </DialogContent>
          <DialogActions sx={{ padding: "16px" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setShowCancelSellOrderConfirmDialog(false);
              }}
              disabled={processing}
            >
              No
            </Button>

            <Button
              variant="outlined"
              onClick={onCancelSellOrderConfirm}
              disabled={processing}
              endIcon={processing ? <ProcessingIcon /> : null}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      {showSellOrderDialog ||
      showBuyDialog ||
      showCancelSellOrderConfirmDialog ? (
        <InvalidNetworkDialog
          validChainId={markerChain}
          cancelAction={() => {
            cancelEdit(false);
            setShowBuyDialog(false);
            setShowCancelSellOrderConfirmDialog(false);
          }}
        />
      ) : null}

      {canBuy && sellOrder ? (
        <Dialog open={showBuyDialog}>
          <DialogTitle sx={{ textAlign: "center" }}>Confirm Buy</DialogTitle>
          <DialogContent>
            <Stack alignItems="center" mt="16px">
              <Typography variant="body1" textAlign="center">
                Please confirm that you're aware of you will pay
              </Typography>
              <Typography
                variant="h3"
                sx={{ marginTop: "8px" }}
                textAlign="center"
              >
                {sellOrder.price} {sellOrder.paymentToken.symbol}
              </Typography>
              {sellOrder.minterFee && parseFloat(sellOrder.minterFee) > 0 ? (
                <Stack alignItems="center">
                  <Typography
                    variant="body1"
                    sx={{ marginTop: "8px" }}
                    textAlign="center"
                  >
                    and
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{ marginTop: "8px" }}
                    textAlign="center"
                  >
                    {sellOrder.minterFee}%
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ marginTop: "8px" }}
                    textAlign="center"
                  >
                    of future sales will be sent to the creator.
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
            {showConfirmTransaction ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Please confirm transaction on your wallet
              </DialogContentText>
            ) : processing ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Processing...
              </DialogContentText>
            ) : null}
          </DialogContent>
          <DialogActions sx={{ padding: "16px" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setShowBuyDialog(false);
              }}
              disabled={processing || buyConfirmed}
              sx={{
                padding: isVerySmall ? "4px 8px" : "",
                fontSize: isVerySmall ? "11px" : "",
              }}
            >
              Cancel
            </Button>

            <Button
              variant="outlined"
              color={
                insufficientBalance
                  ? "error"
                  : paymentApproved
                  ? "success"
                  : "primary"
              }
              onClick={approvePaymentMethod}
              disabled={
                checkingPaymentApproval ||
                approvingPayment ||
                insufficientBalance ||
                paymentApproved
              }
              endIcon={
                checkingPaymentApproval || approvingPayment ? (
                  <ProcessingIcon />
                ) : paymentApproved && !insufficientBalance ? (
                  <DoneIcon />
                ) : null
              }
              sx={{
                padding: isVerySmall ? "4px 8px" : "",
                fontSize: isVerySmall ? "11px" : "",
              }}
            >
              {checkingPaymentApproval
                ? "Checking"
                : approvingPayment
                ? "Processing"
                : insufficientBalance
                ? "Insufficient Balance"
                : paymentApproved
                ? "Approved"
                : "Approve Payment"}
            </Button>
            <Button
              variant="outlined"
              onClick={onConfirmBuy}
              disabled={
                checkingPaymentApproval ||
                approvingPayment ||
                !paymentApproved ||
                insufficientBalance ||
                buyConfirmed
              }
              sx={{
                padding: isVerySmall ? "4px 8px" : "",
                fontSize: isVerySmall ? "11px" : "",
              }}
              endIcon={buyConfirmed ? <ProcessingIcon /> : null}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      <SuccessDialog
        showDialog={showSuccessDialog}
        message={successMessage}
        onCloseDialog={onSuccessDialogClose}
      />

      <SuccessDialog
        showDialog={showBuySuccessDialog}
        message={successMessage}
        onCloseDialog={onBuySuccessDialogClose}
      />
    </Stack>
  );
}
