import { createTheme, ThemeProvider } from "@mui/material";
import axios from "axios";
import Layout from "components/layout";
import { PINYWORLD_API_END_POINT } from "constant";
import { addTokenToStorage, removeJwtToken } from "helpers/localStorage";
import CreateEventPage from "pages/create/event";
import CreateMarkerPage from "pages/create/marker";
import EventHomePage from "pages/event/home";
import MyEventsPage from "pages/event/my";
import ExploreEventPage from "pages/explore/event";
import ExploreMarkerPage from "pages/explore/marker";
import Home from "pages/home";
import MarkerHomePage from "pages/marker/home";
import MyMarkersPage from "pages/marker/my";
import Market from "pages/market";
import UpdateEventPage from "pages/update/event";
import MyCollectionsPage from "pages/collection/my";
import CollectionHomePage from "pages/collection";
import { useCallback, useEffect } from "react";
import { useMoralis } from "react-moralis";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

function App({ isServerInfo }) {
  const {
    enableWeb3,
    isWeb3Enabled,
    isWeb3EnableLoading,
    isAuthenticated,
    isAuthenticating,
    logout,
    Moralis,
    user,
  } = useMoralis();

  const enableMoralisWeb3 = useCallback(async () => {
    if (
      !isWeb3Enabled &&
      !isWeb3EnableLoading &&
      !isAuthenticating &&
      isAuthenticated
    ) {
      const response = await enableWeb3();

      if (!response) {
        logout();
      }
    }
  }, [
    enableWeb3,
    isAuthenticated,
    isAuthenticating,
    isWeb3EnableLoading,
    isWeb3Enabled,
    logout,
  ]);

  useEffect(() => {
    enableMoralisWeb3();
  }, [enableMoralisWeb3]);

  useEffect(() => {
    Moralis.onAccountChanged((account) => {
      logout();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getJwtToken = async () => {
      if (isAuthenticated) {
        const current = await Moralis.User.currentAsync();
        removeJwtToken();
        if (current) {
          axios
            .post(PINYWORLD_API_END_POINT + "auth/token", {
              username:
                user.attributes.ethAddress || user._getServerData().accounts[0],
              password: user.attributes.sessionToken,
            })
            .then((response) => {
              if (response && response.data && response.data.token) {
                addTokenToStorage(response.data.token);
              }
            });
        }
      } else {
        console.log("not authenticated yet...");
      }
    };

    getJwtToken();
  }, [isAuthenticated, Moralis, user, logout]);

  const theme = createTheme({
    typography: {
      fontFamily: "'Open Sans', sans-serif",
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/explore/marker" component={ExploreMarkerPage} />
            <Route exact path="/explore/event" component={ExploreEventPage} />
            <Route exact path="/create/marker" component={CreateMarkerPage} />
            <Route exact path="/create/event" component={CreateEventPage} />
            <Route exact path="/market" component={Market} />
            <Route exact path="/marker" component={MyMarkersPage} />
            <Route
              exact
              path="/marker/:networkId/:markerId"
              component={MarkerHomePage}
            />
            <Route exact path="/event" component={MyEventsPage} />
            <Route
              exact
              path="/event/:networkId/:eventId"
              component={EventHomePage}
            />
            <Route
              exact
              path="/update/event/:networkId/:eventId"
              component={UpdateEventPage}
            />
            <Route exact path="/collection" component={MyCollectionsPage} />
            <Route
              exact
              path="/collection/:collectionId"
              component={CollectionHomePage}
            />
            <Redirect from="*" to="/" />
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
