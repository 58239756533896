import DoneIcon from "@mui/icons-material/Done";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ConnectWalletButton from "components/common/connectWalletButton";
import ProcessingIcon from "components/common/processingIcon";
import {
  getPinyWorldEventAddress,
  getPinyWorldEventListenerAddress,
} from "constant/ContractAddress";
import erc20abi from "contracts/abi/ERC20.json";
import eventabi from "contracts/abi/PinyWorldEvent.json";
import eventlistenerabi from "contracts/abi/PinyWorldEventListener.json";
import {
  getEllipsisTxt,
  tokenValue,
  toTokenValue,
  ZERO_ADDRESS,
} from "helpers/formatters";
import { addEventToLocalStorage } from "helpers/localStorage";
import { getNetworkLogo, networkConfigs } from "helpers/networks";
import { useCallback, useEffect, useState } from "react";
import Blockies from "react-blockies";
import { getChain, useMoralis } from "react-moralis";
import { Link } from "react-router-dom";

import "assets/styles/piny.css";
import ToastUtils from "helpers/toaster";
import { getTokenMetadata } from "helpers/tokens";
import { computePaymentData } from "helpers/tokens";
import LeafletMap from "components/leafletMap";

export default function CreateEventPage({ props }) {
  const isVerySmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { Moralis, isWeb3Enabled, isAuthenticated, account, chainId } =
    useMoralis();

  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [maxPinCount, setMaxPinCount] = useState("");
  const [customMarkerEnabled, setCustomMarkerEnabled] = useState(false);
  const [onePinPerAddress, setOnePinPerAddress] = useState(false);
  const [map, setMap] = useState();

  const [createEventPaymentSettingList, setCreateEventPaymentSettingList] =
    useState(null);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [checkingPaymentApproval, setCheckingPaymentApproval] = useState(false);
  const [approvingPayment, setApprovingPayment] = useState(false);
  const [showApprovingPaymentDialog, setShowApprovingPaymentDialog] =
    useState(false);
  const [paymentApproved, setPaymentApproved] = useState(false);

  const [showSubmitEventDialog, setShowSubmitEventDialog] = useState(false);
  const [submitEventConfirmed, setSubmitEventConfirmed] = useState(false);
  const [submittingEvent, setSubmittingEvent] = useState(false);
  const [createdEvent, setCreatedEvent] = useState(false);
  const [redirectPageAfterCreate, setRedirectPageAfterCreate] = useState("");

  const fetchCreateEventPaymentSettingList = useCallback(async () => {
    if (!isWeb3Enabled || !isAuthenticated) {
      setCreateEventPaymentSettingList(null);
      return;
    }

    let options = {
      contractAddress: getPinyWorldEventListenerAddress(chainId),
      functionName: "getCreateEventPaymentSettingList",
      abi: eventlistenerabi,
    };

    const paymentSettingList = await Moralis.executeFunction(options);

    const paymentSettingWithTokenInfoList = [];

    await Promise.all(
      paymentSettingList.map(async (ps) => {
        let tokenMetadata = await getTokenMetadata(
          Moralis,
          ps.tokenAddress,
          chainId
        );

        const transferAmount = tokenValue(
          ps.paymentSetting.transferAmount,
          tokenMetadata.decimals
        );

        if (transferAmount > 0) {
          paymentSettingWithTokenInfoList.push({
            token: tokenMetadata,
            amount: transferAmount,
            paymentAddress: ps.paymentSetting.transferAddress,
          });
        }
      })
    );

    paymentSettingWithTokenInfoList.sort((ps1, ps2) => {
      if (ps1 && ps1.token && ps1.token.symbol && ps2 && ps2.token) {
        return ps1.token.symbol.localeCompare(ps2.token.symbol);
      }

      return 0;
    });

    setCreateEventPaymentSettingList(paymentSettingWithTokenInfoList);
  }, [Moralis, chainId, isAuthenticated, isWeb3Enabled]);

  const checkPaymentApproval = useCallback(async () => {
    if (!paymentMethod || !isAuthenticated) {
      setCheckingPaymentApproval(false);
      return;
    }

    setCheckingPaymentApproval(true);
    setPaymentApproved(false);
    setInsufficientBalance(false);

    if (paymentMethod.token.address !== ZERO_ADDRESS) {
      let options = {
        contractAddress: paymentMethod.token.address,
        functionName: "allowance",
        abi: erc20abi,
        params: {
          owner: account,
          spender: getPinyWorldEventListenerAddress(chainId),
        },
      };

      const allowance = tokenValue(
        await Moralis.executeFunction(options),
        paymentMethod.token.decimals
      );

      if (allowance >= paymentMethod.amount) {
        setPaymentApproved(true);
      }

      options = {
        contractAddress: paymentMethod.token.address,
        functionName: "balanceOf",
        abi: erc20abi,
        params: {
          account: account,
        },
      };

      const balance = tokenValue(
        await Moralis.executeFunction(options),
        paymentMethod.token.decimals
      );

      if (balance < paymentMethod.amount) {
        setInsufficientBalance(true);
      }
    } else if (paymentMethod.token.address === ZERO_ADDRESS) {
      setPaymentApproved(true);

      const options = { chain: chainId };
      const balance = tokenValue(
        (await Moralis.Web3API.account.getNativeBalance(options))["balance"],
        paymentMethod.token.decimals
      );

      if (balance < paymentMethod.amount) {
        setInsufficientBalance(true);
      }
    }

    setCheckingPaymentApproval(false);
  }, [Moralis, account, isAuthenticated, chainId, paymentMethod]);

  useEffect(() => {
    fetchCreateEventPaymentSettingList();
  }, [fetchCreateEventPaymentSettingList]);

  useEffect(() => {
    checkPaymentApproval();
  }, [checkPaymentApproval]);

  const onEventNameChange = (event) => {
    setEventName(event.target.value);
  };

  const onEventDescriptionChange = (event) => {
    setEventDescription(event.target.value);
  };

  const onMaximumPinCountChange = (event) => {
    let value = parseInt(event.target.value);

    if (isNaN(value) || value <= 0) {
      value = "";
    }

    setMaxPinCount(value);
  };

  const onCustomMarkerEnabledChange = (event) => {
    setCustomMarkerEnabled(event.target.checked);
  };

  const onOnePinPerAddressChange = (event) => {
    setOnePinPerAddress(event.target.checked);
  };

  const onPaymentMethodChange = (event) => {
    if (
      !createEventPaymentSettingList ||
      event.target.value > createEventPaymentSettingList.length
    ) {
      return;
    }

    setSelectedPaymentIndex(parseInt(event.target.value));
  };

  const approvePaymentMethod = async () => {
    if (!paymentMethod || !paymentMethod.token) {
      return;
    }

    setApprovingPayment(true);
    setShowApprovingPaymentDialog(true);

    const options = {
      contractAddress: paymentMethod.token.address,
      functionName: "approve",
      abi: erc20abi,
      params: {
        spender: getPinyWorldEventListenerAddress(chainId),
        amount: toTokenValue(
          paymentMethod.amount,
          paymentMethod.token.decimals
        ),
      },
    };

    try {
      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();

      setPaymentApproved(true);
    } catch (err) {
      if (err && err.code !== 4001) {
        ToastUtils.toastUtil("error", JSON.stringify(err));
      }
      return;
    } finally {
      setApprovingPayment(false);
      setShowApprovingPaymentDialog(false);
    }
  };

  const onSubmitButtonClicked = () => {
    setShowSubmitEventDialog(true);
  };

  const submitCreateEvent = async () => {
    if (
      !createEventPaymentSettingList ||
      (createEventPaymentSettingList.length > 0 && !paymentMethod)
    ) {
      return;
    }

    setSubmitEventConfirmed(true);

    const options = {
      contractAddress: getPinyWorldEventAddress(chainId),
      functionName: "createEvent",
      abi: eventabi,
      params: {
        _eventParams: [
          eventName,
          eventDescription,
          maxPinCount ? maxPinCount : "0",
          customMarkerEnabled,
          onePinPerAddress,
          map.getCenter().lat + "",
          map.getCenter().lng + "",
          map.getZoom(),
        ],
        _token: paymentMethod ? paymentMethod.token.address : ZERO_ADDRESS,
      },
    };

    if (paymentMethod && paymentMethod.token.address === ZERO_ADDRESS) {
      options["msgValue"] = toTokenValue(
        paymentMethod.amount,
        paymentMethod.token.decimals
      );
    }

    setSubmittingEvent(true);

    try {
      const transaction = await Moralis.executeFunction(options);
      const result = await transaction.wait();

      setCreatedEvent(true);
      setRedirectPageAfterCreate("/event");

      const createPinyWorldEvent = result.events.find(
        (e) => e.event === "CreatePinyWorldEvent"
      );

      if (
        createPinyWorldEvent &&
        createPinyWorldEvent.args &&
        createPinyWorldEvent.args.id &&
        createPinyWorldEvent.args.id.toString
      ) {
        const eventId = parseInt(createPinyWorldEvent.args.id.toString());

        addEventToLocalStorage(networkConfigs[chainId].code, eventId, {
          eventId,
          creator: account,
          name: eventName,
          description: eventDescription,
          maxMintCount: maxPinCount,
          customMarkerEnabled: customMarkerEnabled,
          onlyOncePerAddress: onePinPerAddress,
          mapCenter: {
            longitude: map.getCenter().lng,
            latitude: map.getCenter().lat,
          },
          mapZoom: map.getZoom(),
          createPaymentData: computePaymentData(paymentMethod, chainId),
          createTime: new Date().getTime(),
          transactionHash: createPinyWorldEvent.transactionHash,
        });

        setRedirectPageAfterCreate(
          `/update/event/${networkConfigs[chainId].code}/${eventId}`
        );
      }
    } catch (err) {
      ToastUtils.toastUtil(
        "error",
        (err && err.data && err.data.message) || (err && err.message) || err
      );
      return;
    } finally {
      setSubmitEventConfirmed(false);
      setSubmittingEvent(false);
    }
  };

  useEffect(() => {
    if (
      !createEventPaymentSettingList ||
      !createEventPaymentSettingList.length ||
      selectedPaymentIndex >= createEventPaymentSettingList.length
    ) {
      setPaymentMethod(null);
    } else {
      setPaymentMethod(createEventPaymentSettingList[selectedPaymentIndex]);
    }
  }, [createEventPaymentSettingList, selectedPaymentIndex]);

  return (
    <Container
      maxWidth="md"
      sx={{ backgroundColor: "white", paddingBottom: "48px" }}
    >
      <Stack>
        <h1>Create Event</h1>

        <h3 className="formLabel">Name</h3>
        <TextField
          id="event-name-input"
          variant="outlined"
          inputProps={{ maxLength: 64 }}
          value={eventName}
          onChange={onEventNameChange}
        />
        <span className="formInfo">Maximum 64 characters.</span>

        <h3 className="formLabel">Description</h3>
        <TextField
          id="event-description-input"
          variant="outlined"
          multiline
          rows={4}
          inputProps={{ maxLength: 1024 }}
          value={eventDescription}
          onChange={onEventDescriptionChange}
        />
        <span className="formInfo">Maximum 1024 characters.</span>

        <h3 className="formLabel">Maximum Pin Count</h3>
        <TextField
          id="event-max-pin-input"
          variant="outlined"
          value={maxPinCount}
          onChange={onMaximumPinCountChange}
        />
        <span className="formInfo">Empty or 0 means unlimited pins</span>

        <h3 className="formLabel">Custom Marker Enabled</h3>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>Can attendees pin custom markers created by them?</span>

          <Switch
            checked={customMarkerEnabled}
            onChange={onCustomMarkerEnabledChange}
            inputProps={{ "aria-label": "event-custom-marker-swtich" }}
          />
        </Stack>

        <h3 className="formLabel">Only one pin per address</h3>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <span>Each address can pin only one marker for the event</span>

          <Switch
            checked={onePinPerAddress}
            onChange={onOnePinPerAddressChange}
            inputProps={{ "aria-label": "event-one-pin-swtich" }}
            size="large"
          />
        </Stack>

        <h3 className="formLabel">Map zoom and center</h3>
        <LeafletMap
          userLocation
          whenReady={(map) => {
            setMap(map);
          }}
          style={{
            borderRadius: "8px",
            height: `${window.innerWidth * (9 / 16)}px`,
            maxHeight: "600px",
            minHeight: "480px",
          }}
        />
        <span className="formInfo">
          Map will show up with this zoom level and center on event home page
        </span>

        {isAuthenticated && account ? (
          <>
            <h3 className="formLabel">Creator</h3>
            <Stack direction="row" alignItems="center" sx={{ height: "24px" }}>
              <Blockies seed={account.toLowerCase()} className="blockie" />
              <h4 style={{ marginLeft: "8px" }}>
                {isVerySmall ? getEllipsisTxt(account, 15) : account}
              </h4>
            </Stack>
            <h3 className="formLabel">Network</h3>
            <Stack direction="row" alignItems="center" sx={{ height: "24px" }}>
              <img
                src={getNetworkLogo(chainId)}
                alt={getChain(chainId).name}
                style={{
                  maxWidth: "32px",
                  maxHeight: "32px",
                  borderRadius: "50%",
                }}
              />
              <h4 style={{ marginLeft: "8px" }}>{getChain(chainId).name}</h4>
            </Stack>
          </>
        ) : null}

        {createEventPaymentSettingList &&
        createEventPaymentSettingList.length ? (
          <>
            <h3 className="formLabel">Payment Method</h3>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              defaultValue={0}
              onChange={onPaymentMethodChange}
            >
              {createEventPaymentSettingList.map((ps, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={index}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 28,
                          },
                        }}
                      />
                    }
                    label={`${ps.amount} ${ps.token.symbol}`}
                    sx={{ marginTop: index === 0 ? "-8px" : "" }}
                  />
                );
              })}
            </RadioGroup>
          </>
        ) : null}

        <Stack
          flexDirection="row"
          paddingTop="32px"
          alignItems="center"
          justifyContent="center"
        >
          {!isAuthenticated ? (
            <ConnectWalletButton />
          ) : createEventPaymentSettingList ? (
            <>
              {paymentMethod ? (
                <Button
                  variant="outlined"
                  color={
                    insufficientBalance
                      ? "error"
                      : paymentApproved
                      ? "success"
                      : "primary"
                  }
                  onClick={approvePaymentMethod}
                  disabled={
                    checkingPaymentApproval ||
                    approvingPayment ||
                    insufficientBalance ||
                    paymentApproved ||
                    createdEvent
                  }
                  endIcon={
                    checkingPaymentApproval || approvingPayment ? (
                      <ProcessingIcon />
                    ) : paymentApproved && !insufficientBalance ? (
                      <DoneIcon />
                    ) : null
                  }
                >
                  {checkingPaymentApproval
                    ? "Checking"
                    : approvingPayment
                    ? "Processing"
                    : insufficientBalance
                    ? "Insufficient Balance"
                    : paymentApproved
                    ? "Approved"
                    : "Approve Payment"}
                </Button>
              ) : null}
              <Button
                variant="outlined"
                onClick={onSubmitButtonClicked}
                disabled={
                  checkingPaymentApproval ||
                  (paymentMethod && !paymentApproved) ||
                  insufficientBalance ||
                  submitEventConfirmed ||
                  createdEvent
                }
                sx={{ marginLeft: "16px" }}
                endIcon={submitEventConfirmed ? <ProcessingIcon /> : null}
              >
                Submit
              </Button>
              {paymentMethod && !createdEvent ? (
                <Dialog open={showApprovingPaymentDialog} disableScrollLock>
                  <DialogTitle>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                      >
                        <h3 style={{ margin: "0px" }}>Approve Payment</h3>
                      </Stack>
                      <IconButton
                        onClick={() => {
                          setShowApprovingPaymentDialog(false);
                        }}
                        style={{ marginRight: "-16px", marginTop: "-16px" }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <Stack flexDirection="column" alignItems="center">
                      <h2>
                        {paymentMethod.amount} {paymentMethod.token.symbol}
                      </h2>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ height: "24px" }}
                      >
                        <img
                          src={getNetworkLogo(chainId)}
                          alt={getChain(chainId).name}
                          style={{
                            maxWidth: "24px",
                            maxHeight: "24px",
                            borderRadius: "50%",
                          }}
                        />
                        <span style={{ marginLeft: "8px" }}>
                          {getChain(chainId).name}
                        </span>
                      </Stack>
                    </Stack>
                    <DialogContentText
                      sx={{
                        paddingTop: "40px",
                        paddingBottom: "8px",
                        textAlign: "center",
                      }}
                    >
                      Please confirm transaction on your wallet
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              ) : null}

              {!createdEvent ? (
                <Dialog open={showSubmitEventDialog} disableScrollLock>
                  <DialogTitle>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                      >
                        <h3 style={{ margin: "0px" }}>Confirm</h3>
                      </Stack>
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <Stack flexDirection="column" alignItems="center">
                      <Typography textAlign="center">
                        Please be aware that you cannot change
                      </Typography>
                      <Typography variant="h6" textAlign="center">
                        Name
                      </Typography>
                      <Typography variant="h6" textAlign="center">
                        Description
                      </Typography>
                      <Typography variant="h6" textAlign="center">
                        Maximum Pin Count
                      </Typography>
                      <Typography variant="h6" textAlign="center">
                        Only one pin per address
                      </Typography>

                      {paymentMethod ? (
                        <>
                          <Typography textAlign="center">and</Typography>
                          <Typography textAlign="center">
                            you will pay
                          </Typography>
                          <Typography
                            variant="h5"
                            textAlign="center"
                            sx={{ margin: "8px" }}
                          >
                            {paymentMethod.amount} {paymentMethod.token.symbol}
                          </Typography>
                        </>
                      ) : null}

                      <Typography textAlign="center">
                        after confirming transaction on your wallet
                      </Typography>
                      <Typography
                        textAlign="center"
                        style={{
                          marginTop: "16px",
                          marginBottom: "4px",
                          textAlign: "center",
                        }}
                      >
                        Event is being created on
                      </Typography>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ height: "24px" }}
                      >
                        <img
                          src={getNetworkLogo(chainId)}
                          alt={getChain(chainId).name}
                          style={{
                            maxWidth: "24px",
                            maxHeight: "24px",
                            borderRadius: "50%",
                          }}
                        />
                        <span style={{ marginLeft: "8px" }}>
                          {getChain(chainId).name}
                        </span>
                      </Stack>
                    </Stack>
                    {submittingEvent ? (
                      <DialogContentText
                        sx={{
                          paddingTop: "40px",
                          paddingBottom: "8px",
                          textAlign: "center",
                        }}
                      >
                        Please confirm transaction on your wallet
                      </DialogContentText>
                    ) : null}
                  </DialogContent>
                  <DialogActions sx={{ padding: "16px" }}>
                    <Button
                      variant="outlined"
                      color="error"
                      disabled={submitEventConfirmed}
                      onClick={() => {
                        setShowSubmitEventDialog(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={submitEventConfirmed}
                      endIcon={submitEventConfirmed ? <ProcessingIcon /> : null}
                      onClick={submitCreateEvent}
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : (
                <Dialog open={createdEvent} disableScrollLock>
                  <DialogTitle sx={{ textAlign: "center" }}>
                    Event Created
                  </DialogTitle>
                  <DialogContent>
                    <Stack flexDirection="column" alignItems="center">
                      <WhereToVoteOutlinedIcon
                        sx={{
                          height: "120px",
                          width: "120px",
                          color: "green",
                        }}
                      />
                    </Stack>
                    <DialogContentText
                      sx={{ marginTop: "24px", textAlign: "center" }}
                    >
                      Congratulations!
                    </DialogContentText>
                    <DialogContentText
                      sx={{ marginTop: "16px", textAlign: "center" }}
                    >
                      Your event is successfully created.
                    </DialogContentText>
                    <DialogContentText
                      sx={{ marginTop: "16px", textAlign: "center" }}
                    >
                      Click OK to go to your event's page and update remaining
                      information.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    sx={{ padding: "16px", justifyContent: "center" }}
                  >
                    <Link to={redirectPageAfterCreate} className="buttonLink">
                      <Button variant="outlined" onClick={() => {}}>
                        OK
                      </Button>
                    </Link>
                  </DialogActions>
                </Dialog>
              )}
            </>
          ) : null}
        </Stack>
      </Stack>
    </Container>
  );
}
