import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import AccountButton from "./account";

import "assets/styles/piny.css";
import logoMor from "assets/img/logo/piny-logo-mor.png";
import CreateItem from "./create";
import ExploreItem from "./explore";
import HeaderSmallMenu from "./smallMenu";

export default function Header() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "calc(100% - 32px)",
        position: "fixed",
        top: "0",
        left: "0",
        height: "48px",
        padding: "16px",
        boxShadow: "rgb(4 17 29 / 25%) 0px 0px 8px 0px",
        backgroundColor: "white",
        zIndex: 100,
      }}
    >
      <Link to="/" style={{ display: "flex" }}>
        <img
          src={logoMor}
          alt="logo"
          style={{ height: "48px", colorAdjust: "rgba(133,41,153,1.00)" }}
        ></img>
      </Link>

      {isSmall ? (
        <HeaderSmallMenu />
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></div>
          <div style={{ marginLeft: "40px" }}>
            <ExploreItem />
          </div>
          <div style={{ marginLeft: "40px" }}>
            <CreateItem />
          </div>
          <div style={{ marginLeft: "40px" }}>
            <Link to="/market" className="headerMenuItem">
              Market
            </Link>
          </div>
          <div
            style={{
              marginLeft: "40px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <AccountButton />
          </div>
        </>
      )}
    </div>
  );
}
