import { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";

export default function EventMarkers({
  eventMarkers,
  markerPerPage,
  canPinMarker,
  onMarkerClick,
  selectedMarkerUri,
}) {
  const [pages, setPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageInput, setCurrentPageInput] = useState("1");

  const computePage = (startIndex) => {
    let endIndex = startIndex + markerPerPage;

    if (endIndex > eventMarkers.length) {
      endIndex = eventMarkers.length;
    }

    const items = [];

    for (let i = startIndex; i < endIndex; i++) {
      items.push(
        <Grid item key={eventMarkers[i].imageURI} xs={4} md={2}>
          <Paper
            onClick={
              canPinMarker
                ? () => onMarkerClick(eventMarkers[i].imageURI)
                : null
            }
            elevation={selectedMarkerUri === eventMarkers[i].imageURI ? 1 : 3}
            sx={{
              padding: "8px",
              cursor: canPinMarker ? "pointer" : "",
              display: "flex",
              border:
                selectedMarkerUri === eventMarkers[i].imageURI
                  ? "2px solid #b42bb9"
                  : "",
            }}
          >
            <Stack>
              <img
                src={`https://gateway.moralisipfs.com/ipfs/${eventMarkers[i].imageURI}`}
                alt={eventMarkers[i].imageURI}
                style={{ maxWidth: "100%" }}
              />

              <Stack
                flexDirection="row"
                width="100%"
                justifyContent="flex-end"
                alignItems="center"
                mt="8px"
              >
                <Typography
                  variant="caption"
                  sx={{ marginLeft: "2px", lineHeight: "1.2" }}
                >
                  {eventMarkers[i].mintCount}
                </Typography>
                <PinDropOutlinedIcon sx={{ width: "13px", height: "13px" }} />
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      );
    }

    return (
      <Grid
        container
        spacing={1}
        columns={16}
        key={startIndex}
        sx={{ justifyContent: "center", padding: "0px 8px" }}
      >
        {items}
      </Grid>
    );
  };

  const onPreviousPageClick = () => {
    if (currentPage <= 1) {
      return;
    }

    let newCurrentPage = currentPage - 1;

    setCurrentPage(newCurrentPage);
    setCurrentPageInput(newCurrentPage);
  };

  const onNextPageClick = () => {
    if (currentPage >= pages.length) {
      return;
    }

    let newCurrentPage = currentPage + 1;

    setCurrentPage(newCurrentPage);
    setCurrentPageInput(newCurrentPage);
  };

  const onCurrentPageInputChange = (event) => {
    const page = parseInt(event.target.value);

    if (page && page > pages.length) {
      setCurrentPageInput(pages.length);
    } else if (page && page < 1) {
      setCurrentPageInput(1);
    } else {
      setCurrentPageInput(event.target.value);
    }
  };

  const onGoToPageClick = () => {
    const page = parseInt(currentPageInput);

    if (page && page > pages.length) {
      setCurrentPage(pages.length);
    } else if (page && page < 1) {
      setCurrentPage(1);
    } else if (!page) {
      setCurrentPage(1);
      setCurrentPageInput(1);
    } else {
      setCurrentPage(page);
      setCurrentPageInput(page);
    }
  };

  useEffect(() => {
    let tempPages = [];

    for (
      let startIndex = 0;
      startIndex < eventMarkers.length;
      startIndex += markerPerPage
    ) {
      tempPages.push(computePage(startIndex));
    }

    setPages(tempPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventMarkers, markerPerPage, selectedMarkerUri, canPinMarker]);

  if (
    !eventMarkers ||
    !eventMarkers.length ||
    !pages ||
    !pages.length ||
    currentPage > pages.length
  ) {
    return (
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", padding: "8px" }}
      >
        No markers added by owner
      </Stack>
    );
  }

  return (
    <Stack alignItems="center">
      {pages[currentPage - 1]}

      {pages.length > 1 ? (
        <Stack
          flexDirection="row"
          alignItems="center"
          padding="8px"
          paddingBottom="0px"
          marginTop="8px"
        >
          <IconButton onClick={onPreviousPageClick} disabled={currentPage <= 1}>
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
          <IconButton
            ml="8px"
            onClick={onNextPageClick}
            disabled={currentPage >= pages.length}
          >
            <ArrowForwardIosOutlinedIcon />
          </IconButton>

          {pages.length > 4 ? (
            <Stack flexDirection="row" alignItems="center" ml="8px">
              <OutlinedInput
                id="current-page-input"
                variant="outlined"
                type="tel"
                value={currentPageInput}
                onChange={onCurrentPageInputChange}
                inputProps={{ style: { textAlign: "right" } }}
                size="small"
                sx={{
                  width: `${75 + parseInt(Math.log10(pages.length)) * 15}px`,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    / {pages.length}
                  </InputAdornment>
                }
              />

              <IconButton sx={{ marginLeft: "4px" }} onClick={onGoToPageClick}>
                <ArrowUpwardOutlinedIcon />
              </IconButton>
            </Stack>
          ) : (
            <Stack ml="8px">
              <Typography variant="body1">
                {currentPage} / {pages.length}
              </Typography>
            </Stack>
          )}
        </Stack>
      ) : null}
    </Stack>
  );
}
