import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
} from "@mui/material";
import PageLoadingSpinner from "components/common/pageLoadingSpinner";
import ProcessingIcon from "components/common/processingIcon";
import SuccessDialog from "components/common/successDialog";
import { getPinyWorldEventAddress } from "constant/ContractAddress";
import paymentSettingTokens from "constant/paymentSettingTokens";
import eventabi from "contracts/abi/PinyWorldEvent.json";
import { tokenValue, toTokenValue, ZERO_ADDRESS } from "helpers/formatters";
import { getChainIdByNetwork } from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { getTokenMetadata } from "helpers/tokens";
import { runContractFunction } from "helpers/web3utils";
import { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";

export default function UpdateEventPaymentSettings({
  eventData,
  paymentSettings,
  paymentSettingsSetter,
}) {
  const isVerySmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { Moralis } = useMoralis();

  const eventChain = getChainIdByNetwork(eventData.network);

  const [fetchingData, setFetchingData] = useState(true);

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [isUpdate, setIsUpdate] = useState();
  const [hasChanged, setHasChanged] = useState(false);
  const [fetchingSettingData, setFetchingSettingData] = useState(false);
  const [tokenMethod, setTokenMethod] = useState(0);
  const [selectedToken, setSelectedToken] = useState(0);
  const [tokenAddressInput, setTokenAddressInput] = useState("");
  const [tokenAddressError, setTokenAddressError] = useState("");
  const [tokenDataTimeout, setTokenDataTimeout] = useState();
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentAmountToUpdate, setPaymentAmountToUpdate] = useState("");
  const [paymentAmountError, setPaymentAmountError] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [receiverAddressToUpdate, setReceiverAddressToUpdate] = useState("");
  const [receiverAddressError, setReceiverAddressError] = useState("");

  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [showConfirmTransaction, setShowConfirmTransaction] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const isValidNumber = (value, decimals) => {
    const regex = /^\d+(\.\d+)?$/;

    if (!regex.test(value) || parseFloat(value) === 0) {
      return false;
    }

    if (decimals && parseInt(toTokenValue(value, decimals)) <= 0) {
      return false;
    }

    return true;
  };

  const fetchPaymentSettingList = useCallback(async () => {
    if (paymentSettings) {
      setFetchingData(false);
      return;
    }

    setFetchingData(true);

    const options = {
      chain: eventChain,
      address: getPinyWorldEventAddress(eventChain),
      functionName: "getEventPaymentSettingList",
      abi: eventabi,
      params: { _eventId: eventData.eventId },
    };

    const paymentSettingList = await runContractFunction(options);

    const paymentSettingListWithTokenMetadata = [];

    await Promise.all(
      paymentSettingList.map(async (ps) => {
        const tokenMetadata = await getTokenMetadata(
          Moralis,
          ps[0],
          eventChain
        );

        const transferAmount = tokenValue(ps[1][1], tokenMetadata.decimals);

        paymentSettingListWithTokenMetadata.push({
          token: tokenMetadata,
          transferAmount,
          transferAddress: ps[1][2],
        });
      })
    );

    paymentSettingListWithTokenMetadata.sort((ps1, ps2) => {
      if (ps1 && ps1.token && ps1.token.symbol && ps2 && ps2.token) {
        return ps1.token.symbol.localeCompare(ps2.token.symbol);
      }

      return 0;
    });

    paymentSettingsSetter(paymentSettingListWithTokenMetadata);

    setFetchingData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSettings]);

  const fetchTokenMetadata = useCallback(async () => {
    if (isUpdate) {
      return;
    }

    if (tokenMethod === 0) {
      setFetchingSettingData(false);
      setTokenAddressError("");
      setTokenAddress(paymentSettingTokens[eventChain][selectedToken].address);
      setTokenSymbol(paymentSettingTokens[eventChain][selectedToken].symbol);
      return;
    }

    setTokenAddress(tokenAddressInput);

    if (!tokenAddressInput) {
      setFetchingSettingData(false);
      setTokenAddressError("");
      setTokenSymbol("");
      return;
    }

    if (!Moralis.web3Library.utils.isAddress(tokenAddressInput)) {
      setFetchingSettingData(false);
      setTokenAddressError("Invalid Address");
      setTokenSymbol("");
      return;
    }

    setFetchingSettingData(true);
    setTokenSymbol("");

    if (tokenDataTimeout) {
      clearTimeout(tokenDataTimeout);
    }

    setTokenDataTimeout(
      setTimeout(async () => {
        const metadata = await getTokenMetadata(
          Moralis,
          tokenAddressInput,
          eventChain
        );

        if (metadata && metadata.symbol) {
          setTokenAddressError("");
          setTokenSymbol(metadata.symbol);
        } else {
          setTokenAddressError("Token metadata not found");
          setTokenSymbol("");
        }

        setFetchingSettingData(false);
      }, 2000)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenMethod, tokenAddressInput]);

  const fetchPaymentSetting = useCallback(async () => {
    if (!isUpdate || !tokenAddress) {
      return;
    }

    if (!Moralis.web3Library.utils.isAddress(tokenAddressInput)) {
      setFetchingSettingData(false);
      setTokenAddressError("Invalid Address");
      setTokenSymbol("");
      return;
    }

    setFetchingSettingData(true);
    setTokenSymbol("");

    if (tokenDataTimeout) {
      clearTimeout(tokenDataTimeout);
    }

    setTimeout(async () => {
      const metadata = await getTokenMetadata(
        Moralis,
        tokenAddressInput,
        eventChain
      );

      if (metadata && metadata.symbol) {
        setTokenAddress(tokenAddressInput);
        setTokenAddressError("");
        setTokenSymbol(metadata.symbol);
      } else {
        setTokenAddressError("Token metadata not found");
        setTokenSymbol("");
      }

      setFetchingSettingData(false);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenMethod, tokenAddressInput]);

  useEffect(() => {
    fetchPaymentSettingList();
  }, [fetchPaymentSettingList]);

  useEffect(() => {
    fetchTokenMetadata();
  }, [fetchTokenMetadata]);

  useEffect(() => {
    fetchPaymentSetting();
  }, [fetchPaymentSetting]);

  const onAddPaymentSettingButtonClick = () => {
    setIsUpdate(false);
    setTokenMethod(0);
    setTokenAddressInput("");
    setSelectedToken(0);
    setTokenAddress(paymentSettingTokens[eventChain][0].address);
    setTokenSymbol(paymentSettingTokens[eventChain][0].symbol);
    setPaymentAmount("");
    setReceiverAddress("");
    setSuccessMessage("");
    setShowSuccessDialog(false);
    setShowConfirmTransaction(false);
    setProcessing(false);
    setTokenAddressError("");
    setPaymentAmountError("");
    setReceiverAddressError("");
    setShowEditDialog(true);
  };

  const onPaymentSettingUpdateClick = (
    tokenAddress,
    tokenSymbol,
    paymentAmount,
    receiverAddress
  ) => {
    setIsUpdate(true);
    setHasChanged(false);
    setTokenAddress(tokenAddress);
    setTokenSymbol(tokenSymbol);
    setPaymentAmount(paymentAmount);
    setPaymentAmountToUpdate(paymentAmount);
    setReceiverAddress(receiverAddress);
    setReceiverAddressToUpdate(receiverAddress);
    setSuccessMessage("");
    setShowSuccessDialog(false);
    setShowConfirmTransaction(false);
    setProcessing(false);
    setTokenAddressError("");
    setPaymentAmountError("");
    setReceiverAddressError("");
    setShowEditDialog(true);
  };

  const onTokenMethodChange = (event) => {
    setTokenMethod(parseInt(event.target.value));
  };

  const onSelectedTokenChange = (event) => {
    setSelectedToken(event.target.value);
    setTokenAddress(
      paymentSettingTokens[eventChain][event.target.value].address
    );
    setTokenSymbol(paymentSettingTokens[eventChain][event.target.value].symbol);
  };

  const onTokenAddressInputChange = (event) => {
    setTokenAddressInput(event.target.value);
  };

  const onPaymentAmountChange = (event) => {
    setPaymentAmount(event.target.value);

    if (!isValidNumber(event.target.value)) {
      setPaymentAmountError("Invalid Value");
    } else {
      setPaymentAmountError("");
    }
  };

  const onReceiverAddressChange = (event) => {
    setReceiverAddress(event.target.value);

    if (!Moralis.web3Library.utils.isAddress(event.target.value)) {
      setReceiverAddressError("Invalid Address");
    } else {
      setReceiverAddressError("");
    }
  };

  const onSuccessDialogClose = () => {
    setShowSuccessDialog(false);
    setSuccessMessage("");
    setShowConfirmTransaction(false);
    setProcessing(false);
  };

  const cancelEdit = () => {
    setShowEditDialog(false);
    setTokenAddressInput("");
    setTokenAddress("");
    setSelectedToken(0);
    setPaymentAmount("");
    setReceiverAddress("");
    setSuccessMessage("");
    setShowSuccessDialog(false);
    setShowConfirmTransaction(false);
    setProcessing(false);
    setTokenAddressError("");
    setPaymentAmountError("");
    setReceiverAddressError("");
  };

  const onDeleteSettingConfirm = async () => {
    setShowDeleteConfirmDialog(false);
    setProcessing(true);
    setIsDelete(true);

    const options = {
      contractAddress: getPinyWorldEventAddress(eventChain),
      functionName: "saveEventPaymentSetting",
      abi: eventabi,
      params: {
        _eventId: eventData.eventId,
        _tokenAddress: tokenAddress,
        _paymentSetting: [false, "0", ZERO_ADDRESS],
      },
    };

    setShowConfirmTransaction(true);

    try {
      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();
      paymentSettingsSetter(null);

      setSuccessMessage("Payment setting succesfully deleted.");
      setShowSuccessDialog(true);
      setShowEditDialog(false);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
      return;
    } finally {
      setShowConfirmTransaction(false);
      setProcessing(false);
    }
  };

  const savePaymentSetting = async () => {
    setIsDelete(false);

    let hasError = false;

    if (tokenAddressError) {
      hasError = true;
    } else if (!Moralis.web3Library.utils.isAddress(tokenAddress)) {
      setTokenAddressError("Invalid address");
      hasError = true;
    } else {
      setTokenAddressError("");
    }

    if (!isValidNumber(paymentAmount)) {
      setPaymentAmountError("Invalid Value");
      hasError = true;
    } else {
      setPaymentAmountError("");
    }

    if (!Moralis.web3Library.utils.isAddress(receiverAddress)) {
      setReceiverAddressError("Invalid address");
      hasError = true;
    } else {
      setReceiverAddressError("");
    }

    if (hasError || fetchingData || fetchingSettingData || processing) {
      return;
    }

    setProcessing(true);

    const metadata = await getTokenMetadata(Moralis, tokenAddress, eventChain);

    if (!metadata || (metadata.decimals !== 0 && !metadata.decimals)) {
      setTokenAddressError("Invalid address");
      setProcessing(false);
      return;
    }

    if (!isValidNumber(paymentAmount, metadata.decimals)) {
      setPaymentAmountError("Invalid value");
      setProcessing(false);
      return;
    }

    const options = {
      contractAddress: getPinyWorldEventAddress(eventChain),
      functionName: "saveEventPaymentSetting",
      abi: eventabi,
      params: {
        _eventId: eventData.eventId,
        _tokenAddress: tokenAddress,
        _paymentSetting: [
          true,
          toTokenValue(paymentAmount, metadata.decimals),
          receiverAddress,
        ],
      },
    };

    setShowConfirmTransaction(true);

    try {
      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();
      paymentSettingsSetter(null);

      if (isUpdate) {
        setSuccessMessage("Payment setting succesfully updated.");
      } else {
        setSuccessMessage("Payment setting succesfully added.");
      }

      setShowSuccessDialog(true);
      setShowEditDialog(false);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
      return;
    } finally {
      setShowConfirmTransaction(false);
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (!isUpdate) {
      return;
    }

    if (
      paymentAmount === paymentAmountToUpdate &&
      receiverAddress === receiverAddressToUpdate
    ) {
      setHasChanged(false);
    } else {
      setHasChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAmount, receiverAddress, isUpdate]);

  return (
    <Stack>
      {fetchingData ? (
        <PageLoadingSpinner />
      ) : (
        <Stack>
          <span style={{ marginTop: "16px" }}>
            You can add, update and delete payment setting for attending the
            event by pinning marker.
          </span>

          <Box sx={{ marginTop: "16px" }}>
            <Button variant="outlined" onClick={onAddPaymentSettingButtonClick}>
              Add Setting
            </Button>
          </Box>

          <TableContainer component={Paper} sx={{ marginTop: "16px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Token</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell>Receiver Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!paymentSettings || !paymentSettings.length ? (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" colSpan="3">
                      No payment setting is created.
                    </TableCell>
                  </TableRow>
                ) : (
                  paymentSettings.map((ps) => {
                    let amount = ps.transferAmount.toString();

                    if (!/^\d+(\.\d+)?$/.test(amount)) {
                      amount = ps.transferAmount
                        .toFixed(ps.token.decimals)
                        .replace(/(\.0+|0+)$/, "");
                    }

                    return (
                      <TableRow
                        key={ps.token.address}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              onPaymentSettingUpdateClick(
                                ps.token.address,
                                ps.token.symbol,
                                amount,
                                ps.transferAddress
                              )
                            }
                          >
                            {ps.token.symbol}
                          </span>
                        </TableCell>
                        <TableCell align="right">{amount}</TableCell>
                        <TableCell>{ps.transferAddress}</TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}

      <Dialog open={showEditDialog} disableScrollLock>
        <DialogTitle sx={{ textAlign: "center" }}>
          {isUpdate ? "Edit Payment Setting" : "Add Payment Setting"}
        </DialogTitle>
        <DialogContent>
          <Stack>
            {!isUpdate ? (
              <>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  defaultValue={0}
                  onChange={onTokenMethodChange}
                  row
                >
                  <FormControlLabel
                    key={0}
                    value={0}
                    control={<Radio />}
                    label="Select Token"
                  />
                  <FormControlLabel
                    key={1}
                    value={1}
                    control={<Radio />}
                    label="Enter Token Address"
                    sx={{ marginLeft: !isVerySmall ? "8px" : "" }}
                  />
                </RadioGroup>

                {tokenMethod === 0 ? (
                  <>
                    <h4 className="paymentSettingFormLabel">Select Token</h4>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedToken}
                      onChange={onSelectedTokenChange}
                      error={tokenAddressError ? true : false}
                    >
                      {paymentSettingTokens[eventChain].map((ps, index) => {
                        return (
                          <MenuItem key={ps.address} value={index}>
                            {ps.symbol}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {tokenAddressError ? (
                      <span className="formInfo" style={{ color: "red" }}>
                        {tokenAddressError}
                      </span>
                    ) : null}
                  </>
                ) : (
                  <>
                    <h4 className="paymentSettingFormLabel">Token Address</h4>
                    <OutlinedInput
                      id="token-address-input"
                      variant="outlined"
                      value={tokenAddressInput}
                      onChange={onTokenAddressInputChange}
                      error={tokenAddressError ? true : false}
                      endAdornment={
                        fetchingSettingData ? (
                          <InputAdornment position="end">
                            <ProcessingIcon />
                          </InputAdornment>
                        ) : null
                      }
                    />
                    {tokenAddressError ? (
                      <span className="formInfo" style={{ color: "red" }}>
                        {tokenAddressError}
                      </span>
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <>
                <h4 className="paymentSettingFormLabel">Token Address</h4>
                <TextField
                  id="token-address-input"
                  variant="outlined"
                  value={tokenAddress}
                  disabled
                />
                {tokenAddressError ? (
                  <span className="formInfo" style={{ color: "red" }}>
                    {tokenAddressError}
                  </span>
                ) : null}
              </>
            )}
            <h4 className="paymentSettingFormLabel">Payment Amount</h4>
            <OutlinedInput
              value={paymentAmount}
              endAdornment={
                tokenSymbol ? (
                  <InputAdornment position="end">{tokenSymbol}</InputAdornment>
                ) : null
              }
              onChange={onPaymentAmountChange}
              error={paymentAmountError ? true : false}
            />
            {paymentAmountError ? (
              <span className="formInfo" style={{ color: "red" }}>
                {paymentAmountError}
              </span>
            ) : null}

            <h4 className="paymentSettingFormLabel">Receiver Address</h4>
            <TextField
              id="receiver-address-input"
              variant="outlined"
              value={receiverAddress}
              onChange={onReceiverAddressChange}
              error={receiverAddressError ? true : false}
            />
            {receiverAddressError ? (
              <span className="formInfo" style={{ color: "red" }}>
                {receiverAddressError}
              </span>
            ) : null}

            {showConfirmTransaction ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Please confirm transaction on your wallet
              </DialogContentText>
            ) : processing ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Processing...
              </DialogContentText>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="outlined" onClick={cancelEdit} disabled={processing}>
            Cancel
          </Button>
          {isUpdate ? (
            <Stack flexDirection="row" alignItems="center">
              <Button
                onClick={() => {
                  setShowDeleteConfirmDialog(true);
                }}
                variant="outlined"
                color="error"
                disabled={processing}
                sx={{ marginLeft: "8px" }}
                endIcon={processing && isDelete ? <ProcessingIcon /> : null}
              >
                Delete
              </Button>

              <Button
                variant="outlined"
                onClick={savePaymentSetting}
                disabled={!hasChanged || processing}
                endIcon={processing && !isDelete ? <ProcessingIcon /> : null}
                sx={{ marginLeft: "8px" }}
              >
                Update
              </Button>
            </Stack>
          ) : (
            <Button
              variant="outlined"
              disabled={processing}
              endIcon={processing ? <ProcessingIcon /> : null}
              onClick={savePaymentSetting}
            >
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={showDeleteConfirmDialog} disableScrollLock>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this setting?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            onClick={() => {
              setShowDeleteConfirmDialog(false);
            }}
          >
            Cancel
          </Button>

          <Button variant="outlined" onClick={onDeleteSettingConfirm}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessDialog
        showDialog={showSuccessDialog}
        message={successMessage}
        onCloseDialog={onSuccessDialogClose}
      />
    </Stack>
  );
}
