import axios from "axios";
import { PINYWORLD_API_END_POINT } from "constant";
import { getCoinMetadata } from "./coins";
import { toTokenValue, ZERO_ADDRESS } from "./formatters";
import { getNetworkDatabaseNameByCode } from "./networks";

export const getTokenMetadata = async (Moralis, address, chainId) => {
  if (address === ZERO_ADDRESS) {
    return getCoinMetadata(chainId);
  }

  let metadata;

  try {
    const response = await axios.get(
      PINYWORLD_API_END_POINT +
        "tokenMetadata/" +
        getNetworkDatabaseNameByCode(chainId) +
        "/" +
        address.toLowerCase()
    );

    metadata = response.data;
  } catch (err) {
    console.log(err);
  }

  if (!metadata || !metadata.symbol) {
    metadata = (
      await Moralis.Web3API.token.getTokenMetadata({
        chain: chainId,
        addresses: [address],
      })
    )[0];
  }

  return metadata;
};

export const computePaymentData = (paymentMethod, chainId) => {
  if (paymentMethod && paymentMethod.token) {
    return {
      payment: {
        amount: toTokenValue(
          paymentMethod.amount,
          paymentMethod.token.decimals
        ),
        metadata: paymentMethod.token,
        value: paymentMethod.amount,
      },
      paymentAddress: paymentMethod.paymentAddress
        ? paymentMethod.paymentAddress
        : ZERO_ADDRESS,
    };
  }

  return {
    payment: {
      amount: 0,
      metadata: getCoinMetadata(chainId),
      value: 0,
    },
    paymentAddress: ZERO_ADDRESS,
  };
};
