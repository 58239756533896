import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GroupIcon from "@mui/icons-material/Group";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import AppsIcon from "@mui/icons-material/Apps";
import {
  Box,
  Container,
  IconButton,
  Stack,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import ConnectWalletButton from "components/common/connectWalletButton";
import PageLoadingSpinner from "components/common/pageLoadingSpinner";
import { PINYWORLD_API_END_POINT } from "constant";
import { getEllipsisTxt } from "helpers/formatters";
import { getJwtToken, hasJwtToken } from "helpers/localStorage";
import {
  getChainIdByNetwork,
  getNetworkLogo,
  networkConfigs,
} from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";

export default function MyMarkersPage() {
  const {
    isWeb3Enabled,
    isWeb3EnableLoading,
    isAuthenticated,
    account,
    isInitialized,
  } = useMoralis();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const history = useHistory();

  const [fetchingData, setFetchingData] = useState(true);
  const [fetchingPageData, setFetchingPageData] = useState(true);
  const [markers, setMarkers] = useState(false);
  const [markersTotal, setMarkersTotal] = useState(0);
  const [markersPage, setMarkersPage] = useState(0);
  const [collections, setCollections] = useState([]);
  const [collectionsFetched, setCollectionsFetched] = useState(false);

  const jwtTokenExists = hasJwtToken();

  const fetchMarkers = useCallback(async () => {
    if (!isInitialized || (isWeb3EnableLoading && isAuthenticated)) {
      setFetchingData(true);
      return;
    }

    if (!isWeb3Enabled && !account && !isAuthenticated) {
      setMarkers(null);
      setFetchingData(false);
      return;
    }

    if (!isWeb3Enabled || !account || !isAuthenticated) {
      return;
    }

    setFetchingData(true);

    try {
      let response = await axios.post(
        PINYWORLD_API_END_POINT + "marker/query/criteria/count",
        {
          criteria: {
            accountList: [account],
            sortRequestList: [
              { sortField: "createTime", sortDirection: "DESC" },
            ],
          },
        }
      );

      setMarkersTotal(response.data);

      response = await axios.post(
        PINYWORLD_API_END_POINT + "marker/query/criteria",
        {
          criteria: {
            accountList: [account],
            pageSize: 10,
            pageNumber: 0,
            sortRequestList: [
              { sortField: "createTime", sortDirection: "DESC" },
            ],
          },
        }
      );

      setMarkers(response.data);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
    } finally {
      setFetchingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialized,
    isWeb3EnableLoading,
    isWeb3Enabled,
    isAuthenticated,
    account,
  ]);

  const fetchMarkersPage = useCallback(async () => {
    if (!account) {
      setMarkers(null);
      setFetchingPageData(false);
      return;
    }

    setFetchingPageData(true);

    try {
      const response = await axios.post(
        PINYWORLD_API_END_POINT + "marker/query/criteria",
        {
          criteria: {
            accountList: [account],
            pageSize: 10,
            pageNumber: markersPage,
            sortRequestList: [
              { sortField: "createTime", sortDirection: "DESC" },
            ],
          },
        }
      );

      setMarkers(response.data);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
    } finally {
      setFetchingPageData(false);
    }
  }, [account, markersPage]);

  useEffect(() => {
    fetchMarkers();
  }, [fetchMarkers]);

  useEffect(() => {
    fetchMarkersPage();
  }, [fetchMarkersPage]);

  const onMarkersPageChange = (event, newPage) => {
    setMarkersPage(newPage);
  };

  const fetchCollections = useCallback(async () => {
    if (collectionsFetched || !account || !isAuthenticated || !jwtTokenExists) {
      return;
    }
    setCollectionsFetched(true);
    try {
      const header = {
        Authorization: "Bearer " + getJwtToken(),
      };
      const response = await axios.get(
        PINYWORLD_API_END_POINT + "collection/byowner/" + account,
        { headers: header }
      );
      if (response && response.data) {
        setCollections(response.data);
      } else {
        setCollections([]);
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
    } finally {
      setCollectionsFetched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isAuthenticated, collections, hasJwtToken]);

  useEffect(() => {
    fetchCollections();
  }, [fetchCollections]);

  return (
    <Container
      maxWidth="md"
      sx={{ backgroundColor: "white", paddingBottom: "48px" }}
    >
      <Stack>
        <h1>My Markers</h1>

        {fetchingData ? (
          <PageLoadingSpinner />
        ) : !isAuthenticated || !account ? (
          <Box
            sx={{
              height: "200px",
              border: "1px solid",
              borderColor: "divider",
              borderRadius: "8px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack alignItems="center">
              <Typography>You must login to see your markers.</Typography>

              <Box sx={{ marginTop: "32px" }}>
                <ConnectWalletButton />
              </Box>
            </Stack>
          </Box>
        ) : (
          <Stack sx={{ rowGap: "16px", marginTop: "16px", overflow: "hidden" }}>
            {!markers || !markers.length || fetchingPageData ? (
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "calc(100% - 34px)",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: "8px",
                  padding: "16px",
                  height: "130px",
                }}
              >
                {!fetchingPageData ? (
                  <Typography>You don't have any markers</Typography>
                ) : (
                  <Typography>Loading...</Typography>
                )}
              </Stack>
            ) : (
              markers.map((marker) => {
                return (
                  <Stack
                    flexDirection="row"
                    sx={{
                      width: "calc(100% - 34px)",
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "8px",
                      padding: "16px",
                    }}
                    key={marker.id}
                  >
                    <Stack
                      flexDirection={isSmall ? "column" : "row"}
                      alignItems={isSmall ? "" : "center"}
                      sx={{ width: "100%" }}
                    >
                      <Stack
                        minWidth={isSmall ? "100%" : "96px"}
                        alignItems="center"
                      >
                        <img
                          src={`https://gateway.moralisipfs.com/ipfs/${marker.imageUrl}`}
                          alt={marker.id}
                          style={{
                            maxWidth: "96px",
                            maxHeight: "96px",
                            minWidth: "96px",
                          }}
                        />
                      </Stack>
                      <Stack
                        sx={{
                          minWidth: isSmall ? "100%" : "360px",
                          marginLeft: isSmall ? "" : "16px",
                          marginTop: isSmall ? "16px" : "",
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          textAlign={isSmall ? "center" : ""}
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {marker.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            marginTop: "8px",
                            minHeight: "40px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-all",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "2",
                            "-webkit-box-orient": "vertical",
                          }}
                        >
                          {marker.message}
                        </Typography>
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          sx={{ marginTop: "16px" }}
                        >
                          <img
                            src={getNetworkLogo(marker.network)}
                            alt={marker.network}
                            style={{
                              maxWidth: "24px",
                              maxHeight: "24px",
                              borderRadius: "50%",
                            }}
                            title={marker.network}
                          />

                          <Typography
                            variant="subtitle2"
                            sx={{ marginLeft: "8px", opacity: "0.65" }}
                          >
                            {marker.owner === account
                              ? "owned by you"
                              : getEllipsisTxt(marker.owner, 6)}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        sx={{
                          marginTop: isSmall ? "16px" : "",
                          marginLeft: isSmall ? "0px" : "16px",
                          minWidth: "100%",
                        }}
                      >
                        <Stack
                          sx={{
                            width: "100%",
                            maxWidth: isSmall ? "100%" : "280px",
                          }}
                        >
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            width="128px"
                          >
                            <PinDropOutlinedIcon
                              sx={{
                                color: "gray",
                                width: "18px",
                                height: "18px",
                                marginTop: "-2px",
                              }}
                            />

                            <Typography
                              sx={{ marginLeft: "2px", opacity: "0.65" }}
                              variant="subtitle2"
                            >
                              {marker.minter === account
                                ? "by you"
                                : getEllipsisTxt(marker.minter, 6)}
                            </Typography>
                          </Stack>

                          <Stack direction="row" alignItems="center" mt="8px">
                            <CalendarMonthIcon
                              sx={{
                                color: "gray",
                                width: "18px",
                                height: "18px",
                                marginTop: "-2px",
                              }}
                            />
                            <Typography
                              variant="subtitle2"
                              sx={{ marginLeft: "2px", opacity: "0.65" }}
                            >
                              {new Date(marker.createTime).toLocaleDateString()}
                            </Typography>
                          </Stack>

                          {marker.eventName ? (
                            <Stack
                              flexDirection="row"
                              width="100%"
                              alignItems="center"
                              mt="8px"
                            >
                              <GroupIcon
                                sx={{
                                  color: "gray",
                                  width: "18px",
                                  height: "18px",
                                  marginTop: "-2px",
                                }}
                              />

                              <Typography
                                variant="subtitle2"
                                sx={{
                                  opacity: "0.65",
                                  marginLeft: "2px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {marker.eventName}
                              </Typography>
                            </Stack>
                          ) : null}

                          {marker.collectionName ? (
                            <Stack
                              flexDirection="row"
                              width="100%"
                              alignItems="center"
                              mt="8px"
                            >
                              <AppsIcon
                                sx={{
                                  color: "gray",
                                  width: "18px",
                                  height: "18px",
                                  marginTop: "-2px",
                                }}
                              />

                              <Typography
                                variant="subtitle2"
                                sx={{
                                  marginLeft: "2px",
                                  opacity: "0.65",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {marker.collectionName}
                              </Typography>
                            </Stack>
                          ) : null}

                          {marker.listing ? (
                            <Stack
                              flexDirection="row"
                              width="100%"
                              alignItems="center"
                              mt="8px"
                            >
                              <SellOutlinedIcon
                                sx={{
                                  color: "gray",
                                  width: "18px",
                                  height: "18px",
                                  marginTop: "-2px",
                                }}
                              />
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  marginLeft: "2px",
                                  opacity: "0.65",
                                }}
                              >
                                {`${marker.listing.price.value} ${marker.listing.price.metadata.symbol}`}
                              </Typography>
                            </Stack>
                          ) : null}
                        </Stack>
                        <IconButton
                          onClick={() =>
                            history.push(
                              `/marker/${
                                networkConfigs[
                                  getChainIdByNetwork(marker.network)
                                ].code
                              }/${marker.tokenId}`
                            )
                          }
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })
            )}

            <TablePagination
              component="div"
              count={markersTotal}
              rowsPerPage={10}
              page={markersPage}
              onPageChange={onMarkersPageChange}
              rowsPerPageOptions={[10]}
            />
          </Stack>
        )}
      </Stack>
    </Container>
  );
}
