/*
 * 0xa869: avaxtestnet
 * 0x61: bsctestnet
 * 0x5: goerli
 * 0x13881: polygontestnet
 *
 * 0xa86a: avax
 * 0x38: bsc
 * 0x1: eth
 * 0x89: polygon
 *
 */

const ENVIRONMENT_TYPE = process.env.REACT_APP_ENVIRONMENT_TYPE;

export const PINYWORLD_NFT_ADDRESS = {
  dev: {
    "0xa869": "0x9Ebc703065ce4F8185A5b6aE3A7E2791ebe7B923",
    "0x61": "0xF846101765eBA473AaB694B07f567d6494F28492",
    "0x5": "0x9edF06CF880B419f6548058E7012e1C220D5a46A",
    "0x13881": "0x2F89C0B8c2A9E35A27BCc648c9391B3B19162Adb",
    "0x66eed": "0xebB1ed859eBFd5a2A7217776897eba54388E6002",
  },
  staging: {
    "0xa869": "0x0000000000000000000000000000000000000000",
    "0x61": "0x0000000000000000000000000000000000000000",
    "0x5": "0x0000000000000000000000000000000000000000",
    "0x13881": "0x0000000000000000000000000000000000000000",
    "0x66eed": "0x0000000000000000000000000000000000000000",
  },
  prod: {
    "0xa86a": "0x0000000000000000000000000000000000000000",
    "0x38": "0x0000000000000000000000000000000000000000",
    "0x1": "0x0000000000000000000000000000000000000000",
    "0x89": "0x0000000000000000000000000000000000000000",
    "0xa4b1": "0x0000000000000000000000000000000000000000",
  },
};

export const PINYWORLD_EVENT_ADDRESS = {
  dev: {
    "0xa869": "0xE417C69867CDcE589Dc5dc8AfdfFc009760Ec821",
    "0x61": "0x0235CE727E5c2f558B2D94A28D065f7888dAb24a",
    "0x5": "0xA70618f21B8a8f859cE834337Df0d1b8487526d8",
    "0x13881": "0xB93B4fd6585d81c9dBe6a1b5265928BBC0430823",
    "0x66eed": "0xff05bf9FB75A2b109395f72B2358d776f5111963",
  },
  staging: {
    "0xa869": "0x0000000000000000000000000000000000000000",
    "0x61": "0x0000000000000000000000000000000000000000",
    "0x5": "0x0000000000000000000000000000000000000000",
    "0x13881": "0x0000000000000000000000000000000000000000",
    "0x66eed": "0x0000000000000000000000000000000000000000",
  },
  prod: {
    "0xa86a": "0x0000000000000000000000000000000000000000",
    "0x38": "0x0000000000000000000000000000000000000000",
    "0x1": "0x0000000000000000000000000000000000000000",
    "0x89": "0x0000000000000000000000000000000000000000",
    "0xa4b1": "0x0000000000000000000000000000000000000000",
  },
};

export const PINYWORLD_MINT_LISTENER_ADDRESS = {
  dev: {
    "0xa869": "0x20FaF4b9EcDab9f29AB7658EEf9e1E57093e3Db0",
    "0x61": "0x3C9Fddc7a6168b9558881798049cd9cd71C6cEaF",
    "0x5": "0xeC175E0e89ABfa7Ae61850bff061cD37eF36662e",
    "0x13881": "0x24260f229C150344A988D9d755dC974c2ac38FDC",
    "0x66eed": "0xE285BDC76247eAf99C3B623c8F05E307c862BcEa",
  },
  staging: {
    "0xa869": "0x0000000000000000000000000000000000000000",
    "0x61": "0x0000000000000000000000000000000000000000",
    "0x5": "0x0000000000000000000000000000000000000000",
    "0x13881": "0x0000000000000000000000000000000000000000",
    "0x66eed": "0x0000000000000000000000000000000000000000",
  },
  prod: {
    "0xa86a": "0x0000000000000000000000000000000000000000",
    "0x38": "0x0000000000000000000000000000000000000000",
    "0x1": "0x0000000000000000000000000000000000000000",
    "0x89": "0x0000000000000000000000000000000000000000",
    "0xa4b1": "0x0000000000000000000000000000000000000000",
  },
};

export const PINYWORLD_EVENT_LISTENER_ADDRESS = {
  dev: {
    "0xa869": "0xdaE02D4bb6b6f3A831AA5aDe845C3576c9c6195D",
    "0x61": "0xE5764c307a1675E5499FfD6b51245F57995Ca46e",
    "0x5": "0x5305e0316b01AFac519012c73f8d6B0bda3e9466",
    "0x13881": "0xC764463c6cdff001F6A29cb098F82B5A91903862",
    "0x66eed": "0xaB6feB8B29D1A6CcE868e35C13d3AB29fd9958D4",
  },
  staging: {
    "0xa869": "0x0000000000000000000000000000000000000000",
    "0x61": "0x0000000000000000000000000000000000000000",
    "0x5": "0x0000000000000000000000000000000000000000",
    "0x13881": "0x0000000000000000000000000000000000000000",
    "0x66eed": "0x0000000000000000000000000000000000000000",
  },
  prod: {
    "0xa86a": "0x0000000000000000000000000000000000000000",
    "0x38": "0x0000000000000000000000000000000000000000",
    "0x1": "0x0000000000000000000000000000000000000000",
    "0x89": "0x0000000000000000000000000000000000000000",
    "0xa4b1": "0x0000000000000000000000000000000000000000",
  },
};

export const PINYWORLD_MARKET_ADDRESS = {
  dev: {
    "0xa869": "0x45951AbC7ED307Cf603eA8644bfEffC1f30c734a",
    "0x61": "0xe3C4E22da7742ac9D3a41C2E1d3a7f1C359786a9",
    "0x5": "0xD4510177899aA736321912CEdBF79f54fBe39be0",
    "0x13881": "0x88D40Aaf9E318997aBC2e3E043f23107b28E006E",
    "0x66eed": "0xaB6feB8B29D1A6CcE868e35C13d3AB29fd9958D4",
  },
  staging: {
    "0xa869": "0x0000000000000000000000000000000000000000",
    "0x61": "0x0000000000000000000000000000000000000000",
    "0x5": "0x0000000000000000000000000000000000000000",
    "0x13881": "0x0000000000000000000000000000000000000000",
    "0x66eed": "0x0000000000000000000000000000000000000000",
  },
  prod: {
    "0xa86a": "0x0000000000000000000000000000000000000000",
    "0x38": "0x0000000000000000000000000000000000000000",
    "0x1": "0x0000000000000000000000000000000000000000",
    "0x89": "0x0000000000000000000000000000000000000000",
    "0xa4b1": "0x0000000000000000000000000000000000000000",
  },
};

export const PINYWORLD_TOKEN_ADDRESS_USDT = {
  "0xa869": "0xa4116d0376C82826f651d970dD612D8f0a1f9982",
  "0x61": "0x8f9B699203c06fdE87c3BaE6AD10C2DFA5348fBe",
  "0x5": "0x171693817e0C76335A65DBc30dCD955282A28dAa",
  "0x13881": "0x970a7d0B5859493e81413d143693060846215A3F",
  "0x66eed": "0x970a7d0B5859493e81413d143693060846215A3F",
  "0xa86a": "0xc7198437980c041c805a1edcba50c1ce5db95118",
  "0x38": "0x55d398326f99059fF775485246999027B3197955",
  "0x1": "0xdac17f958d2ee523a2206206994597c13d831ec7",
  "0x89": "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
  "0xa4b1": "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
};

export const getPinyWorldNftAddress = (chainId) => {
  return PINYWORLD_NFT_ADDRESS[ENVIRONMENT_TYPE][chainId];
};

export const getPinyWorldEventAddress = (chainId) => {
  return PINYWORLD_EVENT_ADDRESS[ENVIRONMENT_TYPE][chainId];
};

export const getPinyWorldMintListenerAddress = (chainId) => {
  return PINYWORLD_MINT_LISTENER_ADDRESS[ENVIRONMENT_TYPE][chainId];
};

export const getPinyWorldEventListenerAddress = (chainId) => {
  return PINYWORLD_EVENT_LISTENER_ADDRESS[ENVIRONMENT_TYPE][chainId];
};

export const getPinyWorldMarketAddress = (chainId) => {
  return PINYWORLD_MARKET_ADDRESS[ENVIRONMENT_TYPE][chainId];
};

export const getUSDTTokenAddress = (chainId) => {
  return PINYWORLD_TOKEN_ADDRESS_USDT[chainId];
};

export const getScannerERC721UrlByNetworkCode = (network, tokenId) => {
  if (process.env.REACT_APP_WORK_WITH_TESTNET) {
    switch (network.toLowerCase()) {
      case "bsc":
        return `https://testnet.bscscan.com/token/${PINYWORLD_NFT_ADDRESS[ENVIRONMENT_TYPE]["0x61"]}?a=${tokenId}`;
      case "eth":
        return `https://goerli.etherscan.io/token/${PINYWORLD_NFT_ADDRESS[ENVIRONMENT_TYPE]["0x5"]}?a=${tokenId}`;
      case "avax":
        return `https://testnet.avascan.info/blockchain/c/erc721/${PINYWORLD_NFT_ADDRESS[ENVIRONMENT_TYPE]["0xa869"]}/nft/${tokenId}`;
      case "polygon":
        return `https://mumbai.polygonscan.com/token/${PINYWORLD_NFT_ADDRESS[ENVIRONMENT_TYPE]["0x13881"]}?a=${tokenId}`;
      default:
        return null;
    }
  }
};
