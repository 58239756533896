import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { getNetworkLogo } from "helpers/networks";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import GroupIcon from "@mui/icons-material/Group";
import AppsIcon from "@mui/icons-material/Apps";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useMoralis } from "react-moralis";
import { getEllipsisTxt } from "helpers/formatters";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";

export default function MarkerInfoDialog({ markerData, markerDataSetter }) {
  const { account } = useMoralis();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (markerData) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [markerData]);

  const handleClose = () => {
    markerDataSetter(null);
  };

  if (!markerData) {
    return null;
  }

  return (
    <Dialog disableScrollLock open={open}>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {markerData.name ? (
            <Typography
              variant="h5"
              textAlign="center"
              sx={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {markerData.name}
            </Typography>
          ) : (
            <Typography
              variant="h5"
              sx={{ color: "rgba(0, 0, 0, 0.26)", fontStyle: "italic" }}
            >
              Unnamed Marker
            </Typography>
          )}
          <IconButton
            onClick={() => {
              handleClose();
            }}
            style={{ margin: "-16px -16px 0px 16px" }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center">
          <img
            src={`https://gateway.moralisipfs.com/ipfs/${markerData.imageUrl}`}
            style={{ maxWidth: "196px", maxHeight: "196px", marginTop: "16px" }}
            alt={markerData.name}
          />

          {markerData.message ? (
            <Typography
              variant="body2"
              textAlign="center"
              sx={{
                width: "100%",
                marginTop: "16px",
                minHeight: "40px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "pre-wrap",
                wordBreak: "break-all",
                display: "-webkit-box",
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
              }}
            >
              {markerData.message}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              textAlign="center"
              sx={{
                marginTop: "16px",
                color: "rgba(0, 0, 0, 0.26)",
                fontStyle: "italic",
              }}
            >
              No message
            </Typography>
          )}

          {markerData.eventName ? (
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{ marginTop: "16px", maxWidth: "100%" }}
            >
              <GroupIcon
                sx={{
                  color: "gray",
                  width: "18px",
                  height: "18px",
                  marginTop: "-2px",
                }}
              />
              <Typography
                variant="subtitle2"
                sx={{
                  marginLeft: "2px",
                  opacity: "0.65",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {markerData.eventName}
              </Typography>
            </Stack>
          ) : null}

          {markerData.collectionName ? (
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{ marginTop: "8px", maxWidth: "100%" }}
            >
              <AppsIcon
                sx={{
                  color: "gray",
                  width: "18px",
                  height: "18px",
                  marginTop: "-2px",
                }}
              />
              <Typography
                variant="subtitle2"
                sx={{
                  marginLeft: "2px",
                  opacity: "0.65",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {markerData.collectionName}
              </Typography>
            </Stack>
          ) : null}

          <Stack flexDirection="row" mt="32px" justifyContent="space-between">
            <Stack flexDirection="row" alignItems="center" width="128px">
              <PinDropOutlinedIcon
                sx={{
                  color: "gray",
                  width: "18px",
                  height: "18px",
                  marginTop: "-2px",
                }}
              />

              <Typography
                sx={{ marginLeft: "2px", opacity: "0.65" }}
                variant="subtitle2"
              >
                {markerData.minter === account
                  ? "by you"
                  : getEllipsisTxt(markerData.minter, 6)}
              </Typography>
            </Stack>
            {markerData.owner && markerData.owner !== markerData.minter ? (
              <Stack
                flexDirection="row"
                alignItems="center"
                width="128px"
                ml="32px"
              >
                <PersonPinCircleOutlinedIcon
                  sx={{
                    color: "gray",
                    width: "18px",
                    height: "18px",
                    marginTop: "-2px",
                  }}
                />

                <Typography
                  sx={{ marginLeft: "2px", opacity: "0.65" }}
                  variant="subtitle2"
                >
                  {markerData.owner === account
                    ? "by you"
                    : getEllipsisTxt(markerData.owner, 6)}
                </Typography>
              </Stack>
            ) : null}
          </Stack>

          {markerData.listing ? (
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{ marginTop: "16px" }}
            >
              <SellOutlinedIcon />
              <Typography variant="h6" sx={{ marginLeft: "8px" }}>
                {`${markerData.listing.price.value} ${markerData.listing.price.metadata.symbol}`}
              </Typography>
            </Stack>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ padding: "0px 16px 16px 16px", justifyContent: "space-between" }}
      >
        <img
          src={getNetworkLogo(markerData.network)}
          alt={markerData.network}
          style={{
            maxWidth: "24px",
            maxHeight: "24px",
            borderRadius: "50%",
          }}
          title={markerData.network}
        />
        <Link
          to={`/marker/${markerData.network.toLowerCase()}/${
            markerData.tokenId
          }`}
          className="buttonLink"
        >
          <Button onClick={() => {}} endIcon={<ArrowForwardIosOutlinedIcon />}>
            GO TO DETAILS
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}
