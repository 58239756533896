import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { PINYWORLD_API_END_POINT } from "constant";
import { getEllipsisTxt } from "helpers/formatters";
import { getChainIdByNetwork } from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { useCallback, useEffect, useState } from "react";
import Blockies from "react-blockies";
import { useHistory, useParams } from "react-router-dom";
import { FacebookIcon, TwitterIcon } from "react-share";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import PageLoadingSpinner from "components/common/pageLoadingSpinner";
import { networkConfigs } from "helpers/networks";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GroupIcon from "@mui/icons-material/Group";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { useMoralis } from "react-moralis";
import { getNetworkLogo } from "helpers/networks";
import LeafletMarkerMapWithFilter from "components/leafletMap/markerMapWithFilter";
import { SHARE_URL } from "constant";

export default function CollectionHomePage() {
  const history = useHistory();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { collectionId } = useParams();
  const { account } = useMoralis();

  const [dataReady, setDataReady] = useState(false);
  const [collectionNotFound, setCollectionNotFound] = useState(false);
  const [collectionData, setCollectionData] = useState();

  const [fetchingAllMarkers, setFetchingAllMarkers] = useState(false);
  const [allMarkers, setAllMarkers] = useState([]);
  const [allMarkersPage, setAllMarkersPage] = useState(0);

  const fecthData = useCallback(async () => {
    setDataReady(false);

    try {
      const response = await axios.get(
        PINYWORLD_API_END_POINT + "collection/byid/" + collectionId
      );

      const collectionData = response && response.data && response.data.dto;

      if (collectionData) {
        setCollectionData(collectionData);
        setCollectionNotFound(false);
      } else {
        setCollectionNotFound(true);
      }

      setDataReady(true);
    } catch (err) {
      setDataReady(false);
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  const fetchAllMarkers = useCallback(async () => {
    setFetchingAllMarkers(true);
    await fetchAllMarkersPage(allMarkersPage);
    setFetchingAllMarkers(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMarkersPage]);

  useEffect(() => {
    fecthData();
  }, [fecthData]);

  useEffect(() => {
    fetchAllMarkers();
  }, [fetchAllMarkers]);

  const fetchAllMarkersPage = async (page) => {
    setAllMarkers(
      (
        await axios.post(PINYWORLD_API_END_POINT + "marker/query/criteria", {
          criteria: {
            collectionIdList: [collectionId],
            pageSize: 10,
            pageNumber: page,
            sortRequestList: [
              { sortField: "createTime", sortDirection: "DESC" },
            ],
          },
        })
      ).data
    );
  };

  const onAllMarkersPageChange = (event, newPage) => {
    setAllMarkersPage(newPage);
  };

  return (
    <Stack width="100%">
      {!dataReady ? (
        <Container
          maxWidth="md"
          sx={{
            backgroundColor: "white",
            paddingTop: "32px",
          }}
        >
          <PageLoadingSpinner />
        </Container>
      ) : (
        <>
          <Container
            maxWidth="md"
            sx={{
              backgroundColor: "white",
              paddingTop: "32px",
              paddingBottom: "16px",
            }}
          >
            <Stack
              flexDirection={isSmall ? "column" : "row"}
              width="100%"
              justifyContent={isSmall ? "" : "space-between"}
            >
              <Stack alignItems={isSmall ? "center" : "flex-start"}>
                <Typography
                  variant="h4"
                  textAlign={isSmall ? "center" : ""}
                  gutterBottom
                  sx={{ wordBreak: "break-all" }}
                >
                  {collectionData.name}
                </Typography>
                <Typography
                  variant="body1"
                  textAlign={isSmall ? "center" : ""}
                  gutterBottom
                  sx={{ whiteSpace: "pre-line", wordBreak: "break-all" }}
                >
                  {collectionData.description}
                </Typography>
                <Stack
                  flexDirection="row"
                  mt="32px"
                  alignItems="center"
                  width="100%"
                  justifyContent="flex-start"
                >
                  <Typography variant="subtitle2">Share Collection</Typography>
                  <Typography variant="body2" marginLeft="8px">
                    <TwitterShareButton
                      url={`${SHARE_URL}/collection/${collectionId}`}
                      hashtags={["piny", "collection"]}
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <FacebookShareButton
                      url={`${SHARE_URL}/collection/${collectionId}`}
                      hashtag={"#piny"}
                      style={{ marginLeft: "8px" }}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                mt={isSmall ? "32px" : "16px"}
                ml={isSmall ? "0px" : "16px"}
                maxWidth={isSmall ? "100%" : "34%"}
              >
                <Stack flexDirection="column">
                  <Typography variant="subtitle2">Owner</Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ height: "24px" }}
                  >
                    <Blockies
                      seed={collectionData.owner.toLowerCase()}
                      className="blockie"
                      size={6}
                    />
                    <Typography variant="body2" sx={{ marginLeft: "4px" }}>
                      {getEllipsisTxt(collectionData.owner, 8)}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection="column" mt="8px">
                  <Typography variant="subtitle2">Created at</Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ height: "24px" }}
                  >
                    <CalendarMonthIcon />
                    <Typography variant="body2" sx={{ marginLeft: "4px" }}>
                      {new Date(collectionData.createTime).toLocaleDateString()}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection="column" mt="8px">
                  <Typography variant="subtitle2">Marker Count</Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ height: "24px" }}
                  >
                    <FmdGoodOutlinedIcon sx={{ marginTop: "-2px" }} />
                    <Typography variant="body2" sx={{ marginLeft: "4px" }}>
                      {collectionData.markerCount}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Container>

          <Container maxWidth="md" sx={{ backgroundColor: "white" }}>
            <Stack alignItems="center">
              <LeafletMarkerMapWithFilter
                textSearch
                searchFields={{
                  name: true,
                  message: true,
                  minter: true,
                  owner: true,
                  event: true,
                }}
                defaultCriteria={{
                  collectionIdList: [collectionId],
                }}
                initialZoom={2}
                center={{
                  lat: 41,
                  lng: 0.0,
                }}
                style={{
                  borderRadius: "8px",
                  height: `${window.innerWidth * (9 / 16)}px`,
                  maxHeight: "600px",
                  minHeight: "480px",
                }}
              ></LeafletMarkerMapWithFilter>
            </Stack>
          </Container>

          <Container
            maxWidth="md"
            sx={{
              backgroundColor: "white",
              paddingTop: "16px",
              paddingBottom: "48px",
            }}
          >
            <Stack
              sx={{
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <Box
                padding="16px"
                sx={{ borderBottom: "1px solid", borderColor: "divider" }}
              >
                <Typography variant="h5">Markers</Typography>
              </Box>
              <Stack padding="0px 8px">
                {fetchingAllMarkers || !allMarkers || !allMarkers.length ? (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: "calc(100% - 32px)",
                      borderBottom: "1px solid",
                      borderColor: "divider",
                      padding: "16px",
                      height: "130px",
                    }}
                  >
                    {!fetchingAllMarkers ? (
                      <Typography>No marker in this collection.</Typography>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </Stack>
                ) : (
                  allMarkers.map((marker) => {
                    return (
                      <Stack
                        flexDirection="row"
                        sx={{
                          width: "calc(100%-16px)",
                          borderBottom: "1px solid",
                          borderColor: "divider",
                          padding: "16px 8px",
                        }}
                        key={marker.id}
                      >
                        <Stack
                          flexDirection={isSmall ? "column" : "row"}
                          alignItems={isSmall ? "" : "center"}
                          sx={{ width: "100%" }}
                        >
                          <Stack
                            minWidth={isSmall ? "100%" : "96px"}
                            alignItems="center"
                          >
                            <img
                              src={`https://gateway.moralisipfs.com/ipfs/${marker.imageUrl}`}
                              alt={marker.id}
                              style={{
                                maxWidth: "96px",
                                maxHeight: "96px",
                                minWidth: "96px",
                              }}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              minWidth: isSmall ? "100%" : "360px",
                              marginLeft: isSmall ? "" : "24px",
                              marginTop: isSmall ? "16px" : "",
                            }}
                          >
                            <Typography
                              variant="h6"
                              component="div"
                              textAlign={isSmall ? "center" : ""}
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {marker.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                marginTop: "8px",
                                minHeight: "40px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-all",
                                display: "-webkit-box",
                                "-webkit-line-clamp": "2",
                                "-webkit-box-orient": "vertical",
                              }}
                            >
                              {marker.message}
                            </Typography>
                            <Stack
                              flexDirection="row"
                              alignItems="center"
                              sx={{ marginTop: "16px" }}
                            >
                              <img
                                src={getNetworkLogo(marker.network)}
                                alt={marker.network}
                                style={{
                                  maxWidth: "24px",
                                  maxHeight: "24px",
                                  borderRadius: "50%",
                                }}
                                title={marker.network}
                              />

                              <Typography
                                variant="subtitle2"
                                sx={{ marginLeft: "8px", opacity: "0.65" }}
                              >
                                {marker.owner === account
                                  ? "owned by you"
                                  : getEllipsisTxt(marker.owner, 6)}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            sx={{
                              marginTop: isSmall ? "16px" : "",
                              marginLeft: isSmall ? "0px" : "16px",
                              minWidth: "100%",
                            }}
                          >
                            <Stack
                              sx={{
                                width: "100%",
                                maxWidth: isSmall ? "100%" : "280px",
                              }}
                            >
                              <Stack
                                flexDirection="row"
                                alignItems="center"
                                width="128px"
                              >
                                <PinDropOutlinedIcon
                                  sx={{
                                    color: "gray",
                                    width: "18px",
                                    height: "18px",
                                    marginTop: "-2px",
                                  }}
                                />

                                <Typography
                                  sx={{ marginLeft: "2px", opacity: "0.65" }}
                                  variant="subtitle2"
                                >
                                  {marker.minter === account
                                    ? "by you"
                                    : getEllipsisTxt(marker.minter, 6)}
                                </Typography>
                              </Stack>

                              <Stack
                                direction="row"
                                alignItems="center"
                                mt="8px"
                              >
                                <CalendarMonthIcon
                                  sx={{
                                    color: "gray",
                                    width: "18px",
                                    height: "18px",
                                    marginTop: "-2px",
                                  }}
                                />
                                <Typography
                                  variant="subtitle2"
                                  sx={{ marginLeft: "2px", opacity: "0.65" }}
                                >
                                  {new Date(
                                    marker.createTime
                                  ).toLocaleDateString()}
                                </Typography>
                              </Stack>

                              {marker.eventName ? (
                                <Stack
                                  flexDirection="row"
                                  width="100%"
                                  alignItems="center"
                                  mt="8px"
                                >
                                  <GroupIcon
                                    sx={{
                                      color: "gray",
                                      width: "18px",
                                      height: "18px",
                                      marginTop: "-2px",
                                    }}
                                  />

                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      opacity: "0.65",
                                      marginLeft: "2px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {marker.eventName}
                                  </Typography>
                                </Stack>
                              ) : null}

                              {marker.listing ? (
                                <Stack
                                  flexDirection="row"
                                  width="100%"
                                  alignItems="center"
                                  mt="8px"
                                >
                                  <SellOutlinedIcon
                                    sx={{
                                      color: "gray",
                                      width: "18px",
                                      height: "18px",
                                      marginTop: "-2px",
                                    }}
                                  />
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      marginLeft: "2px",
                                      opacity: "0.65",
                                    }}
                                  >
                                    {`${marker.listing.price.value} ${marker.listing.price.metadata.symbol}`}
                                  </Typography>
                                </Stack>
                              ) : null}
                            </Stack>
                            <IconButton
                              onClick={() =>
                                history.push(
                                  `/marker/${
                                    networkConfigs[
                                      getChainIdByNetwork(marker.network)
                                    ].code
                                  }/${marker.tokenId}`
                                )
                              }
                            >
                              <ArrowForwardIosIcon />
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Stack>
                    );
                  })
                )}
                <TablePagination
                  component="div"
                  count={collectionData.markerCount}
                  rowsPerPage={10}
                  page={allMarkersPage}
                  onPageChange={onAllMarkersPageChange}
                  rowsPerPageOptions={[10]}
                />
              </Stack>
            </Stack>
          </Container>
        </>
      )}

      <Dialog open={collectionNotFound} disableScrollLock>
        <DialogTitle sx={{ textAlign: "center" }}>
          Collection Not Found
        </DialogTitle>
        <DialogContent>
          <Stack>
            <Stack
              flexDirection="row"
              justifyContent="center"
              marginTop="16px"
              marginBottom="32px"
            >
              <WarningAmberOutlinedIcon
                color="warning"
                sx={{ width: "80px", height: "80px" }}
              />
            </Stack>

            <DialogContentText sx={{ marginTop: "8px" }}>
              Collection ID: {collectionId}
            </DialogContentText>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
