import DoneIcon from "@mui/icons-material/Done";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import PageLoadingSpinner from "components/common/pageLoadingSpinner";
import ProcessingIcon from "components/common/processingIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SuccessDialog from "components/common/successDialog";
import MarkerEditor from "components/markerEditor";
import {
  getPinyWorldEventAddress,
  getPinyWorldEventListenerAddress,
} from "constant/ContractAddress";
import erc20abi from "contracts/abi/ERC20.json";
import eventabi from "contracts/abi/PinyWorldEvent.json";
import eventlistenerabi from "contracts/abi/PinyWorldEventListener.json";
import { tokenValue, toTokenValue, ZERO_ADDRESS } from "helpers/formatters";
import { getChainIdByNetwork } from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { getTokenMetadata } from "helpers/tokens";
import { runContractFunction } from "helpers/web3utils";

export default function UpdateEventMarkers({
  eventData,
  markers,
  markersSetter,
  addMarkerPaymentSettings,
  addMarkerPaymentSettingsSetter,
}) {
  const { Moralis, account, isAuthenticated } = useMoralis();

  const isVerySmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [fetchingData, setFetchingData] = useState(true);
  const [showMarkerEditor, setShowMarkerEditor] = useState(false);
  const [imageToAdd, setImageToAdd] = useState();
  const [showAddMarkerDialog, setShowAddMarkerDialog] = useState(false);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [checkingPaymentApproval, setCheckingPaymentApproval] = useState(false);
  const [approvingPayment, setApprovingPayment] = useState(false);
  const [paymentApproved, setPaymentApproved] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [uploadingImageToIPFS, setUploadingImageToIPFS] = useState(false);
  const [showConfirmTransaction, setShowConfirmTransaction] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [markerToDelete, setMarkerToDelete] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);

  const eventChain = getChainIdByNetwork(eventData.network);

  const fetchMarkers = useCallback(async () => {
    if (markers && addMarkerPaymentSettings) {
      setFetchingData(false);
      return;
    }

    if (!markers) {
      const options = {
        chain: eventChain,
        address: getPinyWorldEventAddress(eventChain),
        functionName: "getEventMarkerImageURIList",
        abi: eventabi,
        params: { _eventId: eventData.eventId },
      };

      setFetchingData(true);

      markersSetter(await runContractFunction(options));
    }

    if (!addMarkerPaymentSettings) {
      const options = {
        chain: eventChain,
        address: getPinyWorldEventListenerAddress(eventChain),
        functionName: "getAddMarkerPaymentSettingList",
        abi: eventlistenerabi,
      };

      setFetchingData(true);

      const paymentSettingList = await runContractFunction(options);

      const paymentSettingListWithTokenMetadata = [];

      await Promise.all(
        paymentSettingList.map(async (ps) => {
          const tokenMetadata = await getTokenMetadata(
            Moralis,
            ps[0],
            eventChain
          );

          const transferAmount = tokenValue(ps[1][1], tokenMetadata.decimals);

          paymentSettingListWithTokenMetadata.push({
            token: tokenMetadata,
            transferAmount,
            transferAddress: ps[1][2],
          });
        })
      );

      paymentSettingListWithTokenMetadata.sort((ps1, ps2) => {
        if (ps1 && ps1.token && ps1.token.symbol && ps2 && ps2.token) {
          return ps1.token.symbol.localeCompare(ps2.token.symbol);
        }

        return 0;
      });

      addMarkerPaymentSettingsSetter(paymentSettingListWithTokenMetadata);
    }

    setFetchingData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers]);

  const checkPaymentApproval = useCallback(async () => {
    if (!paymentMethod || !isAuthenticated) {
      setCheckingPaymentApproval(false);
      return;
    }

    setCheckingPaymentApproval(true);
    setPaymentApproved(false);
    setInsufficientBalance(false);

    if (paymentMethod.token.address !== ZERO_ADDRESS) {
      let options = {
        contractAddress: paymentMethod.token.address,
        functionName: "allowance",
        abi: erc20abi,
        params: {
          owner: account,
          spender: getPinyWorldEventListenerAddress(eventChain),
        },
      };

      const allowance = tokenValue(
        await Moralis.executeFunction(options),
        paymentMethod.token.decimals
      );

      if (allowance >= paymentMethod.transferAmount) {
        setPaymentApproved(true);
      }

      options = {
        contractAddress: paymentMethod.token.address,
        functionName: "balanceOf",
        abi: erc20abi,
        params: {
          account: account,
        },
      };

      const balance = tokenValue(
        await Moralis.executeFunction(options),
        paymentMethod.token.decimals
      );

      if (balance < paymentMethod.transferAmount) {
        setInsufficientBalance(true);
      }
    } else if (paymentMethod.token.address === ZERO_ADDRESS) {
      setPaymentApproved(true);

      const options = { chain: eventChain };
      const balance = tokenValue(
        (await Moralis.Web3API.account.getNativeBalance(options))["balance"],
        paymentMethod.token.decimals
      );

      if (balance < paymentMethod.transferAmount) {
        setInsufficientBalance(true);
      }
    }

    setCheckingPaymentApproval(false);
  }, [Moralis, account, isAuthenticated, eventChain, paymentMethod]);

  useEffect(() => {
    fetchMarkers();
  }, [fetchMarkers]);

  useEffect(() => {
    checkPaymentApproval();
  }, [checkPaymentApproval]);

  const onAddMarkerClick = () => {
    setImageToAdd("");
    setSelectedPaymentIndex(0);
    setPaymentMethod(null);
    setInsufficientBalance(false);
    setCheckingPaymentApproval(false);
    setApprovingPayment(false);
    setProcessing(false);
    setUploadingImageToIPFS(false);
    setShowConfirmTransaction(false);
    setShowMarkerEditor(true);
  };

  const onPaymentMethodChange = (event) => {
    if (
      !addMarkerPaymentSettings ||
      event.target.value > addMarkerPaymentSettings.length
    ) {
      return;
    }

    setSelectedPaymentIndex(parseInt(event.target.value));
  };

  const cancelAddMarker = () => {
    setShowAddMarkerDialog(false);
  };

  const backToEdit = () => {
    setShowAddMarkerDialog(false);
    setImageToAdd("");
    setShowMarkerEditor(true);
  };

  const approvePaymentMethod = async () => {
    if (!paymentMethod || !paymentMethod.token) {
      return;
    }

    setApprovingPayment(true);
    setShowConfirmTransaction(true);

    const options = {
      contractAddress: paymentMethod.token.address,
      functionName: "approve",
      abi: erc20abi,
      params: {
        spender: getPinyWorldEventListenerAddress(eventChain),
        amount: toTokenValue(
          paymentMethod.transferAmount,
          paymentMethod.token.decimals
        ),
      },
    };

    try {
      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();

      setPaymentApproved(true);
    } catch (err) {
      if (err && err.code !== 4001) {
        ToastUtils.toastUtil("error", JSON.stringify(err));
      }
      return;
    } finally {
      setApprovingPayment(false);
      setShowConfirmTransaction(false);
    }
  };

  const submitAddMarker = async () => {
    if (
      addMarkerPaymentSettings &&
      addMarkerPaymentSettings.length &&
      (!paymentMethod || !paymentApproved)
    ) {
      return;
    }

    setProcessing(true);
    setUploadingImageToIPFS(true);

    let imageIpfsPath;

    try {
      const imageFile = new Moralis.File("image.png", {
        base64: imageToAdd,
      });

      let path = await Moralis.Web3API.storage.uploadFolder({
        abi: [
          {
            path: "image.png",
            content: imageFile._data,
          },
        ],
      });

      // Get IPFS path from https://ipfs.moralis.io:2053/ipfs/QmRGnnNvpMD54ieTh96s7ECk9wrANkZ4H6hYGpHzEaHbHh/image.png
      imageIpfsPath = path[0].path.substring(34);
    } catch (err) {
      setProcessing(false);
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
      return;
    } finally {
      setUploadingImageToIPFS(false);
    }

    setShowConfirmTransaction(true);

    try {
      const options = {
        contractAddress: getPinyWorldEventAddress(eventChain),
        functionName: "addMarker",
        abi: eventabi,
        params: {
          _eventId: eventData.eventId,
          _markerImageURI: imageIpfsPath,
          _token: paymentMethod ? paymentMethod.token.address : ZERO_ADDRESS,
        },
      };

      if (paymentMethod && paymentMethod.token.address === ZERO_ADDRESS) {
        options["msgValue"] = toTokenValue(
          paymentMethod.transferAmount,
          paymentMethod.token.decimals
        );
      }

      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();

      markersSetter(null);
      setSuccessMessage("Marker succesfully added");
      setShowSuccessDialog(true);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
      return;
    } finally {
      setProcessing(false);
      setShowConfirmTransaction(false);
    }

    setShowAddMarkerDialog(false);
  };

  const onSuccessDialogClose = () => {
    setSuccessMessage("");
    setShowSuccessDialog(false);
  };

  const onDeleteMarkerClick = (marker) => {
    setMarkerToDelete(marker);
    setShowDeleteConfirmDialog(true);
  };

  const onCancelDeleteMarker = () => {
    setMarkerToDelete("");
    setShowDeleteConfirmDialog(false);
  };

  const deleteMarker = async () => {
    if (!markerToDelete) {
      return;
    }

    const options = {
      contractAddress: getPinyWorldEventAddress(eventChain),
      functionName: "removeMarker",
      abi: eventabi,
      params: {
        _eventId: eventData.eventId,
        _markerImageURI: markerToDelete,
      },
    };

    try {
      setShowConfirmTransaction(true);

      const transaction = await Moralis.executeFunction(options);
      await transaction.wait();

      markersSetter(null);
      setShowDeleteConfirmDialog(false);
      setSuccessMessage("Marker succesfully deleted");
      setShowSuccessDialog(true);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
      return;
    } finally {
      setShowConfirmTransaction(false);
    }
  };

  useEffect(() => {
    if (imageToAdd) {
      setShowAddMarkerDialog(true);
    }
  }, [imageToAdd]);

  useEffect(() => {
    if (
      !imageToAdd ||
      !addMarkerPaymentSettings ||
      !addMarkerPaymentSettings.length ||
      selectedPaymentIndex >= addMarkerPaymentSettings.length
    ) {
      setPaymentMethod(null);
    } else {
      setPaymentMethod(addMarkerPaymentSettings[selectedPaymentIndex]);
    }
  }, [addMarkerPaymentSettings, selectedPaymentIndex, imageToAdd]);

  return (
    <>
      {fetchingData ? (
        <PageLoadingSpinner />
      ) : (
        <Stack>
          <span style={{ marginTop: "16px" }}>
            You can add and delete markers for the event.
          </span>

          <Box sx={{ marginTop: "16px" }}>
            <Button variant="outlined" onClick={onAddMarkerClick}>
              Add Marker
            </Button>
          </Box>
          <Grid container sx={{ marginTop: "16px" }}>
            {!markers || !markers.length ? (
              <Grid
                sx={{
                  height: "200px",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: "8px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>No marker added</span>
              </Grid>
            ) : (
              <Stack width="100%">
                <span style={{ paddingTop: "16px", paddingBottom: "16px" }}>
                  Total {markers.length} markers
                </span>

                <Grid container spacing={2}>
                  {markers.map((marker) => {
                    return (
                      <Grid item key={marker} xs={12} sm={6} md={4}>
                        <Card>
                          <CardContent sx={{ textAlign: "center" }}>
                            <img
                              src={`https://gateway.moralisipfs.com/ipfs/${marker}`}
                              alt={marker}
                              style={{ maxWidth: "100%" }}
                            />
                          </CardContent>
                          <CardActions sx={{ justifyContent: "flex-end" }}>
                            <Button
                              size="small"
                              onClick={() => {
                                onDeleteMarkerClick(marker);
                              }}
                            >
                              Delete
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Stack>
            )}
          </Grid>
        </Stack>
      )}
      <MarkerEditor
        showDialog={showMarkerEditor}
        onDialogClose={() => {
          setShowMarkerEditor(false);
        }}
        imageSetter={setImageToAdd}
      />
      <Dialog
        open={showAddMarkerDialog}
        PaperProps={{ sx: { margin: isVerySmall ? "4px" : "" } }}
        disableScrollLock
      >
        <DialogTitle sx={{ textAlign: "center" }}>Add Marker</DialogTitle>
        <DialogContent>
          <Stack>
            <h4 className="paymentSettingFormLabel">Marker Image</h4>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              width="296px"
              height="296px"
              border="2px gray dashed"
              padding="8px"
              borderRadius="8px"
            >
              <img
                src={imageToAdd}
                alt="Marker"
                style={{ maxWidth: "296px", maxHeight: "296px" }}
              />
            </Stack>

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              marginTop="8px"
            >
              <Button
                onClick={backToEdit}
                disabled={processing}
                startIcon={
                  <ArrowBackIosNewIcon sx={{ width: "16px", height: "16px" }} />
                }
              >
                Back To Edit
              </Button>
            </Stack>

            {addMarkerPaymentSettings && addMarkerPaymentSettings.length ? (
              <>
                <h4 className="formLabel">Payment Method</h4>
                <RadioGroup
                  defaultValue={0}
                  onChange={onPaymentMethodChange}
                  value={selectedPaymentIndex}
                >
                  {addMarkerPaymentSettings.map((ps, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 28,
                              },
                            }}
                          />
                        }
                        label={`${ps.transferAmount} ${ps.token.symbol}`}
                        sx={{ marginTop: index === 0 ? "-8px" : "" }}
                      />
                    );
                  })}
                </RadioGroup>
              </>
            ) : null}

            {showConfirmTransaction ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Please confirm transaction on your wallet
              </DialogContentText>
            ) : uploadingImageToIPFS ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Uploading image to IPFS...
              </DialogContentText>
            ) : processing ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Processing...
              </DialogContentText>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            onClick={cancelAddMarker}
            sx={{ padding: isVerySmall ? "4px 8px" : "" }}
            disabled={processing}
          >
            Cancel
          </Button>
          {paymentMethod ? (
            <Button
              variant="outlined"
              color={
                insufficientBalance
                  ? "error"
                  : paymentApproved
                  ? "success"
                  : "primary"
              }
              onClick={approvePaymentMethod}
              disabled={
                checkingPaymentApproval ||
                approvingPayment ||
                insufficientBalance ||
                paymentApproved
              }
              endIcon={
                checkingPaymentApproval || approvingPayment ? (
                  <ProcessingIcon />
                ) : paymentApproved && !insufficientBalance ? (
                  <DoneIcon />
                ) : null
              }
              sx={{ padding: isVerySmall ? "4px 8px" : "" }}
            >
              {checkingPaymentApproval
                ? "Checking"
                : approvingPayment
                ? "Processing"
                : insufficientBalance
                ? "Insufficient Balance"
                : paymentApproved
                ? "Approved"
                : "Approve Payment"}
            </Button>
          ) : null}

          <Button
            variant="outlined"
            disabled={
              fetchingData ||
              checkingPaymentApproval ||
              (paymentMethod && !paymentApproved) ||
              insufficientBalance ||
              processing
            }
            endIcon={
              !approvingPayment && processing ? <ProcessingIcon /> : null
            }
            onClick={submitAddMarker}
            sx={{ padding: isVerySmall ? "4px 8px" : "" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showDeleteConfirmDialog} disableScrollLock>
        <DialogTitle sx={{ textAlign: "center" }}>Confirm Delete</DialogTitle>
        <DialogContent>
          <Stack alignItems="center">
            {markerToDelete ? (
              <img
                src={`https://gateway.moralisipfs.com/ipfs/${markerToDelete}`}
                alt="Delete Marker"
                style={{ marginTop: "16px", maxWidth: "256px" }}
              />
            ) : null}

            <DialogContentText sx={{ marginTop: "32px" }}>
              Are you sure you want to delete this marker?
            </DialogContentText>
            {showConfirmTransaction ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Please confirm transaction on your wallet
              </DialogContentText>
            ) : processing ? (
              <DialogContentText
                sx={{ textAlign: "center", marginTop: "32px" }}
              >
                Processing...
              </DialogContentText>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            variant="outlined"
            onClick={onCancelDeleteMarker}
            disabled={showConfirmTransaction}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            onClick={deleteMarker}
            disabled={showConfirmTransaction}
            endIcon={showConfirmTransaction ? <ProcessingIcon /> : null}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessDialog
        showDialog={showSuccessDialog}
        message={successMessage}
        onCloseDialog={onSuccessDialogClose}
      />
    </>
  );
}
