import { DEFAULT_MARKER_IMAGE_DATA } from "components/markerEditor/defaultImages";
import {
  AFTER_LOGOUT,
  CHANGE_MAP_MODE,
  CHANGE_WALLET_CONNECTED,
  SET_CURRENT_MARKER_IMAGE,
  SET_MARKER_SET,
  SET_PAYMENT_APPROVED,
  UPDATE_CURRENT_MARKER_INFO,
} from "../actions/actionPiny";

const initialState = {
  pinModeActive: false,
  walletConnected: undefined,
  isMarkerSet: false,
  paymentApproved: false,
  currentMarkerImage: DEFAULT_MARKER_IMAGE_DATA,
  currentMarkerInfo: {
    markerPosition: null,
    mapZoom: 3,
    markerName: "",
    markerMessage: "",
  },
};

const initializeState = (state = initialState, action) => {
  // console.log("In Piny reducer | state: " + JSON.stringify(state) + " - action: " + JSON.stringify(action));

  switch (action.type) {
    case CHANGE_MAP_MODE:
      if (action.pinModeActiveNEW) {
        return {
          ...state,
          pinModeActive: action.pinModeActiveNEW,
        };
      } else {
        return {
          ...state,
          pinModeActive: action.pinModeActiveNEW,
          isMarkerSet: false,
        };
      }

    case CHANGE_WALLET_CONNECTED:
      return {
        ...state,
        walletConnected: action.walletConnectedNEW,
      };

    case AFTER_LOGOUT:
      return {
        ...state,
        pinModeActive: false,
        walletConnected: undefined,
        isMarkerSet: false,
        currentMarkerImage: DEFAULT_MARKER_IMAGE_DATA,
        currentMarkerInfo: {
          markerPosition: null,
          mapZoom: 3,
          markerName: "",
          markerMessage: "",
        },
      };

    case SET_MARKER_SET:
      return {
        ...state,
        isMarkerSet: action.isMarkerSetNEW,
      };

    case SET_PAYMENT_APPROVED:
      return {
        ...state,
        paymentApproved: action.paymentApprovedNEW,
      };

    case SET_CURRENT_MARKER_IMAGE:
      return {
        ...state,
        currentMarkerImage: action.currentMarkerImageNEW,
      };

    case UPDATE_CURRENT_MARKER_INFO: {
      let updatedMarkerInfo = { ...state.currentMarkerInfo };
      let updatedIsMarkerSet = state.isMarkerSet;

      if (action.positionNEW) {
        updatedMarkerInfo.markerPosition = action.positionNEW;
        updatedIsMarkerSet = true;
      }
      if (action.zoomNEW) {
        updatedMarkerInfo.mapZoom = action.zoomNEW;
      }
      if (action.nameNEW) {
        updatedMarkerInfo.markerName = action.nameNEW;
      }
      if (action.messageNEW) {
        updatedMarkerInfo.markerMessage = action.messageNEW;
      }

      let newState = {
        ...state,
        isMarkerSet: updatedIsMarkerSet,
        currentMarkerInfo: updatedMarkerInfo,
      };

      // console.log("Action UPDATE_CURRENT_MARKER_INFO: " + JSON.stringify(newState));
      return newState;
    }
    default:
      return null;
  }
};

export default initializeState;
