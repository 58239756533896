import React, { useEffect, useState } from "react";
import {
  LayersControl,
  MapContainer,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { useLeafletContext } from "@react-leaflet/core";
import { Typography } from "@mui/material";
import { PINYWORLD_API_END_POINT } from "constant";
import axios from "axios";

export default function LeafletMap({
  userLocation,
  zoom,
  center,
  whenReady,
  onClick,
  onDragEnd,
  onZoomEnd,
  style,
  children,
}) {
  const MapEventHandlers = () => {
    const context = useLeafletContext();

    useMapEvents({
      click(event) {
        if (onClick) {
          onClick(event);
        }
      },
      dragend() {
        if (onDragEnd) {
          onDragEnd(context.map);
        }
      },
      zoomend() {
        if (onZoomEnd) {
          onZoomEnd(context.map);
        }
      },
    });

    return null;
  };

  const [mapCenter, setMapCenter] = useState();

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const userLocationResponse = await axios.get(
          PINYWORLD_API_END_POINT + "location/user-location"
        );

        setMapCenter({
          lat: parseFloat(userLocationResponse.data.latitude),
          lng: parseFloat(userLocationResponse.data.longitude),
        });
      } catch (err) {
        setMapCenter({ lat: 15.0, lng: 10.0 });
      }
    };

    if (userLocation) {
      fetchUserLocation();
      return;
    }

    if (center) {
      setMapCenter(center);
    } else {
      setMapCenter({ lat: 15.0, lng: 10.0 });
    }
  }, [userLocation, center]);

  if (!mapCenter) {
    return (
      <div
        style={{
          width: "100%",
          height: `${window.innerWidth * (9 / 16)}px`,
          maxHeight: "600px",
          minHeight: "480px",
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#e5e3df",
        }}
      >
        <Typography variant="h5" style={{ opacity: "0.7" }}>
          LOADING...
        </Typography>
      </div>
    );
  }

  return (
    <MapContainer
      center={mapCenter}
      zoom={zoom || zoom === 0 ? zoom : 4}
      scrollWheelZoom
      whenReady={whenReady ? (event) => whenReady(event.target) : undefined}
      style={{ zIndex: "1", width: "100%", ...style }}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer name="OpenStreetMap" checked>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="ESRI Satellite">
          <TileLayer
            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
        </LayersControl.BaseLayer>
      </LayersControl>
      {children}
      <MapEventHandlers />
    </MapContainer>
  );
}
