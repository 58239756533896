import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import LocationOffOutlinedIcon from "@mui/icons-material/LocationOffOutlined";
import {
  Box,
  Container,
  IconButton,
  Stack,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import ConnectWalletButton from "components/common/connectWalletButton";
import PageLoadingSpinner from "components/common/pageLoadingSpinner";
import { PINYWORLD_API_END_POINT } from "constant";
import {
  getChainIdByNetwork,
  getNetworkLogo,
  networkConfigs,
} from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";

export default function MyEventsPage() {
  const {
    isWeb3Enabled,
    isWeb3EnableLoading,
    isAuthenticated,
    account,
    isInitialized,
  } = useMoralis();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const history = useHistory();

  const [fetchingData, setFetchingData] = useState(true);
  const [fetchingPageData, setFetchingPageData] = useState(true);
  const [events, setEvents] = useState(false);
  const [eventsTotal, setEventsTotal] = useState(0);
  const [eventsPage, setEventsPage] = useState(0);

  const fetchEvents = useCallback(async () => {
    if (!isInitialized || (isWeb3EnableLoading && isAuthenticated)) {
      setFetchingData(true);
      return;
    }

    if (!isWeb3Enabled && !account && !isAuthenticated) {
      setEvents(null);
      setFetchingData(false);
      return;
    }

    if (!isWeb3Enabled || !account || !isAuthenticated) {
      return;
    }

    setFetchingData(true);

    try {
      let response = await axios.post(
        PINYWORLD_API_END_POINT + "event/query/criteria/count",
        {
          criteria: {
            accountList: [account],
            sortRequestList: [
              { sortField: "createTime", sortDirection: "DESC" },
            ],
          },
        }
      );

      setEventsTotal(response.data);

      response = await axios.post(
        PINYWORLD_API_END_POINT + "event/query/criteria",
        {
          criteria: {
            accountList: [account],
            markerSliceSize: 3,
            pageSize: 10,
            pageNumber: 0,
            sortRequestList: [
              { sortField: "createTime", sortDirection: "DESC" },
            ],
          },
        }
      );

      setEvents(response.data);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
    } finally {
      setFetchingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialized,
    isWeb3EnableLoading,
    isWeb3Enabled,
    isAuthenticated,
    account,
  ]);

  const fetchEventsPage = useCallback(async () => {
    if (!account) {
      setEvents(null);
      setFetchingPageData(false);
      return;
    }

    setFetchingPageData(true);

    try {
      const response = await axios.post(
        PINYWORLD_API_END_POINT + "event/query/criteria",
        {
          criteria: {
            accountList: [account],
            pageSize: 10,
            pageNumber: eventsPage,
            markerSliceSize: 3,
            sortRequestList: [
              { sortField: "createTime", sortDirection: "DESC" },
            ],
          },
        }
      );

      setEvents(response.data);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
    } finally {
      setFetchingPageData(false);
    }
  }, [account, eventsPage]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    fetchEventsPage();
  }, [fetchEventsPage]);

  const onEventsPageChange = (event, newPage) => {
    setEventsPage(newPage);
  };

  return (
    <Container
      maxWidth="md"
      style={{ backgroundColor: "white", paddingBottom: "48px" }}
    >
      <Stack>
        <h1>My Events</h1>

        {fetchingData ? (
          <PageLoadingSpinner />
        ) : !isAuthenticated || !account ? (
          <Box
            sx={{
              height: "200px",
              border: "1px solid",
              borderColor: "divider",
              borderRadius: "8px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack alignItems="center">
              <Typography>You must login to see your events.</Typography>

              <Box sx={{ marginTop: "32px" }}>
                <ConnectWalletButton />
              </Box>
            </Stack>
          </Box>
        ) : (
          <Stack sx={{ rowGap: "16px", marginTop: "16px", overflow: "hidden" }}>
            {!events || !events.length || fetchingPageData ? (
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "calc(100% - 34px)",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: "8px",
                  padding: "16px",
                  height: "130px",
                }}
              >
                {!fetchingPageData ? (
                  <Typography>You don't have any events</Typography>
                ) : (
                  <Typography>Loading...</Typography>
                )}
              </Stack>
            ) : (
              events.map((event) => {
                return (
                  <Stack
                    flexDirection="row"
                    sx={{
                      width: "calc(100% - 34px)",
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "8px",
                      padding: "16px",
                    }}
                    key={event.id}
                  >
                    <Stack
                      flexDirection={isSmall ? "column" : "row"}
                      alignItems={isSmall ? "" : "center"}
                      sx={{ width: "100%" }}
                    >
                      <Stack sx={{ minWidth: isSmall ? "100%" : "500px" }}>
                        <Typography
                          variant="h5"
                          component="div"
                          textAlign={isSmall ? "center" : ""}
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {event.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            marginTop: "16px",
                            minHeight: "48px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-all",
                            display: "-webkit-box",
                            "-webkit-line-clamp": "2",
                            "-webkit-box-orient": "vertical",
                          }}
                        >
                          {event.description}
                        </Typography>
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          sx={{ marginTop: "16px" }}
                        >
                          <img
                            src={getNetworkLogo(event.network)}
                            alt={event.network}
                            style={{
                              maxWidth: "24px",
                              maxHeight: "24px",
                              borderRadius: "50%",
                            }}
                            title={event.network}
                          />

                          <Stack direction="row" alignItems="center" ml="8px">
                            <CalendarMonthIcon
                              sx={{
                                color: "gray",
                                width: "18px",
                                height: "18px",
                                marginTop: "-2px",
                              }}
                            />
                            <Typography
                              variant="subtitle2"
                              sx={{ marginLeft: "2px", opacity: "0.65" }}
                            >
                              {new Date(event.createTime).toLocaleDateString()}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack
                        flexDirection="row"
                        alignItems="center"
                        sx={{
                          marginTop: isSmall ? "16px" : "",
                          marginLeft: isSmall ? "0px" : "16px",
                          minWidth: "100%",
                        }}
                      >
                        <Stack
                          sx={{
                            width: "100%",
                            maxWidth: isSmall ? "100%" : "264px",
                          }}
                        >
                          <Stack
                            flexDirection="row"
                            width="100%"
                            alignItems="center"
                          >
                            {!event.enabled ? (
                              <LocationOffOutlinedIcon
                                sx={{
                                  color: "gray",
                                  width: "18px",
                                  height: "18px",
                                  marginTop: "-2px",
                                }}
                              />
                            ) : null}

                            {event.customMarkerEnabled ? (
                              <EditLocationOutlinedIcon
                                sx={{
                                  color: "gray",
                                  width: "18px",
                                  height: "18px",
                                  marginTop: "-2px",
                                  marginLeft: !event.enabled ? "16px" : "0px",
                                }}
                              />
                            ) : null}

                            <PinDropOutlinedIcon
                              sx={{
                                color: "gray",
                                width: "18px",
                                height: "18px",
                                marginTop: "-2px",
                                marginLeft:
                                  event.customMarkerEnabled || !event.enabled
                                    ? "16px"
                                    : "0px",
                              }}
                            />

                            <Typography
                              variant="subtitle2"
                              sx={{ marginLeft: "2px", opacity: "0.65" }}
                            >
                              {event.currentMintCount}
                            </Typography>
                          </Stack>

                          <Box
                            sx={{
                              display: "flex",
                              minHeight: "40px",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                            title={
                              event.markerImageList &&
                              event.markerImageList.length
                                ? event.markerImageList.length === 1
                                  ? "1 marker"
                                  : `${event.markerImageList.length} markers`
                                : null
                            }
                          >
                            {event.markerImageList &&
                            event.markerImageList.length ? (
                              event.markerImageList.map((markerImage, i) => {
                                return (
                                  <Stack
                                    key={markerImage.urlPath}
                                    sx={{
                                      border: "1px solid",
                                      borderColor: "divider",
                                      borderRadius: "4px",
                                      marginLeft: i > 0 ? "4px" : "0px",
                                      padding: "2px",
                                      width: "40px",
                                      height: "40px",
                                    }}
                                  >
                                    <img
                                      src={`https://gateway.moralisipfs.com/ipfs/${markerImage.urlPath}`}
                                      alt={markerImage.urlPath}
                                      key={markerImage.urlPath}
                                      style={{
                                        width: "100%",
                                        backgroundColor: "white",
                                      }}
                                    />
                                  </Stack>
                                );
                              })
                            ) : (
                              <Typography
                                variant="subtitle2"
                                sx={{ opacity: "0.65" }}
                              >
                                No marker added
                              </Typography>
                            )}
                          </Box>

                          <Stack
                            flexDirection=" row"
                            alignItems="center"
                            mt="8px"
                          >
                            <IconButton
                              onClick={() =>
                                history.push(
                                  `/update/event/${
                                    networkConfigs[
                                      getChainIdByNetwork(event.network)
                                    ].code
                                  }/${event.eventId}`
                                )
                              }
                            >
                              <ModeEditOutlinedIcon />
                            </IconButton>
                          </Stack>
                        </Stack>
                        <IconButton
                          onClick={() =>
                            history.push(
                              `/event/${
                                networkConfigs[
                                  getChainIdByNetwork(event.network)
                                ].code
                              }/${event.eventId}`
                            )
                          }
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Stack>
                );
              })
            )}
            <TablePagination
              component="div"
              count={eventsTotal}
              rowsPerPage={10}
              page={eventsPage}
              onPageChange={onEventsPageChange}
              rowsPerPageOptions={[10]}
            />
          </Stack>
        )}
      </Stack>
    </Container>
  );
}
