import AvaxLogo from "assets/img/avalanche.png";
import BscLogo from "assets/img/bsc.png";
import PolygonLogo from "assets/img/polygon.png";
import EthLogo from "assets/img/eth.png";

export const networkConfigs = {
  "0x1": {
    currencySymbol: "ETH",
    blockExplorerUrl: "https://etherscan.io/",
    wrapped: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    code: "eth",
  },
  "0x3": {
    currencySymbol: "ETH",
    blockExplorerUrl: "https://ropsten.etherscan.io/",
    code: "eth",
  },
  "0x4": {
    currencySymbol: "ETH",
    blockExplorerUrl: "https://kovan.etherscan.io/",
    code: "eth",
  },
  "0x2a": {
    currencySymbol: "ETH",
    blockExplorerUrl: "https://rinkeby.etherscan.io/",
    code: "eth",
  },
  "0x5": {
    currencySymbol: "ETH",
    blockExplorerUrl: "https://goerli.etherscan.io/",
    rpcUrl: "https://ethereum-goerli.publicnode.com",
    code: "eth",
  },
  "0x539": {
    chainName: "Local Chain",
    currencyName: "ETH",
    currencySymbol: "ETH",
    rpcUrl: "http://127.0.0.1:7545",
    code: "eth",
  },
  "0xa86a": {
    chainId: 43114,
    chainName: "Avalanche Mainnet",
    currencyName: "AVAX",
    currencySymbol: "AVAX",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    blockExplorerUrl: "https://cchain.explorer.avax.network/",
    code: "avax",
  },
  "0xa869": {
    chainName: "Avalanche Testnet",
    currencyName: "AVAX",
    currencySymbol: "AVAX",
    code: "avax",
  },
  "0x38": {
    chainId: 56,
    chainName: "Smart Chain",
    currencyName: "BNB",
    currencySymbol: "BNB",
    rpcUrl: "https://bsc-dataseed.binance.org/",
    blockExplorerUrl: "https://bscscan.com/",
    wrapped: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    code: "bsc",
  },
  "0x61": {
    chainId: 97,
    chainName: "Smart Chain - Testnet",
    currencyName: "BNB",
    currencySymbol: "BNB",
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    blockExplorerUrl: "https://testnet.bscscan.com/",
    code: "bsc",
  },
  "0x89": {
    chainId: 137,
    chainName: "Polygon Mainnet",
    currencyName: "MATIC",
    currencySymbol: "MATIC",
    rpcUrl: "https://rpc-mainnet.maticvigil.com/",
    blockExplorerUrl: "https://explorer-mainnet.maticvigil.com/",
    wrapped: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    code: "polygon",
  },
  "0x13881": {
    chainId: 80001,
    chainName: "Mumbai",
    currencyName: "MATIC",
    currencySymbol: "MATIC",
    rpcUrl: "https://rpc-mumbai.maticvigil.com",
    blockExplorerUrl: "https://mumbai.polygonscan.com/",
    code: "polygon",
  },
  "0xa4b1": {
    chainId: 42161,
    chainName: "Arbitrum Mainnet",
    currencyName: "ETH",
    currencySymbol: "ETH",
    rpcUrl: "https://arbitrum-mainnet.infura.io",
    blockExplorerUrl: "https://explorer.arbitrum.io",
    code: "arbitrum",
  },
  "0x66eed": {
    chainId: 421613,
    chainName: "Arbitrum Goerli",
    currencyName: "ETH",
    currencySymbol: "ETH",
    rpcUrl: "https://goerli-rollup.arbitrum.io/rpc",
    blockExplorerUrl: "https://goerli.arbiscan.io/",
    code: "arbitrum",
  },
};

export const getNativeByChain = (chain) =>
  networkConfigs[chain]?.currencySymbol || "NATIVE";

export const getChainById = (chain) => networkConfigs[chain]?.chainId || null;

export const getExplorer = (chain) => networkConfigs[chain]?.blockExplorerUrl;

export const getWrappedNative = (chain) =>
  networkConfigs[chain]?.wrapped || null;

export const getChainIdByNetwork = (network) => {
  if (process.env.REACT_APP_WORK_WITH_TESTNET) {
    switch (network) {
      case "BSC":
      case "bsc":
        return "0x61";
      case "ETH":
      case "eth":
        return "0x5";
      case "Avax":
      case "avax":
        return "0xa869";
      case "Polygon":
      case "polygon":
        return "0x13881";
      default:
        return null;
    }
  }
};

export const getScannerTxUrlByNetworkCode = (network, txId) => {
  if (process.env.REACT_APP_WORK_WITH_TESTNET) {
    switch (network) {
      case "BSC":
      case "bsc":
        return `https://testnet.bscscan.com/tx/${txId}`;
      case "ETH":
      case "eth":
        return `https://goerli.etherscan.io/tx/${txId}`;
      case "Avax":
      case "avax":
        return `https://testnet.avascan.info/blockchain/c/tx/${txId}`;
      case "Polygon":
      case "polygon":
        return `https://mumbai.polygonscan.com/tx/${txId}`;
      default:
        return null;
    }
  }
};

export const getNetworkLogo = (chainId) => {
  if (
    chainId === "0xa869" ||
    chainId === "0xa86a" ||
    chainId === "Avax" ||
    chainId === "avax"
  ) {
    return AvaxLogo;
  }

  if (
    chainId === "0x61" ||
    chainId === "0x38" ||
    chainId.toLowerCase() === "bsc"
  ) {
    return BscLogo;
  }

  if (
    chainId === "0x13881" ||
    chainId === "0x89" ||
    chainId.toLowerCase() === "polygon"
  ) {
    return PolygonLogo;
  }

  if (
    chainId === "0x5" ||
    chainId === "0x1" ||
    chainId.toLowerCase() === "eth"
  ) {
    return EthLogo;
  }
};

export const getNetworkDatabaseNameByCode = (networkCode) => {
  if (!networkCode || !networkCode.toUpperCase) {
    return "";
  }

  if (
    networkCode.toUpperCase() === "AVAX" ||
    networkCode === "0xa86a" ||
    networkCode === "0xa869"
  ) {
    return "Avax";
  }

  if (
    networkCode.toUpperCase() === "BSC" ||
    networkCode === "0x61" ||
    networkCode === "0x38"
  ) {
    return "BSC";
  }

  if (
    networkCode.toUpperCase() === "POLYGON" ||
    networkCode === "0x13881" ||
    networkCode === "0x89"
  ) {
    return "Polygon";
  }

  if (
    networkCode.toUpperCase() === "ETH" ||
    networkCode === "0x1" ||
    networkCode === "0x5"
  ) {
    return "ETH";
  }

  return "";
};
