import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import ConnectWalletButton from "components/common/connectWalletButton";
import InvalidNetworkDialog from "components/common/invalidNetworkDialog";
import PageLoadingSpinner from "components/common/pageLoadingSpinner";
import { PINYWORLD_API_END_POINT } from "constant";
import { deleteEventFromLocalStorage } from "helpers/localStorage";
import { getEventFromLocalStorage } from "helpers/localStorage";
import {
  getChainIdByNetwork,
  getNetworkDatabaseNameByCode,
} from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { useCallback, useEffect, useState } from "react";
import { getChain, useMoralis } from "react-moralis";
import { Link, useParams } from "react-router-dom";
import UpdateEventGeneralInfo from "./generalInfo";
import UpdateEventMarkers from "./markers";
import UpdateEventPaymentSettings from "./paymentSettings";

export default function UpdateEventPage() {
  const { isAuthenticated, account } = useMoralis();

  const { networkId, eventId } = useParams();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [dataReady, setDataReady] = useState(false);
  const [eventData, setEventData] = useState();
  const [eventNotFound, setEventNotFound] = useState(false);

  const [fetchedOnchainData, setFetchedOnchainData] = useState(false);
  const [paymentSettings, setPaymentSettings] = useState();
  const [markers, setMarkers] = useState();
  const [addMarkerPaymentSettings, setAddMarkerPaymentSettings] = useState();

  const [showUnautheticatedDialog, setShowUnautheticatedDialog] =
    useState(false);
  const [showInvalidAccountDialog, setShowInvalidAccountDialog] =
    useState(false);

  const fetchEventData = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }

    try {
      const response = await axios.get(
        PINYWORLD_API_END_POINT +
          "event/" +
          getNetworkDatabaseNameByCode(networkId) +
          "/" +
          eventId
      );

      let fetchedEventData = response.data.dto;

      if (!fetchedEventData) {
        fetchedEventData = getEventFromLocalStorage(networkId, eventId);
      } else if (getEventFromLocalStorage(networkId, eventId)) {
        deleteEventFromLocalStorage(networkId, eventId);
      }

      if (fetchedEventData) {
        setEventData(fetchedEventData);
        setDataReady(true);
      } else {
        setEventNotFound(true);
        setDataReady(false);
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
    }
  }, [isAuthenticated, networkId, eventId]);

  useEffect(() => {
    fetchEventData();
  }, [fetchEventData]);

  const onSelectedTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setShowUnautheticatedDialog(true);
    } else {
      setShowUnautheticatedDialog(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && account && eventData) {
      if (eventData.owner !== account) {
        setShowInvalidAccountDialog(true);
      } else {
        setShowInvalidAccountDialog(false);
      }
    } else {
      setShowInvalidAccountDialog(false);
    }
  }, [isAuthenticated, account, eventData]);

  return (
    <Container
      maxWidth="md"
      sx={{ backgroundColor: "white", paddingBottom: "48px" }}
    >
      <Stack>
        <h1>Update Event</h1>

        {!dataReady || showUnautheticatedDialog || showInvalidAccountDialog ? (
          <PageLoadingSpinner />
        ) : (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={selectedTabIndex} onChange={onSelectedTabChange}>
                <Tab label="General" />
                <Tab label="Payment" />
                <Tab label="Markers" />
              </Tabs>
            </Box>
            {selectedTabIndex === 0 ? (
              <UpdateEventGeneralInfo
                eventData={eventData}
                fetchedOnchainData={fetchedOnchainData}
                fetchedOnchainDataSetter={setFetchedOnchainData}
              />
            ) : selectedTabIndex === 1 ? (
              <UpdateEventPaymentSettings
                eventData={eventData}
                paymentSettings={paymentSettings}
                paymentSettingsSetter={setPaymentSettings}
              />
            ) : selectedTabIndex === 2 ? (
              <UpdateEventMarkers
                eventData={eventData}
                markers={markers}
                markersSetter={setMarkers}
                addMarkerPaymentSettings={addMarkerPaymentSettings}
                addMarkerPaymentSettingsSetter={setAddMarkerPaymentSettings}
              />
            ) : null}
          </Box>
        )}
      </Stack>
      <Dialog open={showUnautheticatedDialog} disableScrollLock>
        <DialogTitle sx={{ textAlign: "center" }}>
          Unauthenticated Access
        </DialogTitle>
        <DialogContent>
          <Stack alignItems="center">
            <DangerousOutlinedIcon
              color="error"
              sx={{ width: "80px", height: "80px", marginTop: "16px" }}
            />
            <DialogContentText sx={{ textAlign: "center", marginTop: "32px" }}>
              Please connect your wallet to access this page
            </DialogContentText>
            <Box sx={{ marginTop: "40px" }}>
              <ConnectWalletButton />
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog open={showInvalidAccountDialog} disableScrollLock>
        <DialogTitle sx={{ textAlign: "center" }}>Invalid Account</DialogTitle>
        <DialogContent>
          <Stack alignItems="center">
            <DangerousOutlinedIcon
              color="error"
              sx={{ width: "80px", height: "80px", marginTop: "16px" }}
            />
            <DialogContentText sx={{ textAlign: "center", marginTop: "32px" }}>
              You aren't the owner of this event
            </DialogContentText>

            <Link to="/" className="buttonLink" style={{ marginTop: "32px" }}>
              <Button variant="outlined" onClick={() => {}}>
                Go to Homepage
              </Button>
            </Link>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog open={eventNotFound} disableScrollLock>
        <DialogTitle sx={{ textAlign: "center" }}>Event Not Found</DialogTitle>
        <DialogContent>
          <Stack>
            <Stack
              flexDirection="row"
              justifyContent="center"
              marginTop="16px"
              marginBottom="32px"
            >
              <WarningAmberOutlinedIcon
                color="warning"
                sx={{ width: "80px", height: "80px" }}
              />
            </Stack>

            <DialogContentText>
              Network: {getChain(getChainIdByNetwork(networkId)).name}
            </DialogContentText>

            <DialogContentText sx={{ marginTop: "8px" }}>
              Event Id: {eventId}
            </DialogContentText>
          </Stack>
        </DialogContent>
      </Dialog>
      {eventData && eventData.network ? (
        <InvalidNetworkDialog
          validChainId={getChainIdByNetwork(eventData.network)}
          cancelLink="/event"
        />
      ) : null}
    </Container>
  );
}
