import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
   
/**
 * 
 * @param {*} title 
 * @param {*} severity valuable to info,success,warning,error  lowercase or uppercase
 * @param {*} message 
 */
function toastUtil (severity , message)  {
    toast.configure(toastProperties);
    switch(severity) {
        case 'info':
            toast.info(message);
            break;
        case 'warning':
            toast.warning(message);
            break;
        case 'error':
            toast.error(message);
            break;
        default:
            toast.success(message);
    }
}

function closeToasts() {
    toast.dismiss();
}

const toastProperties = {
    position: "top-right",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
};
const ToastUtils = {
    toastUtil,
    closeToasts
};
export default ToastUtils;