import { Popover } from "@mui/material";
import "emoji-mart/css/emoji-mart.css";
import data from "emoji-mart/data/apple.json";
import { NimblePicker } from "emoji-mart";

export default function EmojiPicker({ anchorEl, anchorElSetter, onSelect }) {
  const open = Boolean(anchorEl);

  return (
    <div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => anchorElSetter(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <NimblePicker
          autoFocus={true}
          color="hsl(0,0%,20%)"
          theme="light"
          sheetSize={16}
          showPreview={false}
          title=" "
          emoji=" "
          native={true}
          set="apple"
          data={data}
          onSelect={(emoji) => onSelect(emoji.native)}
        />
      </Popover>
    </div>
  );
}
