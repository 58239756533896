import { Stack, Typography } from "@mui/material";
import { getChainIdByNetwork } from "helpers/networks";
import { getNetworkLogo } from "helpers/networks";
import { getChain } from "react-moralis";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";

export default function MarkerImage({ markerData }) {
  const markerChain = getChainIdByNetwork(markerData.network);

  return (
    <Stack
      sx={{
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "divider",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding="8px"
        sx={{
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Stack direction="row" alignItems="center">
          <img
            src={getNetworkLogo(markerChain)}
            alt={getChain(markerChain).name}
            style={{
              maxWidth: "24px",
              maxHeight: "24px",
              borderRadius: "50%",
            }}
          />
          <Typography variant="body2" sx={{ marginLeft: "4px" }}>
            {getChain(markerChain).name}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <PinDropOutlinedIcon />
          <Typography variant="body2" sx={{ marginLeft: "4px" }}>
            {new Date(markerData.createTime).toLocaleDateString()}
          </Typography>
        </Stack>
      </Stack>
      <Stack sx={{ padding: "8px", height: "100%" }} justifyContent="center">
        <img
          src={
            markerData.imageData
              ? "data:image/png;base64," + markerData.imageData
              : `https://gateway.moralisipfs.com/ipfs/${markerData.imageUrl}`
          }
          alt="Marker"
          style={{ maxWidth: "336px", maxHeight: "336px" }}
        />
      </Stack>
    </Stack>
  );
}
