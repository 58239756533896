import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { MoralisProvider } from "react-moralis";
import { combineReducers, createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { Provider } from "react-redux";
import reducerPiny from "state/reducers/reducerPiny";

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

const rootReducer = combineReducers({
  piny: reducerPiny,
});
const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

const Application = () => {
  const isServerInfo = APP_ID && SERVER_URL ? true : false;
  //Validate
  if (!APP_ID || !SERVER_URL)
    throw new Error(
      "Missing Moralis Application ID and/or Server URL. Make sure to set your .env file."
    );

  if (isServerInfo)
    return (
      <Provider store={store}>
        <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
          <App isServerInfo />
        </MoralisProvider>
      </Provider>
    );
  else {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ display: "flex", gap: "10px" }}>
          <div>This is Pinyworld :)</div>
        </div>
      </div>
    );
  }
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Application />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
