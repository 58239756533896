import { Box, Popover } from "@mui/material";
import { useState } from "react";
import { SketchPicker } from "react-color";

export default function ColorPicker({ color, onChange }) {
  const [anchorEl, setAnchorEl] = useState();

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (color) => {
    const valueType = "string";

    let newColor;
    if (valueType === "string") {
      newColor = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
    } else {
      newColor = color.rgb;
    }

    onChange(newColor);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Box
        sx={{
          backgroundColor: color,
          height: "24px",
          width: "48px",
          border: "1px solid",
          borderColor: "divider",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SketchPicker color={color} onChange={handleChange} />
      </Popover>
    </div>
  );
}
