import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import MarkerImage from "components/marker/image";
import { PINYWORLD_API_END_POINT } from "constant";
import { getScannerERC721UrlByNetworkCode } from "constant/ContractAddress";
import { getEllipsisTxt, tokenValue } from "helpers/formatters";
import {
  deleteMarkerFromLocalStorage,
  getMarkerFromLocalStorage,
} from "helpers/localStorage";
import {
  getChainIdByNetwork,
  getNetworkDatabaseNameByCode,
  getScannerTxUrlByNetworkCode,
} from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { getTokenMetadata } from "helpers/tokens";
import { useCallback, useEffect, useState } from "react";
import { getChain, useMoralis } from "react-moralis";
import { Link, useParams } from "react-router-dom";
import MarkerActivity from "./activity";
import Listing from "./listing";
import { FacebookIcon, TwitterIcon } from "react-share";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import PageLoadingSpinner from "components/common/pageLoadingSpinner";
import ProcessingIcon from "components/common/processingIcon";
import { getJwtToken } from "helpers/localStorage";
import LeafletMap from "components/leafletMap";
import L from "leaflet";
import { Marker } from "react-leaflet";
import { SHARE_URL } from "constant";

export default function MarkerHomePage() {
  const { Moralis, account } = useMoralis();
  const { networkId, markerId } = useParams();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const markerChain = getChainIdByNetwork(networkId);

  const [mapZoom, setMapZoom] = useState(4);
  const [markerIconSize, setMarkerIconSize] = useState(64);
  const [fetchingData, setFetchingData] = useState(true);
  const [markerNotFound, setMarkerNotFound] = useState(false);
  const [markerData, setMarkerData] = useState();
  const [pinPayment, setPinPayment] = useState();
  const [eventPayment, setEventPayment] = useState();

  const [collectionList, setCollectionList] = useState();
  const [showCollectionInfo, setShowCollectionInfo] = useState();
  const [showCollectionEdit, setShowCollectionEdit] = useState();
  const [selectedCollection, setSelectedCollection] = useState("");
  const [savingCollectionInfo, setSavingCollectionInfo] = useState(false);

  const fetchMarkerData = useCallback(async () => {
    try {
      const response = await axios.get(
        PINYWORLD_API_END_POINT +
          "marker/" +
          getNetworkDatabaseNameByCode(networkId) +
          "/" +
          markerId
      );

      let fetchedMarkerData = response.data.dto;

      if (!fetchedMarkerData) {
        fetchedMarkerData = getMarkerFromLocalStorage(networkId, markerId);
      } else if (getMarkerFromLocalStorage(networkId, markerId)) {
        deleteMarkerFromLocalStorage(networkId, markerId);
      }

      if (fetchedMarkerData) {
        setMarkerData(fetchedMarkerData);
        setMapZoom(fetchedMarkerData.mapZoom);
      } else {
        setMarkerNotFound(true);
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
    } finally {
      setFetchingData(false);
    }
  }, [networkId, markerId]);

  const fetchExtraData = useCallback(async () => {
    if (!account || !markerData) {
      setPinPayment(null);
      setEventPayment(null);
      setCollectionList([]);
      return;
    }

    setCollectionList(
      (
        await axios.post(
          PINYWORLD_API_END_POINT + "collection/query/criteria",
          {
            criteria: {
              owner: account,
              sortRequestList: [
                { sortField: "createTime", sortDirection: "DESC" },
              ],
              fieldList: ["_id", "name"],
            },
          }
        )
      ).data
    );

    setSelectedCollection(markerData.collectionId || "");

    if (markerData.eventPaymentData && markerData.eventPaymentData.payment) {
      if (!markerData.eventPaymentData.payment.value) {
        setEventPayment("Free");
      } else {
        let tokenMetadata = await getTokenMetadata(
          Moralis,
          markerData.eventPaymentData.payment.metadata.address,
          markerChain
        );

        setEventPayment(
          tokenValue(
            markerData.eventPaymentData.payment.amount,
            tokenMetadata.decimals
          ) +
            " " +
            tokenMetadata.symbol
        );
      }
    }

    if (markerData.mintPaymentData && markerData.mintPaymentData.payment) {
      if (!markerData.mintPaymentData.payment.value) {
        setPinPayment("Free");
      } else {
        let tokenMetadata = await getTokenMetadata(
          Moralis,
          markerData.mintPaymentData.payment.metadata.address,
          markerChain
        );

        setPinPayment(
          tokenValue(
            markerData.mintPaymentData.payment.amount,
            tokenMetadata.decimals
          ) +
            " " +
            tokenMetadata.symbol
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, markerData]);

  useEffect(() => {
    fetchMarkerData();
  }, [fetchMarkerData]);

  useEffect(() => {
    fetchExtraData();
  }, [fetchExtraData]);

  useEffect(() => {
    if (!markerData) {
      setShowCollectionInfo(false);
      setShowCollectionEdit(false);
      return;
    }

    let tempShowInfo = false;
    let tempShowEdit = false;

    if (markerData.collectionId) {
      tempShowInfo = true;
    }

    if (
      markerData.id &&
      account &&
      ((!markerData.collectionId && markerData.owner === account) ||
        (markerData.owner === account &&
          markerData.collectionOwner === account))
    ) {
      tempShowInfo = true;
      tempShowEdit = true;
    }

    setShowCollectionInfo(tempShowInfo);
    setShowCollectionEdit(tempShowEdit);
  }, [account, markerData]);

  const onSelectedCollectionChange = (event) => {
    setSelectedCollection(event.target.value);
  };

  const saveCollectionInfo = async () => {
    try {
      setSavingCollectionInfo(true);

      const header = {
        Authorization: "Bearer " + getJwtToken(),
      };

      let response;

      if (markerData.collectionId && !selectedCollection) {
        response = await axios.delete(
          PINYWORLD_API_END_POINT +
            "collection/" +
            markerData.collectionId +
            "/marker/" +
            markerData.id +
            "/remove",
          { headers: header }
        );
      } else if (selectedCollection) {
        response = await axios.put(
          PINYWORLD_API_END_POINT +
            "collection/" +
            selectedCollection +
            "/marker/" +
            markerData.id +
            "/add",
          {},
          { headers: header }
        );
      } else {
        ToastUtils.toastUtil("warning", "Invalid request");
        return;
      }

      if (response.data.resultCode === "000000") {
        ToastUtils.toastUtil(
          "success",
          "Marker collection info is succesfully saved"
        );

        markerData.collectionId = selectedCollection;
      } else {
        ToastUtils.toastUtil("error", response.data.resultMessage);
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
    } finally {
      setSavingCollectionInfo(false);
    }
  };

  useEffect(() => {
    if (!mapZoom || mapZoom * 16 < 48) {
      setMarkerIconSize(48);
    } else if (mapZoom * 16 > 160) {
      setMarkerIconSize(160);
    } else {
      setMarkerIconSize(mapZoom * 16);
    }
  }, [mapZoom]);

  const renderMarkerInfo = () => {
    return (
      <Stack mt="24px" width="100%" alignItems="flex-start">
        <Stack flexDirection="row" alignItems="center">
          <Typography variant="subtitle2">NFT ID</Typography>
          <Typography variant="body2" sx={{ marginLeft: "8px" }}>
            #{markerData.tokenId}
          </Typography>
          <a
            href={getScannerERC721UrlByNetworkCode(
              markerData.network,
              markerData.tokenId
            )}
            target="_blank"
            rel="noreferrer"
            style={{ display: "flex" }}
          >
            <OpenInNewIcon
              sx={{ marginLeft: "4px", width: "18px", height: "18px" }}
            />
          </a>
        </Stack>

        <Stack flexDirection="row" mt="16px" alignItems="center">
          <Typography variant="subtitle2">Pinned by</Typography>

          <Typography variant="body2" sx={{ marginLeft: "4px" }}>
            {markerData.minter === account
              ? "you"
              : getEllipsisTxt(markerData.minter, 6)}
          </Typography>
        </Stack>

        {markerData.owner !== markerData.minter ? (
          <Stack flexDirection="row" mt="16px" alignItems="center">
            <Typography variant="subtitle2">Owner</Typography>

            <Typography variant="body2" sx={{ marginLeft: "4px" }}>
              {markerData.owner === account
                ? "you"
                : getEllipsisTxt(markerData.owner, 6)}
            </Typography>
          </Stack>
        ) : null}

        {markerData.eventId ? (
          <Stack flexDirection="row" mt="16px" alignItems="center">
            <Typography variant="subtitle2">Event</Typography>

            <Link
              to={`/event/${networkId}/${markerData.eventId}`}
              style={{ marginLeft: "8px" }}
            >
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: isSmall ? "216px" : "352px",
                }}
              >
                {markerData.eventName}
              </Typography>
            </Link>

            <Typography
              variant="body2"
              sx={{
                marginLeft: "8px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "72px",
              }}
            >
              #{markerData.eventTokenId}
            </Typography>
          </Stack>
        ) : null}

        {showCollectionInfo ? (
          <Stack flexDirection="row" mt="16px" alignItems="center">
            <Typography variant="subtitle2">Collection</Typography>
            {!showCollectionEdit ? (
              <Link
                to={`/collection/${markerData.collectionId}`}
                style={{ marginLeft: "8px" }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: isSmall ? "256px" : "400px",
                  }}
                >
                  {markerData.collectionName}
                </Typography>
              </Link>
            ) : (
              <Stack flexDirection="row">
                <Select
                  displayEmpty
                  value={selectedCollection}
                  onChange={onSelectedCollectionChange}
                  renderValue={
                    !collectionList || !collectionList.length
                      ? () => {
                          return (
                            <Typography
                              sx={{
                                color: "currentColor",
                                opacity: "0.42",
                              }}
                            >
                              You don't have any collection
                            </Typography>
                          );
                        }
                      : !selectedCollection
                      ? () => {
                          return (
                            <Typography
                              sx={{
                                color: "currentColor",
                                opacity: "0.42",
                              }}
                            >
                              Select a collection
                            </Typography>
                          );
                        }
                      : undefined
                  }
                  sx={{
                    marginLeft: "8px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: isSmall ? "176px" : "304px",
                  }}
                  size="small"
                >
                  {collectionList && collectionList.length ? (
                    <MenuItem value="">
                      <Typography
                        sx={{
                          color: "currentColor",
                          opacity: "0.42",
                        }}
                      >
                        None
                      </Typography>
                    </MenuItem>
                  ) : null}

                  {collectionList &&
                    collectionList.length &&
                    collectionList.map((collection) => {
                      return (
                        <MenuItem value={collection.id} key={collection.id}>
                          {collection.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                {(markerData.collectionId || "") !== selectedCollection ? (
                  <Button
                    onClick={saveCollectionInfo}
                    variant="outlined"
                    sx={{ marginLeft: "8px" }}
                    endIcon={savingCollectionInfo ? <ProcessingIcon /> : null}
                    disabled={savingCollectionInfo}
                  >
                    Save
                  </Button>
                ) : null}
              </Stack>
            )}
          </Stack>
        ) : null}

        <Stack flexDirection="row" mt="16px" alignItems="center">
          <Typography variant="subtitle2">Share Marker</Typography>
          <Typography variant="body2" marginLeft="8px">
            <TwitterShareButton
              url={`${SHARE_URL}/marker/${getNetworkDatabaseNameByCode(
                networkId
              )}/${markerId}`}
              hashtags={["piny", "marker"]}
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <FacebookShareButton
              url={`${SHARE_URL}/marker/${getNetworkDatabaseNameByCode(
                networkId
              )}/${markerId}`}
              hashtag={"#piny"}
              style={{ marginLeft: "8px" }}
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </Typography>
        </Stack>

        <Box mt="16px" width="100%">
          <Listing tokenId={markerId} markerChain={markerChain} />
        </Box>
      </Stack>
    );
  };

  return (
    <Container
      maxWidth="md"
      sx={{ backgroundColor: "white", paddingBottom: "64px" }}
    >
      {fetchingData || !markerData ? (
        <PageLoadingSpinner />
      ) : (
        <Stack flexDirection="column" sx={{ paddingTop: "32px" }}>
          {!isSmall ? (
            <Stack flexDirection="row">
              <MarkerImage markerData={markerData} />

              <Stack sx={{ marginLeft: "16px", width: "100%" }}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ wordBreak: "break-all" }}
                >
                  {(markerData && markerData.name) || "Unnamed Marker"}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    whiteSpace: "pre-line",
                    wordBreak: "break-all",
                    lineHeight: 2,
                  }}
                >
                  {(markerData && markerData.message) || "No message"}
                </Typography>

                {renderMarkerInfo()}
              </Stack>
            </Stack>
          ) : (
            <Stack flexDirection="column" alignItems="center">
              <Typography
                variant="h4"
                textAlign="center"
                gutterBottom
                sx={{ wordBreak: "break-all" }}
              >
                {(markerData && markerData.name) || "Unnamed Marker"}
              </Typography>
              <Typography
                variant="body1"
                textAlign="center"
                gutterBottom
                sx={{
                  whiteSpace: "pre-line",
                  wordBreak: "break-all",
                  lineHeight: 2,
                }}
              >
                {(markerData && markerData.message) || "No message"}
              </Typography>

              <Stack mt="16px">
                <MarkerImage markerData={markerData} />
              </Stack>

              {renderMarkerInfo()}
            </Stack>
          )}

          <Box sx={{ marginTop: "16px" }}>
            <LeafletMap
              center={{
                lat: markerData.coordinate.latitude,
                lng: markerData.coordinate.longitude,
              }}
              zoom={markerData.mapZoom}
              onZoomEnd={(map) => {
                setMapZoom(map.getZoom());
              }}
              style={{ borderRadius: "8px", height: "480px" }}
            >
              <Marker
                key="1"
                icon={
                  new L.Icon({
                    iconUrl: `https://gateway.moralisipfs.com/ipfs/${markerData.imageUrl}`,
                    iconSize: [markerIconSize, markerIconSize],
                    iconAnchor: [markerIconSize / 2, markerIconSize],
                  })
                }
                position={{
                  lat: markerData.coordinate.latitude,
                  lng: markerData.coordinate.longitude,
                }}
              />
            </LeafletMap>
          </Box>

          {account === markerData.minter ? (
            <Stack
              width="100%"
              border="1px solid"
              borderColor="divider"
              borderRadius="8px"
              mt="16px"
            >
              <Box padding="8px" borderBottom="1px solid" borderColor="divider">
                <Typography variant="h5">Pin Info</Typography>
              </Box>

              <Stack padding="8px">
                <Stack flexDirection="row" alignItems="center">
                  <Typography variant="subtitle2">Transaction ID</Typography>
                  <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                    {getEllipsisTxt(markerData.transactionHash, 8)}
                  </Typography>
                  <a
                    href={getScannerTxUrlByNetworkCode(
                      markerData.network,
                      markerData.transactionHash
                    )}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex" }}
                  >
                    <OpenInNewIcon
                      sx={{
                        marginLeft: "4px",
                        width: "18px",
                        height: "18px",
                      }}
                    />
                  </a>
                </Stack>

                <Stack flexDirection="row" mt="16px" alignItems="center">
                  <Typography variant="subtitle2">Pin Payment</Typography>
                  <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                    {pinPayment ? (
                      pinPayment
                    ) : (
                      <Skeleton
                        width={48}
                        height={24}
                        sx={{ marginLeft: "8px" }}
                      />
                    )}
                  </Typography>
                </Stack>
                {eventPayment ? (
                  <Stack flexDirection="row" mt="16px" alignItems="center">
                    <Typography variant="subtitle2">Event Payment</Typography>
                    <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                      {eventPayment ? (
                        eventPayment
                      ) : (
                        <Skeleton
                          width={48}
                          height={24}
                          sx={{ marginLeft: "8px" }}
                        />
                      )}
                    </Typography>
                  </Stack>
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
          ) : null}

          <MarkerActivity tokenId={markerId} network={networkId} />
        </Stack>
      )}

      <Dialog open={markerNotFound} disableScrollLock>
        <DialogTitle sx={{ textAlign: "center" }}>Marker Not Found</DialogTitle>
        <DialogContent>
          <Stack>
            <Stack
              flexDirection="row"
              justifyContent="center"
              marginTop="16px"
              marginBottom="32px"
            >
              <WarningAmberOutlinedIcon
                color="warning"
                sx={{ width: "80px", height: "80px" }}
              />
            </Stack>

            <DialogContentText>
              Network: {getChain(getChainIdByNetwork(networkId)).name}
            </DialogContentText>

            <DialogContentText sx={{ marginTop: "8px" }}>
              Marker Id: {markerId}
            </DialogContentText>
          </Stack>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
