export const addMarkerToLocalStorage = (network, id, marker) => {
  const markers = localStorage.getItem("markers");

  let markersObj;

  if (markers) {
    markersObj = JSON.parse(markers);
  } else {
    markersObj = {};
  }

  markersObj[network + "_" + id] = {
    network,
    tokenId: id,
    minter: marker.minter,
    owner: marker.minter,
    name: marker.name,
    message: marker.description,
    imageUrl: marker.imageUrl,
    imageData: marker.imageData,
    coordinate: {
      longitude: marker.attributes[1].value,
      latitude: marker.attributes[0].value,
    },
    mapZoom: marker.attributes[2].value,
    tokenURI: marker.tokenURI,
    eventId: marker.eventId,
    eventName: marker.eventName,
    eventTokenId: marker.eventTokenId,
    mintPaymentData: marker.mintPaymentData,
    eventPaymentData: marker.eventPaymentData,
    createTime: marker.createTime,
    transactionHash: marker.transactionHash,
  };

  localStorage.setItem("markers", JSON.stringify(markersObj));
};

export const getMarkerFromLocalStorage = (network, id) => {
  const markers = localStorage.getItem("markers");

  if (!markers) {
    return null;
  }

  return JSON.parse(markers)[network + "_" + id];
};

export const deleteMarkerFromLocalStorage = (network, id) => {
  const markers = localStorage.getItem("markers");

  if (!markers) {
    return;
  }

  const markersObj = JSON.parse(markers);

  if (markersObj[network + "_" + id]) {
    delete markersObj[network + "_" + id];
    localStorage.setItem("markers", JSON.stringify(markersObj));
  }
};

export const addEventToLocalStorage = (network, id, event) => {
  const events = localStorage.getItem("events");

  let eventsObj;

  if (events) {
    eventsObj = JSON.parse(events);
  } else {
    eventsObj = {};
  }

  eventsObj[network + "_" + id] = {
    network,
    eventId: id,
    creator: event.creator,
    owner: event.creator,
    name: event.name,
    description: event.description,
    enabled: true,
    maxMintCount: event.maxMintCount,
    currentMintCount: 0,
    customMarkerEnabled: event.customMarkerEnabled,
    mapCenter: {
      longitude: event.mapCenter.longitude,
      latitude: event.mapCenter.latitude,
    },
    mapZoom: event.mapZoom,
    createPaymentData: event.createPaymentData,
    createTime: event.createTime,
    transactionHash: event.transactionHash,
  };

  localStorage.setItem("events", JSON.stringify(eventsObj));
};

export const getEventFromLocalStorage = (network, id) => {
  const events = localStorage.getItem("events");

  if (!events) {
    return null;
  }

  return JSON.parse(events)[network + "_" + id];
};

export const deleteEventFromLocalStorage = (network, id) => {
  const events = localStorage.getItem("events");

  if (!events) {
    return;
  }

  const eventsObj = JSON.parse(events);

  if (eventsObj[network + "_" + id]) {
    delete eventsObj[network + "_" + id];
    localStorage.setItem("events", JSON.stringify(eventsObj));
  }
};

export const addTokenToStorage = (token) => {
  const jwtToken = localStorage.getItem("jwtToken");
  if(jwtToken) {
    localStorage.removeItem("jwtToken");
  } 
  localStorage.setItem("jwtToken", token);
};

export const getJwtToken = () => {
  return localStorage.getItem("jwtToken");
};

export const removeJwtToken = () => {
  localStorage.removeItem("jwtToken");
  return null;
}

export const hasJwtToken = () => {
  const jwtToken = localStorage.getItem("jwtToken");
  return jwtToken && jwtToken.length > 10;
}
