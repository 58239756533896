import { ZERO_ADDRESS } from "helpers/formatters";

const tokens = {
  "0xa869": [
    { address: ZERO_ADDRESS, decimal: 18, symbol: "AVAX" },
    {
      address: "0xcDfc48E54020a4caaEf157a33E89555AF6220dbe",
      decimal: 18,
      symbol: "PINY",
    },
    {
      address: "0xa4116d0376C82826f651d970dD612D8f0a1f9982",
      decimal: 18,
      symbol: "USDT",
    },
  ],
  "0x61": [
    { address: ZERO_ADDRESS, decimal: 18, symbol: "BNB" },
    {
      address: "0x27B2fB69fCC44898507dbB371eF02d0CF7eF9839",
      decimal: 18,
      symbol: "PINY",
    },
    {
      address: "0xd659Cca58EE6DD9B02C7e58DcfDDE4FDF13e6431",
      decimal: 18,
      symbol: "BUSD",
    },
  ],
  "0x62": [
    {
      address: "0x8f9B699203c06fdE87c3BaE6AD10C2DFA5348fBe",
      decimal: 18,
      symbol: "USDT",
    },
  ],
  "0x13881": [
    { address: ZERO_ADDRESS, decimal: 18, symbol: "MATIC" },
    {
      address: "0x063A0983181f31FcF019fbaC185BF9B783d23766",
      decimal: 18,
      symbol: "PINY",
    },
    {
      address: "0x970a7d0B5859493e81413d143693060846215A3F",
      decimal: 18,
      symbol: "USDT",
    },
  ],
  "0x5": [
    { address: ZERO_ADDRESS, decimal: 18, symbol: "ETH" },
    {
      address: "0xf30486b984966EC7bc49880aef0bd666087a7caE",
      decimal: 18,
      symbol: "PINY",
    },
    {
      address: "0x171693817e0C76335A65DBc30dCD955282A28dAa",
      decimal: 18,
      symbol: "USDT",
    },
    {
      address: "0x970a7d0B5859493e81413d143693060846215A3F",
      decimal: 18,
      symbol: "BUSD",
    },
  ],
  "0x66eed": [
    { address: ZERO_ADDRESS, decimal: 18, symbol: "ETH" },
    {
      address: "0xcC839925Db2F227aeBb0BD4b9c7E5Ab3928BDf14",
      decimal: 18,
      symbol: "PINY",
    },
    {
      address: "0x970a7d0B5859493e81413d143693060846215A3F",
      decimal: 18,
      symbol: "USDT",
    },
  ],
};

export const freeMintToken = {
  address: "0x0000000000000000000000000000000000000001",
  decimal: 18,
  symbol: "PFMT",
};

export const freeMintTokenPaymentMethod = {
  token: freeMintToken,
  amount: 0,
  paymentAddress: ZERO_ADDRESS,
};

export default tokens;
