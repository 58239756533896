import { MenuItem, Select, Slider, Stack } from "@mui/material";
import ColorPicker from "../colorPicker";
import Fonts from "helpers/fonts";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

export default function EditText({ textObject }) {
  const fonts = Fonts.getFonts();

  const [color, setColor] = useState(textObject.fill);
  const [opacity, setOpacity] = useState(textObject.opacity * 100);
  const [fontFamily, setFontFamily] = useState(textObject.fontFamily);
  const [fontSize, setFontSize] = useState(textObject.fontSize);

  const onColorChange = (color) => {
    textObject.set({ fill: color });
    textObject.canvas.renderAll();

    setColor(color);
  };

  const onOpacityChange = (value) => {
    textObject.set({ opacity: value / 100 });
    textObject.canvas.renderAll();

    setOpacity(value);
  };

  const onFontFamilyChange = (e) => {
    textObject.set({ fontFamily: e.target.value });
    textObject.canvas.renderAll();

    setFontFamily(e.target.value);
  };

  const onFontSizeChange = (value) => {
    textObject.set({ fontSize: value });
    textObject.canvas.renderAll();

    setFontSize(value);
  };

  useEffect(() => {
    setColor(textObject.fill);
    setOpacity(textObject.opacity * 100);
    setFontFamily(textObject.fontFamily);
    setFontSize(textObject.fontSize);
  }, [textObject]);

  return (
    <Stack flexDirection="column">
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack width="50%" flexDirection="row" alignItems="center">
          <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>Color:</h5>
          <div style={{ marginLeft: "4px" }}>
            <ColorPicker color={color} onChange={onColorChange} />
          </div>
        </Stack>
        <Stack
          width="50%"
          flexDirection="row"
          alignItems="center"
          paddingLeft="8px"
        >
          <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>Opacity:</h5>
          <Slider
            sx={{ marginLeft: "12px", marginRight: "16px" }}
            onChange={(e) => onOpacityChange(e.target.value)}
            value={opacity}
          ></Slider>
        </Stack>
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        marginTop="16px"
        alignItems="start"
      >
        <Stack
          width="50%"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <h5 style={{ marginTop: "4px", marginBottom: "0px" }}>
            Font Family:
          </h5>
          <Select
            value={fontFamily}
            onChange={onFontFamilyChange}
            size="small"
            sx={{
              ".MuiSelect-select": {
                padding: "4px 8px 4px 8px",
              },
            }}
          >
            {Object.keys(fonts.default).map((font) => {
              return (
                <MenuItem
                  key={fonts.default[font].name}
                  value={fonts.default[font].name}
                  style={{
                    whiteSpace: "normal",
                    height: "30px",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  <span
                    style={{
                      fontFamily: fonts.default[font].name,
                      fontSize: "small",
                    }}
                  >
                    {fonts.default[font].name}
                  </span>
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
        <Stack
          width="50%"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
          paddingLeft="8px"
        >
          <h5 style={{ marginTop: "4px", marginBottom: "0px" }}>Font Size:</h5>
          <Box sx={{ width: "calc(100% - 16px)" }}>
            <Slider
              onChange={(e) => onFontSizeChange(e.target.value)}
              value={fontSize}
            ></Slider>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
