import { useRef, useState } from "react";
import Blockies from "react-blockies";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import GroupIcon from "@mui/icons-material/Group";
import AppsIcon from "@mui/icons-material/Apps";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useMoralis } from "react-moralis";
import { getEllipsisTxt } from "helpers/formatters";

import "assets/styles/piny.css";
import ConnectWalletDialog from "components/common/connectWalletDialog";
import { useHistory } from "react-router-dom";
import { removeJwtToken } from "helpers/localStorage";

export default function AccountButton({ showDrawerSetter }) {
  const history = useHistory();

  const { isAuthenticated, logout, account } = useMoralis();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isVerySmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [showConnectWalletDialog, setShowConnectWalletDialog] = useState(false);

  const [accountMenuOpen, setAccountMenuOpen] = useState(false);
  const accountMenuAnchorRef = useRef(null);

  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const handleAccountMenuOpen = () => {
    setAccountMenuOpen(!accountMenuOpen);
  };

  const handleAccountMenuClose = (event) => {
    setAccountMenuOpen(false);

    if (isSmall && showDrawerSetter) {
      showDrawerSetter(false);
    }
  };

  const handleAccountModalOpen = () => {
    setAccountModalVisible(true);
  };

  const handleAccountModalClose = () => {
    setAccountModalVisible(false);
  };

  const onCopyAddressClick = () => {
    navigator.clipboard.writeText(account);
    setShowCopied(true);

    setTimeout(() => {
      setShowCopied(false);
    }, 3000);
  };

  const disconnectWallet = async () => {
    await logout();
    setAccountModalVisible(false);
    removeJwtToken();
  };

  const onConnectWalletClick = () => {
    setShowConnectWalletDialog(true);
  };

  const onConnecWalletDialogClose = () => {
    setShowConnectWalletDialog(false);
  };

  const menuContent = () => {
    if (!isAuthenticated) {
      return (
        <MenuList id="account-menu">
          <MenuItem onClick={onConnectWalletClick}>Connect Wallet</MenuItem>
        </MenuList>
      );
    }

    return (
      <MenuList id="account-menu">
        <MenuItem
          onClick={() => {
            handleAccountModalOpen();
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            padding="8px 0px 8px 0px"
          >
            <AccountBalanceWalletOutlinedIcon
              style={{ width: "26px", height: "26px" }}
            />
            <span style={{ marginLeft: "8px" }}>
              {getEllipsisTxt(account, 4)}
            </span>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAccountMenuClose();
            history.push(`/marker`);
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            padding="8px 0px 8px 0px"
          >
            <PersonPinCircleOutlinedIcon
              style={{ width: "26px", height: "26px" }}
            />
            <span style={{ marginLeft: "8px" }}>My Markers</span>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAccountMenuClose();
            history.push(`/collection`);
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            padding="8px 0px 8px 0px"
          >
            <AppsIcon style={{ width: "26px", height: "26px" }} />
            <span style={{ marginLeft: "8px" }}>My Collections</span>
          </Stack>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleAccountMenuClose();
            history.push(`/event`);
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            padding="8px 0px 8px 0px"
          >
            <GroupIcon style={{ width: "26px", height: "26px" }} />
            <span style={{ marginLeft: "8px" }}>My Events</span>
          </Stack>
        </MenuItem>
      </MenuList>
    );
  };

  return (
    <>
      {isSmall ? (
        menuContent()
      ) : isAuthenticated && account ? (
        <Box
          onClick={handleAccountMenuOpen}
          display="flex"
          mr="8px"
          ref={accountMenuAnchorRef}
          aria-controls={accountMenuOpen ? "account-menu" : undefined}
          aria-expanded={accountMenuOpen ? "true" : undefined}
          aria-haspopup="true"
        >
          <Blockies seed={account.toLowerCase()} className="accountBlockie" />
        </Box>
      ) : (
        <AccountCircleOutlinedIcon
          id="account-button"
          style={{
            width: "40px",
            height: "40px",
            marginRight: "8px",
            cursor: "pointer",
          }}
          ref={accountMenuAnchorRef}
          aria-controls={accountMenuOpen ? "account-menu" : undefined}
          aria-expanded={accountMenuOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleAccountMenuOpen}
        />
      )}
      <Popper
        open={accountMenuOpen}
        anchorEl={accountMenuAnchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleAccountMenuClose}>
                {menuContent()}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {isAuthenticated && account ? (
        <Dialog
          open={accountModalVisible}
          onClose={handleAccountModalClose}
          aria-labelledby="account-modal-title"
          aria-describedby="account-modal-description"
          disableScrollLock
        >
          <DialogTitle id="alert-dialog-title">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <h3 style={{ margin: "0px" }}>Wallet Info</h3>
              </Stack>
              <IconButton
                onClick={handleAccountModalClose}
                style={{ marginRight: "-16px", marginTop: "-16px" }}
              >
                <HighlightOffIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Blockies seed={account.toLowerCase()} className="blockie" />
              <h4 style={{ marginLeft: "8px" }}>
                {isVerySmall
                  ? getEllipsisTxt(account.toLowerCase(), 12)
                  : account.toLowerCase()}
              </h4>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ padding: "0px 16px 16px 16px" }}>
            <Tooltip title="Copied!" placement="top" arrow open={showCopied}>
              <Button
                variant="outlined"
                onClick={onCopyAddressClick}
                startIcon={<ContentCopyIcon />}
              >
                Copy Address
              </Button>
            </Tooltip>
            <Button
              variant="outlined"
              onClick={disconnectWallet}
              endIcon={<LogoutIcon />}
              color="error"
            >
              Disconnect Wallet
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <ConnectWalletDialog
          showDialog={showConnectWalletDialog}
          onDialogClose={onConnecWalletDialogClose}
        />
      )}
    </>
  );
}
