import { Contract, providers } from "ethers";
import { networkConfigs } from "./networks";

export const runContractFunction = async (options) => {
  if (!options || !options.chain || !networkConfigs[options.chain]) {
    return;
  }

  const rpcUrl = networkConfigs[options.chain].rpcUrl;

  if (!rpcUrl) {
    return;
  }

  const routerContract = new Contract(
    options.address,
    options.abi,
    new providers.JsonRpcProvider(rpcUrl)
  );

  if (options.params && Object.values(options.params).length > 0) {
    return await routerContract[options.functionName](
      ...Object.values(options.params)
    );
  }

  return await routerContract[options.functionName]();
};
