import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useMediaQuery,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditLocationAltOutlinedIcon from "@mui/icons-material/EditLocationAltOutlined";
import TextFormatOutlinedIcon from "@mui/icons-material/TextFormatOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import InsertEmoticonOutlinedIcon from "@mui/icons-material/InsertEmoticonOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { fabric } from "fabric";
import {
  DEFAULT_HEART_MARKER_SVG,
  DEFAULT_MARKER_SVG,
  DEFAULT_SQUARE_MARKER_SVG,
  TRANS_BG_IMAGE,
} from "./defaultImages";
import EditMarker from "./edit/marker";
import EditText from "./edit/text";
import EditImage from "./edit/image";
import EmojiPicker from "./emojiPicker";

export default function MarkerEditor({
  showDialog,
  onDialogClose,
  imageSetter,
  ...props
}) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const canvasRef = useRef();

  const [canvas, setCanvas] = useState();
  const [initialized, setInitialized] = useState(false);

  const [markerFabricObj, setMarkerFabricObj] = useState();
  const [markerShape, setMarkerShape] = useState("0");
  const [markerOpacity, setMarkerOpacity] = useState(100);
  const [markerColor, setMarkerColor] = useState("#E7BA55");
  const [markerBorder, setMarkerBorder] = useState(0);
  const [markerBorderColor, setMarkerBorderColor] = useState("#000000");
  const [emojiPickerAnchorEl, setEmojiPickerAnchorEl] = useState();
  const [customBackground, setCustomBackground] = useState();

  const [selectedEditor, setSelectedEditor] = useState(1);
  const [objectToEdit, setObjectToEdit] = useState();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    onDialogClose();
  };

  const computeEditor = (selectedObj) => {
    if (
      !selectedObj ||
      (selectedObj && selectedObj.length && selectedObj.length > 1)
    ) {
      setObjectToEdit(null);
      setSelectedEditor(0);

      return;
    }

    let obj = selectedObj;

    if (selectedObj.length) {
      obj = selectedObj[0];
    }

    setObjectToEdit(obj);

    const objectType = obj.get("type");

    if (objectType === "textbox") {
      if (obj.isEmoji) {
        setSelectedEditor(4);
      } else {
        setSelectedEditor(2);
      }
    } else if (objectType === "rect" || objectType === "ellipse") {
      setSelectedEditor(3);
    } else {
      setSelectedEditor(1);
      setObjectToEdit(null);
    }
  };

  const onObjectModified = (e) => {};

  const onObjectRemoved = (e) => {};

  const onSelectCreated = (e) => {
    computeEditor(e.selected);
  };

  const onSelectUpdated = (e) => {
    let selecteds;
    if (e.target._objects === undefined) {
      if (e.selected && e.selected.length === 0) {
        selecteds = { ...e.target };
      } else {
        selecteds = e.selected;
      }
    } else {
      selecteds = e.target._objects;
    }

    computeEditor(selecteds);
  };

  const onSelectCleared = (e) => {
    setSelectedEditor(1);
    setObjectToEdit(null);
  };

  useEffect(() => {
    if (!showDialog || initialized) {
      return;
    }

    const canvas = new fabric.Canvas(canvasRef.current);

    canvas.controlsAboveOverlay = true;

    let svgContent;

    if (markerShape === "0") {
      svgContent = DEFAULT_MARKER_SVG;
    } else if (markerShape === "1") {
      svgContent = DEFAULT_SQUARE_MARKER_SVG;
    } else if (markerShape === "2") {
      svgContent = DEFAULT_HEART_MARKER_SVG;
    }

    fabric.loadSVGFromString(svgContent, function (objects, options) {
      let obj = fabric.util.groupSVGElements(objects, options);
      obj.scale(0.1);
      obj.set({
        selectable: false,
        absolutePositioned: true,
        opacity: markerOpacity / 100,
        fill: markerColor,
        stroke: markerBorderColor,
        strokeWidth: markerBorder,
        hoverCursor: "initial",
      });

      canvas.add(obj);
      obj.center().setCoords();

      canvas.clipPath = obj;

      const bgImg = new Image();
      bgImg.src = TRANS_BG_IMAGE;

      bgImg.onload = function () {
        let f_img = new fabric.Image(bgImg);
        canvas.setBackgroundImage(f_img, canvas.renderAll.bind(canvas), {
          scaleX: canvas.width / f_img.width,
          scaleY: canvas.height / f_img.height,
        });
        canvas.renderAll();
      };

      setMarkerFabricObj(obj);
    });

    canvas.preserveObjectStacking = true;
    canvas.on({
      "object:modified": onObjectModified,
      "object:removed": onObjectRemoved,
      "selection:created": onSelectCreated,
      "selection:cleared": onSelectCleared,
      "selection:updated": onSelectUpdated,
    });

    setCanvas(canvas);
    setInitialized(true);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog, canvasRef]);

  const onEditMarkerButtonClick = () => {
    setSelectedEditor(1);

    canvas.discardActiveObject().renderAll();
  };

  const addTextBox = () => {
    const text = new fabric.Textbox("Edit...", {
      hasControls: true,
      fontSize: 30,
      fontFamily: "Verdana",
      isEmoji: false,
    });

    canvas.add(text);
    text.center();

    canvas.setActiveObject(text);

    canvas.renderAll();

    setSelectedEditor(2);
    setObjectToEdit(text);
  };

  const fileOnChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (f) {
      const data = f.target.result;

      fabric.Image.fromURL(data, function (img) {
        const photoFrameFabricObj = new fabric.Rect({
          width: img.width,
          height: img.height,
          stroke: "#000000",
          strokeWidth: 0,
          shape: "0",
        });

        const patternSourceCanvas = new fabric.StaticCanvas();
        patternSourceCanvas.setWidth(img.width);
        patternSourceCanvas.setHeight(img.height);
        patternSourceCanvas.add(img);
        patternSourceCanvas.renderAll();

        photoFrameFabricObj.set({
          fill: new fabric.Pattern({
            source: patternSourceCanvas.getElement(),
          }),
        });

        canvas.add(photoFrameFabricObj);

        let imageFrameWidth;

        if (img.height > img.width) {
          imageFrameWidth = 150 * (img.width / img.height);
        } else if (img.height < img.width) {
          imageFrameWidth = 150;
        }

        photoFrameFabricObj.scaleToWidth(imageFrameWidth);
        photoFrameFabricObj.center();

        canvas.setActiveObject(photoFrameFabricObj);

        canvas.renderAll();

        setObjectToEdit(photoFrameFabricObj);

        document.getElementById("marker-image-upload").value = null;
      });
    };

    setSelectedEditor(3);
  };

  const addEmoji = (emoji) => {
    const emojiObject = new fabric.Textbox(emoji, {
      fontSize: 128,
      isEmoji: true,
    });

    canvas.add(emojiObject);
    emojiObject.center();

    canvas.setActiveObject(emojiObject);

    setEmojiPickerAnchorEl(null);

    canvas.renderAll();
  };

  const onAddEmojiButtonClick = (e) => {
    setEmojiPickerAnchorEl(e.currentTarget);
  };

  const changeShape = (value) => {
    let svgContent;

    if (value === "0") {
      svgContent = DEFAULT_MARKER_SVG;
    } else if (value === "1") {
      svgContent = DEFAULT_SQUARE_MARKER_SVG;
    } else if (value === "2") {
      svgContent = DEFAULT_HEART_MARKER_SVG;
    } else if (value === "3" && customBackground) {
      canvas.remove(markerFabricObj);

      const existingObjects = canvas.getObjects();

      customBackground.set({
        opacity: markerOpacity / 100,
        stroke: markerBorderColor,
      });

      if (markerBorder) {
        let imageFrameWidth;

        if (customBackground.height > customBackground.width) {
          imageFrameWidth =
            canvas.getWidth() *
            (customBackground.width / customBackground.height);
        } else if (customBackground.height < customBackground.width) {
          imageFrameWidth = canvas.getWidth();
        }

        customBackground.scaleToWidth(customBackground.width);

        customBackground.scaleToWidth(imageFrameWidth);

        const strokeScale = imageFrameWidth / customBackground.width / 2;

        customBackground.set("strokeWidth", markerBorder / (10 * strokeScale));
      }

      canvas.clipPath = customBackground;

      canvas.add(customBackground);
      customBackground.center().setCoords();

      existingObjects.forEach((eo) => {
        eo.bringToFront();
      });

      canvas.renderAll();

      setMarkerFabricObj(customBackground);
      setMarkerShape(value);

      return;
    }

    fabric.loadSVGFromString(svgContent, function (objects, options) {
      canvas.remove(markerFabricObj);

      const existingObjects = canvas.getObjects();

      let obj = fabric.util.groupSVGElements(objects, options);
      obj.scale(0.1);
      obj.set({
        selectable: false,
        absolutePositioned: true,
        fill: markerColor,
        opacity: markerOpacity / 100,
        stroke: markerBorderColor,
        hoverCursor: "initial",
      });

      canvas.clipPath = obj;

      if (markerBorder) {
        obj.set("strokeWidth", 0);

        if (value === "2") {
          obj.scaleToWidth(canvas.getWidth() - markerBorder * 0.1);
        } else {
          obj.scaleToHeight(canvas.getHeight() - markerBorder * 0.1);
        }

        obj.set("strokeWidth", markerBorder);
      }

      canvas.add(obj);
      obj.center().setCoords();

      existingObjects.forEach((eo) => {
        eo.bringToFront();
      });

      canvas.renderAll();

      setMarkerFabricObj(obj);
    });

    setMarkerShape(value);
  };

  const onCustomBackgroundChange = (image) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);

    reader.onload = function (f) {
      const data = f.target.result;

      fabric.Image.fromURL(data, function (img) {
        canvas.remove(markerFabricObj);

        const existingObjects = canvas.getObjects();

        img.set({
          selectable: false,
          absolutePositioned: true,
          opacity: markerOpacity / 100,
          stroke: markerBorderColor,
          strokeWidth: 0,
          hoverCursor: "initial",
        });

        let imageWidth;

        if (img.height > img.width) {
          imageWidth = canvas.getWidth() * (img.width / img.height);
        } else if (img.height < img.width) {
          imageWidth = canvas.getWidth();
        }

        img.scaleToWidth(imageWidth);

        if (markerBorder) {
          const strokeScale = imageWidth / img.width / 2;

          img.set("strokeWidth", markerBorder / (10 * strokeScale));
        }

        canvas.clipPath = img;

        canvas.add(img);
        img.center().setCoords();

        existingObjects.forEach((eo) => {
          eo.bringToFront();
        });

        canvas.renderAll();

        setMarkerFabricObj(img);
        setCustomBackground(img);
      });
    };

    setMarkerShape("3");
  };

  const changeOpacity = (value) => {
    let bgImg = canvas.clipPath;
    bgImg.set({
      selectable: false,
      absolutePositioned: true,
      opacity: value / 100,
    });

    canvas.clipPath = bgImg;
    canvas.renderAll();

    setMarkerOpacity(value);
  };

  const changeBackgroundColor = (value) => {
    markerFabricObj.set("fill", value);
    canvas.renderAll();
    setMarkerColor(value);
  };

  const changeMarkerBorder = (value) => {
    markerFabricObj.set("strokeWidth", 0);

    if (markerShape === "3") {
      let imageFrameWidth;

      if (markerFabricObj.height > markerFabricObj.width) {
        imageFrameWidth =
          canvas.getWidth() * (markerFabricObj.width / markerFabricObj.height);
      } else if (markerFabricObj.height < markerFabricObj.width) {
        imageFrameWidth = canvas.getWidth();
      }

      markerFabricObj.scaleToWidth(markerFabricObj.width);

      markerFabricObj.scaleToWidth(imageFrameWidth);

      const strokeScale = imageFrameWidth / markerFabricObj.width / 2;

      markerFabricObj.set("strokeWidth", value / (10 * strokeScale));
    } else {
      markerFabricObj.scale(0.1);

      if (markerShape === "2") {
        markerFabricObj.scaleToWidth(canvas.getWidth() - value * 0.1);
      } else {
        markerFabricObj.scaleToHeight(canvas.getHeight() - value * 0.1);
      }

      markerFabricObj.set("strokeWidth", value);
    }

    markerFabricObj.center().setCoords();

    canvas.renderAll();

    setMarkerBorder(value);
  };

  const changeMarkerBorderColor = (value) => {
    markerFabricObj.set("stroke", value);
    canvas.renderAll();
    setMarkerBorderColor(value);
  };

  const removeObjects = () => {
    canvas.getActiveObjects().forEach((obj) => {
      canvas.remove(obj);
    });

    canvas.discardActiveObject();
  };

  const setCanvasBackground = () => {
    const bgImg = new Image();
    bgImg.onload = function () {
      let f_img = new fabric.Image(bgImg);
      canvas.setBackgroundImage(f_img, canvas.renderAll.bind(canvas), {
        scaleX: canvas.width / f_img.width,
        scaleY: canvas.height / f_img.height,
      });
      canvas.renderAll();
    };
    bgImg.src = TRANS_BG_IMAGE;
  };

  const clearCanvasBackground = () => {
    if (canvas) {
      canvas.setBackgroundImage(null);
      canvas.setBackgroundColor("");
      canvas.renderAll();
    }
  };

  const exportCanvasToImage = () => {
    clearCanvasBackground();

    const newImage = canvas.toDataURL({
      format: "png",
      quality: 0.8,
    });

    imageSetter(newImage);

    setCanvasBackground();

    onDialogClose();
  };

  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      keepMounted
      maxWidth="md"
      fullScreen={isSmall ? true : false}
      disableEnforceFocus={true}
      disableScrollLock
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <h3 style={{ margin: "0px" }}>Edit Marker</h3>
          </Stack>
          <IconButton
            onClick={onDialogClose}
            style={{ marginRight: "-16px", marginTop: "-16px" }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{ padding: isSmall ? "8px" : "16px", paddingBottom: "0px" }}
      >
        <Stack flexDirection="column">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid",
              borderColor: "divider",
              padding: "8px",
            }}
          >
            <canvas ref={canvasRef} width={336} height={336} />
          </Box>

          <Stack
            flexDirection="row"
            marginTop="8px"
            marginBottom="8px"
            justifyContent="space-around"
            alignItems="center"
          >
            <IconButton onClick={onEditMarkerButtonClick}>
              <EditLocationAltOutlinedIcon />
            </IconButton>
            <h4 style={{ margin: "0px 0px 0px 64px" }}>Add:</h4>
            <IconButton onClick={addTextBox}>
              <TextFormatOutlinedIcon />
            </IconButton>
            <IconButton aria-label="upload image" component="label">
              <input
                hidden
                accept="image/*"
                type="file"
                id="marker-image-upload"
                onChange={fileOnChange}
              />
              <ImageOutlinedIcon />
            </IconButton>
            <IconButton onClick={onAddEmojiButtonClick}>
              <InsertEmoticonOutlinedIcon />
            </IconButton>
            <EmojiPicker
              anchorEl={emojiPickerAnchorEl}
              anchorElSetter={setEmojiPickerAnchorEl}
              onSelect={addEmoji}
            />
          </Stack>
          <Stack
            flexDirection="column"
            sx={{
              borderTop: "1px solid",
              borderBottom: "1px solid",
              borderColor: "divider",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            {selectedEditor === 1 ? (
              <EditMarker
                background={markerShape}
                onBackgroundChange={changeShape}
                onBackgroundUploadImageChange={onCustomBackgroundChange}
                customBackground={customBackground}
                opacity={markerOpacity}
                onOpacityChange={changeOpacity}
                color={markerColor}
                onColorChange={changeBackgroundColor}
                border={markerBorder}
                onBorderChange={changeMarkerBorder}
                borderColor={markerBorderColor}
                onBorderColorChange={changeMarkerBorderColor}
              />
            ) : objectToEdit && selectedEditor === 2 ? (
              <EditText textObject={objectToEdit} />
            ) : objectToEdit && selectedEditor === 3 ? (
              <EditImage
                imageObject={objectToEdit}
                objectToEditSetter={setObjectToEdit}
              />
            ) : null}
            {selectedEditor !== 1 &&
            canvas &&
            canvas.getActiveObjects() &&
            canvas.getActiveObjects().length ? (
              <Stack
                flexDirection="row"
                justifyContent="center"
                marginTop="24px"
              >
                <Button
                  onClick={removeObjects}
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                >
                  {canvas.getActiveObjects().length > 1
                    ? "Remove All"
                    : "Remove"}
                </Button>
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: isSmall ? "8px" : "16px" }}>
        <Button
          startIcon={<DoneOutlinedIcon />}
          onClick={exportCanvasToImage}
          variant="outlined"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
