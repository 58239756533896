import {
  Box,
  IconButton,
  Stack,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { PINYWORLD_API_END_POINT } from "constant";
import { networkConfigs } from "helpers/networks";
import { getChainIdByNetwork } from "helpers/networks";
import { getNetworkLogo } from "helpers/networks";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import GroupIcon from "@mui/icons-material/Group";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { getEllipsisTxt } from "helpers/formatters";
import { useMoralis } from "react-moralis";

export default function MarketSaleHistory() {
  const history = useHistory();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { account } = useMoralis();

  const [saleList, setSaleList] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchingSales, setFetchingSales] = useState(false);
  const [totalSaleCount, setTotalSaleCount] = useState(0);

  const fetchSales = useCallback(async () => {
    try {
      setFetchingSales(true);

      const response = await axios.post(
        PINYWORLD_API_END_POINT + "markerSale/query/criteria",
        {
          criteria: {
            pageSize: 10,
            pageNumber: currentPage,
            sortRequestList: [
              { sortField: "createTime", sortDirection: "DESC" },
            ],
          },
        }
      );

      setSaleList(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setFetchingSales(false);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchSales();
  }, [fetchSales]);

  const onCurrentPageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axios.post(
          PINYWORLD_API_END_POINT + "markerSale/query/criteria/count",
          {
            criteria: {},
          }
        );

        setTotalSaleCount(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchInitialData();
  }, []);

  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: "8px",
        marginBottom: " 16px",
        overflow: "hidden",
      }}
    >
      <Box
        padding="16px"
        sx={{ borderBottom: "1px solid", borderColor: "divider" }}
      >
        <Typography variant="h5">Sale History</Typography>
      </Box>
      <Stack padding="0px 8px">
        {fetchingSales || !saleList || !saleList.length ? (
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "calc(100% - 32px)",
              borderBottom: "1px solid",
              borderColor: "divider",
              padding: "16px",
              height: "130px",
            }}
          >
            {!fetchingSales ? (
              <Typography>No sales found.</Typography>
            ) : (
              <Typography>Loading...</Typography>
            )}
          </Stack>
        ) : (
          saleList.map((sale) => {
            return (
              <Stack
                flexDirection="row"
                sx={{
                  width: "calc(100%-16px)",
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  padding: "16px 8px",
                }}
                key={sale.id}
              >
                <Stack
                  flexDirection={isSmall ? "column" : "row"}
                  alignItems={isSmall ? "" : "center"}
                  sx={{ width: "100%" }}
                >
                  <Stack
                    minWidth={isSmall ? "100%" : "96px"}
                    alignItems="center"
                  >
                    <img
                      src={`https://gateway.moralisipfs.com/ipfs/${sale.markerImageHash}`}
                      alt={sale.id}
                      style={{
                        maxWidth: "96px",
                        maxHeight: "96px",
                        minWidth: "96px",
                      }}
                    />
                  </Stack>
                  <Stack
                    sx={{
                      minWidth: isSmall ? "100%" : "360px",
                      marginLeft: isSmall ? "" : "24px",
                      marginTop: isSmall ? "16px" : "",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      textAlign={isSmall ? "center" : ""}
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {sale.markerName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: "8px",
                        minHeight: "40px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-all",
                        display: "-webkit-box",
                        "-webkit-line-clamp": "2",
                        "-webkit-box-orient": "vertical",
                      }}
                    >
                      {sale.markerMessage}
                    </Typography>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      sx={{ marginTop: "16px" }}
                    >
                      <Stack flexDirection="row" alignItems="center">
                        <img
                          src={getNetworkLogo(sale.network)}
                          alt={sale.network}
                          style={{
                            maxWidth: "24px",
                            maxHeight: "24px",
                            borderRadius: "50%",
                          }}
                          title={sale.network}
                        />

                        {sale.eventName ? (
                          <Stack direction="row" alignItems="center" ml="8px">
                            <GroupIcon
                              sx={{
                                color: "gray",
                                width: "18px",
                                height: "18px",
                                marginTop: "-2px",
                              }}
                            />

                            <Typography
                              variant="subtitle2"
                              sx={{
                                opacity: "0.65",
                                marginLeft: "2px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "256px",
                              }}
                            >
                              {sale.eventName}
                            </Typography>
                          </Stack>
                        ) : null}
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    sx={{
                      marginTop: isSmall ? "16px" : "",
                      marginLeft: isSmall ? "0px" : "16px",
                      minWidth: "100%",
                    }}
                  >
                    <Stack
                      sx={{
                        width: "100%",
                        maxWidth: isSmall ? "100%" : "280px",
                      }}
                    >
                      <Stack
                        flexDirection="row"
                        width="100%"
                        alignItems="center"
                        mt="8px"
                      >
                        <SellOutlinedIcon
                          sx={{
                            color: "gray",
                            width: "22px",
                            height: "22px",
                            marginTop: "-2px",
                          }}
                        />
                        <Typography
                          variant="h5"
                          sx={{
                            marginLeft: "2px",
                            opacity: "0.65",
                          }}
                        >
                          {`${sale.price.value} ${sale.price.metadata.symbol}`}
                        </Typography>
                      </Stack>

                      <Stack direction="row" alignItems="center" mt="8px">
                        <CalendarMonthIcon
                          sx={{
                            color: "gray",
                            width: "18px",
                            height: "18px",
                            marginTop: "-2px",
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          sx={{ marginLeft: "2px", opacity: "0.65" }}
                        >
                          {new Date(sale.createTime).toLocaleDateString()}
                        </Typography>
                      </Stack>

                      <Stack flexDirection="row" alignItems="center" mt="8px">
                        <PersonPinCircleOutlinedIcon
                          sx={{
                            color: "gray",
                            width: "18px",
                            height: "18px",
                            marginTop: "-2px",
                          }}
                        />

                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            marginLeft: "2px",
                            opacity: "0.65",
                          }}
                          variant="subtitle2"
                        >
                          {sale.seller === account
                            ? "from you"
                            : getEllipsisTxt(sale.seller, 5)}
                        </Typography>

                        <ArrowRightAltOutlinedIcon
                          sx={{
                            color: "gray",
                            width: "18px",
                            height: "18px",
                            marginTop: "-2px",
                            ml: "8px",
                          }}
                        />

                        <PersonPinCircleOutlinedIcon
                          sx={{
                            color: "gray",
                            width: "18px",
                            height: "18px",
                            marginTop: "-2px",
                            ml: "8px",
                          }}
                        />

                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            marginLeft: "2px",
                            opacity: "0.65",
                          }}
                          variant="subtitle2"
                        >
                          {sale.buyer === account
                            ? "to you"
                            : getEllipsisTxt(sale.buyer, 5)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <IconButton
                      onClick={() =>
                        history.push(
                          `/marker/${
                            networkConfigs[getChainIdByNetwork(sale.network)]
                              .code
                          }/${sale.tokenId}`
                        )
                      }
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
            );
          })
        )}
        <TablePagination
          component="div"
          count={totalSaleCount}
          rowsPerPage={10}
          page={currentPage}
          onPageChange={onCurrentPageChange}
          rowsPerPageOptions={[10]}
        />
      </Stack>
    </Stack>
  );
}
