import {
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import homeBackgroundImg from "assets/img/home-background.png";
import protestImg from "assets/img/protest.png";
import donateImg from "assets/img/donate.png";
import gorupActivityImg from "assets/img/groupActivity.png";
import moreImg from "assets/img/more.png";
import avaxImg from "assets/img/avalanche.png";
import bscImg from "assets/img/bsc.png";
import polygonImg from "assets/img/polygon.png";
import ethereumImg from "assets/img/eth.png";
import marketPlaceImg from "assets/img/market-place.png";

export default function Home() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Stack width="100%" sx={{ backgroundColor: "white" }}>
      <Stack
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.7) 33%, rgba(232,201,214,0.7) 100%), url(${homeBackgroundImg})`,
          backgroundPosition: "center",
        }}
      >
        <Container maxWidth="lg" sx={{ minHeight: "560px" }}>
          <Stack
            width="100%"
            minHeight="100%"
            justifyContent="center"
            sx={{ minHeight: "560px" }}
          >
            <Stack flexDirection={isSmall ? "column" : "row"}>
              <Typography variant={isSmall ? "h4" : "h3"} fontWeight="600">
                LEAVE A PIN
              </Typography>
              <Typography
                variant={isSmall ? "h4" : "h3"}
                color="#852999"
                ml={isSmall ? "" : "8px"}
                fontWeight="600"
              >
                ON THE WORLD
              </Typography>
            </Stack>
            <Typography mt="32px" fontSize="20px" lineHeight="2">
              Create location based NFTs on the most popular blockchains
              networks and pin with your message to the world.
            </Typography>
          </Stack>
        </Container>
      </Stack>

      <Stack width="100%">
        <Container>
          <Stack width="100%" p="136px 0px 136px 0px" alignItems="center">
            <Stack
              flexDirection={isSmall ? "column" : "row"}
              justifyContent="center"
            >
              <Typography
                variant={isSmall ? "h4" : "h3"}
                textAlign={isSmall ? "center" : ""}
                fontWeight="600"
              >
                CREATE OR JOIN
              </Typography>
              <Typography
                variant={isSmall ? "h4" : "h3"}
                color="#852999"
                ml={isSmall ? "8px" : "8px"}
                fontWeight="600"
                textAlign={isSmall ? "center" : ""}
              >
                EVENTS
              </Typography>
            </Stack>

            <Typography
              textAlign="center"
              mt="32px"
              fontSize="20px"
              lineHeight="2"
            >
              Events are where people gather with their markers to
            </Typography>

            <Grid container columns={12} spacing={2} mt="48px">
              <Grid item xs={12} md={3}>
                <Stack
                  p="24px"
                  alignItems="center"
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                >
                  <img
                    src={protestImg}
                    alt="Protest"
                    style={{ width: "72px" }}
                  />

                  <Typography variant="h6" mt="16px">
                    Protest
                  </Typography>
                  <Typography variant="subtitle1" mt="16px" textAlign="center">
                    People gather under an event to voice their concerns.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack
                  p="24px"
                  alignItems="center"
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                >
                  <img
                    src={donateImg}
                    alt="Protest"
                    style={{ width: "72px" }}
                  />

                  <Typography variant="h6" mt="16px">
                    Donate
                  </Typography>
                  <Typography variant="subtitle1" mt="16px" textAlign="center">
                    Coordinate a fundraising compaign with an event.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack
                  p="24px"
                  alignItems="center"
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                >
                  <img
                    src={gorupActivityImg}
                    alt="Protest"
                    style={{ width: "72px" }}
                  />

                  <Typography variant="h6" mt="16px">
                    Group Activities
                  </Typography>
                  <Typography variant="subtitle1" mt="16px" textAlign="center">
                    Arrange an event to have a great time with your friends.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack
                  p="24px"
                  alignItems="center"
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "rgba(243, 243, 243, 1)",
                  }}
                >
                  <img src={moreImg} alt="Protest" style={{ width: "72px" }} />

                  <Typography variant="h6" mt="16px">
                    And More
                  </Typography>
                  <Typography variant="subtitle1" mt="16px" textAlign="center">
                    Limit for what you can do with events is your imagination.
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Stack>

      <Stack width="100%" sx={{ backgroundColor: "#fffbff" }}>
        <Container>
          <Stack width="100%" p="136px 0px" alignItems="center">
            <Stack
              flexDirection={isSmall ? "column" : "row"}
              justifyContent="center"
            >
              <Typography
                variant={isSmall ? "h4" : "h3"}
                textAlign={isSmall ? "center" : ""}
                fontWeight="600"
              >
                FULLY
              </Typography>
              <Typography
                variant={isSmall ? "h4" : "h3"}
                color="#852999"
                ml={isSmall ? "8px" : "8px"}
                fontWeight="600"
                textAlign={isSmall ? "center" : ""}
              >
                DECENTRALIZED
              </Typography>
            </Stack>

            <Typography
              textAlign="center"
              mt="32px"
              fontSize="20px"
              lineHeight="2"
            >
              All essential logic and data is managed on the most popular
              blockchain networks
            </Typography>

            <Grid
              container
              columns={12}
              rowSpacing={6}
              columnSpacing={2}
              ml="0px !important"
              width="100% !important"
              mt="72px"
              p={{ xs: "56px 16px", md: "56px 32px" }}
              sx={{
                borderRadius: "8px",
                backgroundColor: "rgba(243, 243, 243, 1)",
              }}
            >
              <Grid item xs={6} md={3} pt="0px !important" pl="0px !important">
                <Stack alignItems="center">
                  <Stack
                    width={isSmall ? "64px" : "96px"}
                    height={isSmall ? "64px" : "96px"}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={ethereumImg}
                      alt="ETH"
                      style={{
                        maxWidth: isSmall ? "64px" : "96px",
                        maxHeight: isSmall ? "64px" : "96px",
                      }}
                    />
                  </Stack>

                  <Typography
                    variant={isSmall ? "h5" : "h4"}
                    color="#852999"
                    mt="24px"
                    fontWeight="600"
                  >
                    ETHEREUM
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={3} pt="0px !important">
                <Stack alignItems="center">
                  <Stack
                    width={isSmall ? "64px" : "96px"}
                    height={isSmall ? "64px" : "96px"}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={bscImg}
                      alt="BSC"
                      style={{
                        maxWidth: isSmall ? "64px" : "96px",
                        maxHeight: isSmall ? "64px" : "96px",
                      }}
                    />
                  </Stack>

                  <Typography
                    variant={isSmall ? "h5" : "h4"}
                    color="#852999"
                    mt="24px"
                    fontWeight="600"
                  >
                    BSC
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                pt={{ xs: "48px !important", md: "0px !important" }}
                pl={{ xs: "0px !important", md: "16px !important" }}
              >
                <Stack alignItems="center">
                  <Stack
                    width={isSmall ? "64px" : "96px"}
                    height={isSmall ? "64px" : "96px"}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={polygonImg}
                      alt="POLYGON"
                      style={{
                        maxWidth: isSmall ? "64px" : "96px",
                        maxHeight: isSmall ? "64px" : "96px",
                      }}
                    />
                  </Stack>

                  <Typography
                    variant={isSmall ? "h5" : "h4"}
                    color="#852999"
                    mt={{ xs: "16px", md: "24px" }}
                    fontWeight="600"
                  >
                    POLYGON
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                pt={{ xs: "48px !important", md: "0px !important" }}
              >
                <Stack alignItems="center">
                  <Stack
                    width={isSmall ? "64px" : "96px"}
                    height={isSmall ? "64px" : "96px"}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={avaxImg}
                      alt="AVAX"
                      style={{
                        maxWidth: isSmall ? "64px" : "96px",
                        maxHeight: isSmall ? "64px" : "96px",
                      }}
                    />
                  </Stack>

                  <Typography
                    variant={isSmall ? "h5" : "h4"}
                    color="#852999"
                    mt={{ xs: "16px", md: "24px" }}
                    fontWeight="600"
                  >
                    AVAX
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Stack>

      <Stack width="100%">
        <Container>
          <Stack width="100%" p="136px 0px 136px 0px" alignItems="center">
            {isSmall ? (
              <Stack alignItems="center">
                <Stack flexDirection="row">
                  <Typography variant="h4" fontWeight="600">
                    MARKET
                  </Typography>
                  <Typography variant="h4" color="#852999" fontWeight="600">
                    PLACE
                  </Typography>
                </Stack>

                <img
                  src={marketPlaceImg}
                  alt="Market Place"
                  style={{ marginTop: "32px", maxWidth: "100%" }}
                />

                <Typography
                  mt="32px"
                  fontSize="20px"
                  lineHeight="2"
                  textAlign="center"
                >
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet
                </Typography>

                <Stack flexDirection="row" mt="24px">
                  <Stack
                    width="50%"
                    p="16px"
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "rgba(243, 243, 243, 1)",
                    }}
                  >
                    <Typography variant="h6" color="#852999">
                      Sell your markers
                    </Typography>
                    <Typography mt="16px">
                      Amet minim mollit non deserunt ullamco mollit
                    </Typography>
                  </Stack>
                  <Stack
                    width="50%"
                    ml="16px"
                    p="16px"
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "rgba(243, 243, 243, 1)",
                    }}
                  >
                    <Typography variant="h6" color="#852999">
                      Buy markers
                    </Typography>
                    <Typography mt="16px">
                      Amet minim mollit non deserunt ullamco mollit
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <Stack flexDirection="row">
                <img
                  src={marketPlaceImg}
                  alt="Market Place"
                  style={{ maxWidth: "480px" }}
                />

                <Stack ml="64px" width="100%">
                  <Stack flexDirection="row">
                    <Typography variant="h3" fontWeight="600">
                      MARKET
                    </Typography>
                    <Typography variant="h3" color="#852999" fontWeight="600">
                      PLACE
                    </Typography>
                  </Stack>

                  <Typography mt="32px" fontSize="20px" lineHeight="2">
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit. Exercitation veniam consequat sunt nostrud amet
                  </Typography>

                  <Stack flexDirection="row" mt="24px">
                    <Stack
                      width="50%"
                      p="16px"
                      sx={{
                        borderRadius: "8px",
                        backgroundColor: "rgba(243, 243, 243, 1)",
                      }}
                    >
                      <Typography variant="h6" color="#852999">
                        Sell your markers
                      </Typography>
                      <Typography mt="16px">
                        Amet minim mollit non deserunt ullamco mollit
                      </Typography>
                    </Stack>
                    <Stack
                      width="50%"
                      ml="16px"
                      p="16px"
                      sx={{
                        borderRadius: "8px",
                        backgroundColor: "rgba(243, 243, 243, 1)",
                      }}
                    >
                      <Typography variant="h6" color="#852999">
                        Buy markers
                      </Typography>
                      <Typography mt="16px">
                        Amet minim mollit non deserunt ullamco mollit
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
}
