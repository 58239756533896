import {
  Button,
  Checkbox,
  Chip,
  Container,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  TablePagination,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axios from "axios";
import PageLoadingSpinner from "components/common/pageLoadingSpinner";
import { PINYWORLD_API_END_POINT } from "constant";
import {
  getChainIdByNetwork,
  getNetworkLogo,
  networkConfigs,
} from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SortIcon from "@mui/icons-material/Sort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownWideShort,
  faArrowDownShortWide,
} from "@fortawesome/free-solid-svg-icons";
import { getEllipsisTxt } from "helpers/formatters";
import { useMoralis } from "react-moralis";

export default function ExploreEventPage() {
  const { account } = useMoralis();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const history = useHistory();

  const [userCoordinate, setUserCoordinate] = useState({
    longitude: 0,
    latitude: 0,
  });

  const [fetchingData, setFetchingData] = useState(true);
  const [fetchingPageData, setFetchingPageData] = useState(false);
  const [searching, setSearching] = useState(false);

  const [events, setEvents] = useState([]);
  const [eventsTotal, setEventsTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [sortBy, setSortBy] = useState("default");
  const [sortDirection, setSortDirection] = useState("none");
  const [criteria, setCriteria] = useState({
    pageSize: 10,
    pageNumber: 0,
    onlyEnabled: true,
    sortRequestList: [{ sortField: "createTime", sortDirection: "DESC" }],
  });

  const networks = {
    Avax: "Avalanche",
    BSC: "BSC",
    ETH: "Ethereum",
    Polygon: "Polygon",
  };

  const fetchEvents = async (pageNumber, pageChange) => {
    try {
      const response = await axios.post(
        PINYWORLD_API_END_POINT + "event/query/criteria",
        {
          criteria: {
            ...criteria,
            markerSliceSize: 3,
            pageSize: 10,
            pageNumber,
          },
        }
      );

      setEvents(response.data);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
      setEvents([]);
    } finally {
      if (pageChange) {
        setFetchingPageData(false);
      }
    }
  };

  const fetchEventByCriteria = useCallback(async () => {
    setFetchingData(true);

    try {
      let response = await axios.post(
        PINYWORLD_API_END_POINT + "event/query/criteria/count",
        {
          criteria: criteria,
        }
      );

      setEventsTotal(response.data);

      if (response.data === 0) {
        setEvents([]);
      } else {
        await fetchEvents(0, false);
      }

      setCurrentPage(0);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
      setEvents([]);
    } finally {
      setFetchingData(false);
      setSearching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria]);

  useEffect(() => {
    fetchEventByCriteria();
  }, [fetchEventByCriteria]);

  const onCurrentPageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
    setFetchingPageData(true);
    fetchEvents(pageNumber, true);
  };

  const onSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const onSelectedNetworksChange = (event) => {
    setSelectedNetworks(event.target.value);
  };

  const onSortByChange = (event) => {
    const selection = event.target.value;

    setSortBy(selection);

    switch (selection) {
      case "default":
        setSortDirection("none");
        break;
      case "distance":
        setSortDirection("ASC");
        break;
      case "currentMintCount":
        setSortDirection("DESC");
        break;
      case "createTime":
        setSortDirection("DESC");
        break;
      default:
        return;
    }
  };

  const onSortDirectionChange = (event, newValue) => {
    setSortDirection(newValue);
  };

  const onApplyClick = () => {
    const tempCriteria = {
      searchText,
      networkList: selectedNetworks,
      sortRequestList:
        sortBy === "distance"
          ? []
          : [
              {
                sortField: sortBy === "default" ? "createTime" : sortBy,
                sortDirection:
                  sortDirection === "none" ? "DESC" : sortDirection,
              },
            ],
      onlyEnabled: true,
    };

    if (sortBy === "distance") {
      tempCriteria["nearBy"] = {
        longitude: userCoordinate.longitude,
        latitude: userCoordinate.latitude,
      };
    }

    setCriteria(tempCriteria);
    setSearching(true);
  };

  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const userLocationResponse = await axios.get(
          PINYWORLD_API_END_POINT + "location/user-location"
        );

        setUserCoordinate({
          latitude: parseFloat(userLocationResponse.data.latitude),
          longitude: parseFloat(userLocationResponse.data.longitude),
        });
      } catch (err) {
        ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
      }
    };

    fetchUserLocation();
  }, []);

  return (
    <Container
      maxWidth="md"
      sx={{ backgroundColor: "white", paddingBottom: "48px" }}
    >
      <Stack>
        <h1>Explore Events</h1>

        {fetchingData && !searching ? (
          <PageLoadingSpinner />
        ) : (
          <Stack sx={{ rowGap: "8px" }}>
            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 64 }}
              value={searchText}
              onChange={onSearchTextChange}
              placeholder="Search by event name, description, creator, owner"
              sx={{
                "& .MuiOutlinedInput-input": {
                  paddingTop: "12px",
                  paddingBottom: "12px",
                },
              }}
            />

            <Select
              displayEmpty
              multiple
              value={selectedNetworks}
              onChange={onSelectedNetworksChange}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <Typography sx={{ color: "currentColor", opacity: "0.42" }}>
                      Filter by network
                    </Typography>
                  );
                }
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={networks[value]}
                        sx={{
                          "& .MuiChip-root": {
                            height: "23px",
                          },
                        }}
                      />
                    ))}
                  </Box>
                );
              }}
              startAdornment={
                <InputAdornment position="start">
                  <FilterAltOutlinedIcon />
                </InputAdornment>
              }
              sx={{
                "& .MuiSelect-select": {
                  paddingTop: "12px",
                  paddingBottom: "12px",
                },
              }}
            >
              <MenuItem key="avax" value="Avax">
                <Checkbox
                  checked={
                    selectedNetworks.findIndex(
                      (network) => network === "Avax"
                    ) > -1
                  }
                />
                <ListItemText primary="Avalanche" />
              </MenuItem>
              <MenuItem key="bsc" value="BSC">
                <Checkbox
                  checked={
                    selectedNetworks.findIndex((network) => network === "BSC") >
                    -1
                  }
                />
                <ListItemText primary="BSC" />
              </MenuItem>
              <MenuItem key="eth" value="ETH">
                <Checkbox
                  checked={
                    selectedNetworks.findIndex((network) => network === "ETH") >
                    -1
                  }
                />
                <ListItemText primary="Ethereum" />
              </MenuItem>
              <MenuItem key="polygon" value="Polygon">
                <Checkbox
                  checked={
                    selectedNetworks.findIndex(
                      (network) => network === "Polygon"
                    ) > -1
                  }
                />
                <ListItemText primary="Polygon" />
              </MenuItem>
            </Select>

            <Stack
              flexDirection={isSmall ? "column" : "row"}
              alignItems="center"
              sx={{ width: "100%", rowGap: isSmall ? "8px" : "" }}
            >
              <Stack flexDirection="row" sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  value={sortBy}
                  onChange={onSortByChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <SortIcon />
                    </InputAdornment>
                  }
                  renderValue={
                    sortBy !== "default"
                      ? undefined
                      : () => {
                          return (
                            <Typography
                              sx={{ color: "currentColor", opacity: "0.42" }}
                            >
                              Sort by
                            </Typography>
                          );
                        }
                  }
                  sx={{
                    width: "100%",
                    "& .MuiSelect-select": {
                      paddingTop: "12px",
                      paddingBottom: "12px",
                    },
                  }}
                >
                  <MenuItem value="default">Default</MenuItem>
                  <MenuItem value="distance">Distance</MenuItem>
                  <MenuItem value="currentMintCount">Pin Count</MenuItem>
                  <MenuItem value="createTime">Create Time</MenuItem>
                </Select>

                <ToggleButtonGroup
                  value={sortDirection}
                  exclusive
                  onChange={onSortDirectionChange}
                  sx={{
                    marginLeft: "8px",
                    "& .MuiButtonBase-root": { padding: "16px" },
                  }}
                  disabled={sortBy === "default" || sortBy === "distance"}
                >
                  <ToggleButton value="ASC">
                    <FontAwesomeIcon icon={faArrowDownShortWide} />
                  </ToggleButton>
                  <ToggleButton value="DESC">
                    <FontAwesomeIcon icon={faArrowDownWideShort} />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              <Button
                onClick={onApplyClick}
                variant="outlined"
                sx={{
                  marginLeft: isSmall ? "" : "16px",
                  padding: "10.75px",
                  minWidth: isSmall ? "100%" : "150px",
                }}
                disabled={searching}
              >
                Apply
              </Button>
            </Stack>

            <Stack
              sx={{ rowGap: "16px", marginTop: "16px", overflow: "hidden" }}
            >
              {!events || !events.length || fetchingPageData || searching ? (
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: "calc(100% - 34px)",
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: "8px",
                    padding: "16px",
                    height: "130px",
                  }}
                >
                  {searching ? (
                    <Typography>Searching for events...</Typography>
                  ) : !fetchingPageData ? (
                    <Typography>No event found</Typography>
                  ) : (
                    <Typography>Loading...</Typography>
                  )}
                </Stack>
              ) : (
                events.map((event) => {
                  return (
                    <Stack
                      flexDirection="row"
                      sx={{
                        width: "calc(100% - 34px)",
                        border: "1px solid",
                        borderColor: "divider",
                        borderRadius: "8px",
                        padding: "16px",
                      }}
                      key={event.id}
                    >
                      <Stack
                        flexDirection={isSmall ? "column" : "row"}
                        alignItems={isSmall ? "" : "center"}
                        sx={{ width: "100%" }}
                      >
                        <Stack sx={{ minWidth: isSmall ? "100%" : "500px" }}>
                          <Typography
                            variant="h5"
                            component="div"
                            textAlign={isSmall ? "center" : ""}
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {event.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              marginTop: "16px",
                              minHeight: "48px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-all",
                              display: "-webkit-box",
                              "-webkit-line-clamp": "2",
                              "-webkit-box-orient": "vertical",
                            }}
                          >
                            {event.description}
                          </Typography>
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            sx={{ marginTop: "16px" }}
                          >
                            <img
                              src={getNetworkLogo(event.network)}
                              alt={event.network}
                              style={{
                                maxWidth: "24px",
                                maxHeight: "24px",
                                borderRadius: "50%",
                              }}
                              title={event.network}
                            />

                            <Typography
                              sx={{ marginLeft: "8px", opacity: "0.65" }}
                              variant="subtitle2"
                            >
                              {event.owner === account
                                ? "owned by you"
                                : getEllipsisTxt(event.owner, 6)}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack
                          flexDirection="row"
                          alignItems="center"
                          sx={{
                            marginTop: isSmall ? "16px" : "",
                            marginLeft: isSmall ? "0px" : "16px",
                            minWidth: "100%",
                          }}
                        >
                          <Stack
                            sx={{
                              width: "100%",
                              maxWidth: isSmall ? "100%" : "264px",
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ height: "24px" }}
                            >
                              <CalendarMonthIcon
                                sx={{
                                  color: "gray",
                                  width: "18px",
                                  height: "18px",
                                  marginTop: "-2px",
                                }}
                              />
                              <Typography
                                variant="subtitle2"
                                sx={{ marginLeft: "2px", opacity: "0.65" }}
                              >
                                {new Date(
                                  event.createTime
                                ).toLocaleDateString()}
                              </Typography>
                            </Stack>

                            <Box
                              sx={{
                                display: "flex",
                                minHeight: "40px",
                                alignItems: "center",
                                marginTop: "8px",
                              }}
                              title={
                                event.markerImageList &&
                                event.markerImageList.length
                                  ? event.markerImageList.length === 1
                                    ? "1 marker"
                                    : `${event.markerImageList.length} markers`
                                  : null
                              }
                            >
                              {event.markerImageList &&
                              event.markerImageList.length ? (
                                event.markerImageList.map((markerImage, i) => {
                                  return (
                                    <Stack
                                      key={markerImage.urlPath}
                                      sx={{
                                        border: "1px solid",
                                        borderColor: "divider",
                                        borderRadius: "4px",
                                        marginLeft: i > 0 ? "4px" : "0px",
                                        padding: "2px",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                    >
                                      <img
                                        src={`https://gateway.moralisipfs.com/ipfs/${markerImage.urlPath}`}
                                        alt={markerImage.urlPath}
                                        key={markerImage.urlPath}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "white",
                                        }}
                                      />
                                    </Stack>
                                  );
                                })
                              ) : (
                                <Typography
                                  variant="subtitle2"
                                  sx={{ opacity: "0.65" }}
                                >
                                  No marker added
                                </Typography>
                              )}
                            </Box>

                            <Stack
                              flexDirection="row"
                              width="100%"
                              alignItems="center"
                              mt="8px"
                            >
                              <PinDropOutlinedIcon
                                sx={{
                                  color: "gray",
                                  width: "18px",
                                  height: "18px",
                                  marginTop: "-2px",
                                }}
                              />

                              <Typography
                                variant="subtitle2"
                                sx={{ marginLeft: "2px", opacity: "0.65" }}
                              >
                                {event.currentMintCount}
                              </Typography>
                            </Stack>
                          </Stack>
                          <IconButton
                            onClick={() =>
                              history.push(
                                `/event/${
                                  networkConfigs[
                                    getChainIdByNetwork(event.network)
                                  ].code
                                }/${event.eventId}`
                              )
                            }
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })
              )}

              <TablePagination
                component="div"
                count={eventsTotal}
                rowsPerPage={10}
                page={currentPage}
                onPageChange={onCurrentPageChange}
                rowsPerPageOptions={[10]}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Container>
  );
}
