import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { PINYWORLD_API_END_POINT } from "constant";
import { getEllipsisTxt } from "helpers/formatters";
import { getNetworkDatabaseNameByCode } from "helpers/networks";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getScannerTxUrlByNetworkCode } from "helpers/networks";
import { useMoralis } from "react-moralis";

export default function MarkerActivity({ tokenId, network }) {
  const { account } = useMoralis();

  const [activityList, setActivityList] = useState();
  const [activityCount, setActivityCount] = useState(0);
  const [activityListPage, setActivityListPage] = useState(0);

  const fetchActivityData = useCallback(async () => {
    if (!(tokenId && network)) {
      setActivityList([]);
      return;
    }

    try {
      const criteria = {
        tokenIdList: [tokenId],
        networkList: [getNetworkDatabaseNameByCode(network)],
        pageSize: 10,
        pageNumber: activityListPage,
        sortRequestList: [{ sortField: "createTime", sortDirection: "DESC" }],
      };

      let response = await axios.post(
        PINYWORLD_API_END_POINT + "markerActivity/query/criteria/count",
        {
          criteria: criteria,
        }
      );

      setActivityCount(response.data);

      response = await axios.post(
        PINYWORLD_API_END_POINT + "markerActivity/query/criteria",
        {
          criteria: criteria,
        }
      );

      setActivityList(response.data);
    } catch (err) {
      console.error(err);
    }
  }, [tokenId, network, activityListPage]);

  useEffect(() => {
    fetchActivityData();
  }, [fetchActivityData]);

  const onActivityListPageChange = (event, newPage) => {
    setActivityListPage(newPage);
  };

  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: "divider",
        borderRadius: "8px",
        marginTop: "16px",
        marginBottom: " 16px",
        overflow: "hidden",
      }}
    >
      <Box
        padding="8px"
        sx={{ borderBottom: "1px solid", borderColor: "divider" }}
      >
        <Typography variant="h5">Activity History</Typography>
      </Box>
      <TableContainer
        sx={{
          width: "calc(100% - 16px)",
          paddingLeft: "8px",
          paddingRight: "8px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Tx</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!activityList ? (
              <TableRow>
                <TableCell colSpan={5}>Loading...</TableCell>
              </TableRow>
            ) : !activityList.length ? (
              <TableRow>
                <TableCell colSpan={5}>No activity found.</TableCell>
              </TableRow>
            ) : (
              activityList.map((activity) => {
                return (
                  <TableRow hover key={activity.id}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body1">
                        {activity.type === "MINT"
                          ? "Mint"
                          : activity.type === "LIST"
                          ? "List"
                          : activity.type === "SALE"
                          ? "Sale"
                          : activity.type === "CANCEL"
                          ? "Cancel"
                          : activity.type === "TRANSFER"
                          ? "Transfer"
                          : null}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {activity.type === "LIST" ||
                      activity.type === "SALE" ||
                      activity.type === "CANCEL" ? (
                        activity.price &&
                        activity.price.value &&
                        activity.price.metadata ? (
                          <Typography>
                            {activity.price.value}{" "}
                            {activity.price.metadata.symbol}
                          </Typography>
                        ) : null
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {activity.from === account
                        ? "you"
                        : getEllipsisTxt(activity.from, 4)}
                    </TableCell>
                    <TableCell>
                      {activity.to === account
                        ? "you"
                        : getEllipsisTxt(activity.to, 4)}
                    </TableCell>
                    <TableCell>
                      {new Date(activity.createTime).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <a
                        href={getScannerTxUrlByNetworkCode(
                          network,
                          activity.transactionHash
                        )}
                        target="_blank"
                        rel="noreferrer"
                        style={{ display: "flex" }}
                      >
                        <OpenInNewIcon
                          sx={{
                            marginLeft: "4px",
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={activityCount}
        rowsPerPage={10}
        page={activityListPage}
        onPageChange={onActivityListPageChange}
        rowsPerPageOptions={[10]}
      />
    </Stack>
  );
}
