import {
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  MenuList,
  Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@mui/icons-material/Group";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useHistory } from "react-router-dom";
import styled from "@emotion/styled";
import AccountButton from "./account";

const SectionHeader = styled.h3`
  margin: 0px;
  padding: 16px 16px 0px 16px;
`;

export default function HeaderSmallMenu() {
  const history = useHistory();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Stack>
      <IconButton
        onClick={() => {
          setMenuOpen(true);
        }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>

      <Drawer
        anchor="right"
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false);
        }}
      >
        <Stack width="250px">
          <SectionHeader>Explore</SectionHeader>
          <MenuList
            id="explore-menu"
            aria-labelledby="explore-item-div"
            onClick={() => {
              setMenuOpen(false);
            }}
          >
            <MenuItem onClick={() => history.push(`/explore/marker`)}>
              <Stack
                flexDirection="row"
                alignItems="center"
                padding="8px 0px 8px 0px"
              >
                <FmdGoodOutlinedIcon
                  style={{ width: "26px", height: "26px" }}
                />
                <span style={{ marginLeft: "4px" }}>Markers</span>
              </Stack>
            </MenuItem>
            <MenuItem onClick={() => history.push(`/explore/event`)}>
              <Stack
                flexDirection="row"
                alignItems="center"
                padding="8px 0px 8px 0px"
              >
                <GroupIcon style={{ width: "26px", height: "26px" }} />
                <span style={{ marginLeft: "4px" }}>Events</span>
              </Stack>
            </MenuItem>
          </MenuList>

          <Divider />

          <SectionHeader>Create</SectionHeader>
          <MenuList
            id="create-menu"
            onClick={() => {
              setMenuOpen(false);
            }}
          >
            <MenuItem onClick={() => history.push(`/create/marker`)}>
              <Stack
                flexDirection="row"
                alignItems="center"
                padding="8px 0px 8px 0px"
              >
                <AddLocationAltOutlinedIcon
                  style={{ width: "26px", height: "26px" }}
                />
                <span style={{ marginLeft: "4px" }}>Create Marker</span>
              </Stack>
            </MenuItem>
            <MenuItem onClick={() => history.push(`/create/event`)}>
              <Stack
                flexDirection="row"
                alignItems="center"
                padding="8px 0px 8px 0px"
              >
                <GroupAddIcon style={{ width: "26px", height: "26px" }} />
                <span style={{ marginLeft: "4px" }}>Create Event</span>
              </Stack>
            </MenuItem>
          </MenuList>

          <Divider />

          <SectionHeader
            style={{ paddingBottom: "16px" }}
            onClick={() => {
              history.push(`/market`);
              setMenuOpen(false);
            }}
          >
            Market
          </SectionHeader>

          <Divider />

          <Stack sx={{ paddingTop: "16px" }}>
            <AccountButton showDrawerSetter={setMenuOpen} />
          </Stack>
        </Stack>
      </Drawer>
    </Stack>
  );
}
