import { Button, Stack, Switch, TextField } from "@mui/material";
import axios from "axios";
import ConfirmTransactionDialog from "components/common/confirmTransactionDialog";
import ProcessingIcon from "components/common/processingIcon";
import SuccessDialog from "components/common/successDialog";
import LeafletMap from "components/leafletMap";
import { PINYWORLD_API_END_POINT } from "constant";
import { getPinyWorldEventAddress } from "constant/ContractAddress";
import eventabi from "contracts/abi/PinyWorldEvent.json";
import { getJwtToken } from "helpers/localStorage";
import { getChainIdByNetwork, getNetworkLogo } from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { runContractFunction } from "helpers/web3utils";
import { useCallback, useEffect, useState } from "react";
import { getChain, useMoralis } from "react-moralis";

export default function UpdateEventGeneralInfo({
  eventData,
  fetchedOnchainData,
  fetchedOnchainDataSetter,
}) {
  const { Moralis, chainId } = useMoralis();

  const [fetchingOnchainData, setFetchingOnchainData] = useState(false);

  const [eventEnabled, setEventEnabled] = useState(true);
  const [customMarkerEnabled, setCustomMarkerEnabled] = useState(true);
  const [hasChanged, setHasChanged] = useState(false);
  const [mapZoom, setMapZoom] = useState(3);
  const [mapCenter, setMapCenter] = useState();

  const [updatingEvent, setUpdatingEvent] = useState(false);
  const [showConfirmTransactionDialog, setShowConfirmTransactionDialog] =
    useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  const fetchEventOnlineData = useCallback(async () => {
    if (!eventData || fetchedOnchainData) {
      return;
    }

    setFetchingOnchainData(true);

    const eventChain = getChainIdByNetwork(eventData.network);

    try {
      const fetchEventEnabled = async () => {
        const options = {
          chain: eventChain,
          address: getPinyWorldEventAddress(eventChain),
          functionName: "isEventEnabled",
          abi: eventabi,
          params: { _eventId: eventData.eventId },
        };

        const isEventEnabled = await runContractFunction(options);

        eventData.enabled = isEventEnabled;
        setEventEnabled(isEventEnabled);
      };

      const fetchEventCustomMarkerEnabled = async () => {
        const options = {
          chain: eventChain,
          address: getPinyWorldEventAddress(eventChain),
          functionName: "isEventCustomMarkerEnabled",
          abi: eventabi,
          params: { _eventId: eventData.eventId },
        };

        const isCustomMarkerEnabled = await runContractFunction(options);

        eventData.customMarkerEnabled = isCustomMarkerEnabled;

        setCustomMarkerEnabled(isCustomMarkerEnabled);
      };

      await Promise.all([fetchEventEnabled(), fetchEventCustomMarkerEnabled()]);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
    } finally {
      setFetchingOnchainData(false);
      fetchedOnchainDataSetter(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  useEffect(() => {
    fetchEventOnlineData();
  }, [fetchEventOnlineData]);

  const onEventEnabledChange = (event) => {
    setEventEnabled(event.target.checked);
  };

  const onCustomMarkerEnabledChange = (event) => {
    setCustomMarkerEnabled(event.target.checked);
  };

  const updateEvent = async () => {
    if (
      !eventData ||
      !eventData.network ||
      getChainIdByNetwork(eventData.network) !== chainId
    ) {
      return;
    }

    setUpdatingEvent(true);
    setUpdateSuccessful(false);

    let needsUpdate = false;

    if (
      eventData.mapZoom !== mapZoom ||
      eventData.mapCenter.longitude !== mapCenter.longitude ||
      eventData.mapCenter.latitude !== mapCenter.latitude
    ) {
      needsUpdate = true;

      const eventMapUpdateParams = {
        network: eventData.network,
        eventId: eventData.eventId,
        zoom: mapZoom,
        center: {
          longitude: mapCenter.longitude,
          latitude: mapCenter.latitude,
        },
      };

      try {
        await axios.put(
          PINYWORLD_API_END_POINT + "event/update/map",
          eventMapUpdateParams,
          { headers: { Authorization: "Bearer " + getJwtToken() } }
        );

        eventData.mapZoom = mapZoom;
        eventData.mapCenter = {
          longitude: mapCenter.longitude,
          latitude: mapCenter.latitude,
        };
      } catch (err) {
        setUpdatingEvent(false);
        ToastUtils.toastUtil("error", err.message || err);
        return;
      }
    }

    if (
      eventData.enabled !== eventEnabled ||
      eventData.customMarkerEnabled !== customMarkerEnabled
    ) {
      needsUpdate = true;

      const options = {
        contractAddress: getPinyWorldEventAddress(chainId),
        functionName: "updateEvent",
        abi: eventabi,
        params: {
          _eventUpdateParams: [
            eventData.eventId,
            eventEnabled,
            customMarkerEnabled,
          ],
        },
      };

      setShowConfirmTransactionDialog(true);

      try {
        const transaction = await Moralis.executeFunction(options);
        await transaction.wait();

        eventData.enabled = eventEnabled;
        eventData.customMarkerEnabled = customMarkerEnabled;
      } catch (err) {
        setUpdatingEvent(false);
        ToastUtils.toastUtil("error", err.message || err);
        return;
      } finally {
        setShowConfirmTransactionDialog(false);
      }
    }

    setUpdatingEvent(false);

    if (needsUpdate) {
      setUpdateSuccessful(true);
    }
  };

  useEffect(() => {
    setEventEnabled(eventData.enabled);
    setCustomMarkerEnabled(eventData.customMarkerEnabled);
    setMapCenter({
      longitude: eventData.mapCenter.longitude,
      latitude: eventData.mapCenter.latitude,
    });
    setMapZoom(eventData.mapZoom);
  }, [eventData]);

  useEffect(() => {
    if (eventData.mapCenter && !mapCenter) {
      setHasChanged(false);
      return;
    }

    if (
      eventData.enabled !== eventEnabled ||
      eventData.customMarkerEnabled !== customMarkerEnabled ||
      eventData.mapCenter.longitude !== mapCenter.longitude ||
      eventData.mapCenter.latitude !== mapCenter.latitude ||
      eventData.mapZoom !== mapZoom
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [
    mapZoom,
    mapCenter,
    eventEnabled,
    customMarkerEnabled,
    eventData,
    updateSuccessful,
  ]);

  return (
    <Stack>
      <h3 className="formLabel">Name</h3>
      <TextField
        id="event-name-input"
        variant="outlined"
        value={eventData.name}
        disabled
      />

      <h3 className="formLabel">Description</h3>
      <TextField
        id="event-description-input"
        variant="outlined"
        multiline
        rows={4}
        value={eventData.description}
        disabled
      />

      <h3 className="formLabel">Maximum Pin Count</h3>
      <TextField
        id="event-max-pin-input"
        variant="outlined"
        value={eventData.maxMintCount}
        disabled
      />

      <h3 className="formLabel">Network</h3>
      <Stack direction="row" alignItems="center" sx={{ height: "24px" }}>
        <img
          src={getNetworkLogo(getChainIdByNetwork(eventData.network))}
          alt={getChain(getChainIdByNetwork(eventData.network)).name}
          style={{
            maxWidth: "32px",
            maxHeight: "32px",
            borderRadius: "50%",
          }}
        />
        <h4 style={{ marginLeft: "8px" }}>
          {getChain(getChainIdByNetwork(eventData.network)).name}
        </h4>
      </Stack>

      <h3 className="formLabel">Enabled</h3>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <span>
          People cannot pin markers if event is disabled. You can enable back
          later.
        </span>

        {fetchingOnchainData ? (
          <ProcessingIcon sx={{ color: "#9f9f9f" }} />
        ) : (
          <Switch
            checked={eventEnabled}
            onChange={onEventEnabledChange}
            inputProps={{ "aria-label": "event-enabled-switch" }}
          />
        )}
      </Stack>

      <h3 className="formLabel">Custom Marker Enabled</h3>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <span>Can attendees pin custom markers created by them?</span>

        {fetchingOnchainData ? (
          <ProcessingIcon sx={{ color: "#9f9f9f" }} />
        ) : (
          <Switch
            checked={customMarkerEnabled}
            onChange={onCustomMarkerEnabledChange}
            inputProps={{ "aria-label": "custom-marker-enabled-switch" }}
          />
        )}
      </Stack>

      <h3 className="formLabel">Only one pin per address</h3>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <span>Each address can pin only one marker for the event</span>

        <Switch
          checked={eventData.onlyOncePerAddress}
          inputProps={{ "aria-label": "event-one-pin-swtich" }}
          disabled
        />
      </Stack>

      <h3 className="formLabel">Map zoom and center</h3>
      <LeafletMap
        zoom={eventData.mapZoom}
        center={{
          lat: eventData.mapCenter.latitude,
          lng: eventData.mapCenter.longitude,
        }}
        onZoomEnd={(map) => {
          setMapZoom(map.getZoom());
          setMapCenter({
            longitude: map.getCenter().lng,
            latitude: map.getCenter().lat,
          });
        }}
        onDragEnd={(map) => {
          setMapCenter({
            longitude: map.getCenter().lng,
            latitude: map.getCenter().lat,
          });
        }}
        style={{
          borderRadius: "8px",
          height: `${window.innerWidth * (9 / 16)}px`,
          maxHeight: "600px",
          minHeight: "480px",
        }}
      />
      <span className="formInfo">
        Map will show up with this zoom level and center on event home page
      </span>

      <Stack flexDirection="row" justifyContent="center" marginTop="32px">
        <Button
          variant="outlined"
          disabled={!hasChanged || updatingEvent}
          endIcon={updatingEvent ? <ProcessingIcon /> : null}
          onClick={updateEvent}
        >
          Update
        </Button>
      </Stack>

      <ConfirmTransactionDialog showDialog={showConfirmTransactionDialog} />
      <SuccessDialog
        showDialog={updateSuccessful}
        message="Event successfully updated."
        onCloseDialog={() => {
          setUpdateSuccessful(false);
        }}
      />
    </Stack>
  );
}
