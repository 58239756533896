import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { PINYWORLD_API_END_POINT } from "constant";
import {
  getPinyWorldEventAddress,
  getPinyWorldMintListenerAddress,
} from "constant/ContractAddress";
import eventabi from "contracts/abi/PinyWorldEvent.json";
import mintlistenerabi from "contracts/abi/PinyWorldMintListener.json";
import { getEllipsisTxt, tokenValue } from "helpers/formatters";
import {
  getChainIdByNetwork,
  getNetworkDatabaseNameByCode,
  getNetworkLogo,
} from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { useCallback, useEffect, useState } from "react";
import Blockies from "react-blockies";
import { getChain, useMoralis } from "react-moralis";
import { useParams } from "react-router-dom";
import EventPinMarker from "./pin";
import EventPinHistory from "./pinHistory";
import { FacebookIcon, TwitterIcon } from "react-share";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import EventMarkers from "./eventMarkers";
import PageLoadingSpinner from "components/common/pageLoadingSpinner";
import LeafletMarkerMapWithFilter from "components/leafletMap/markerMapWithFilter";
import { SHARE_URL } from "constant";
import { runContractFunction } from "helpers/web3utils";
import { getTokenMetadata } from "helpers/tokens";

export default function EventHomePage() {
  const { Moralis, isAuthenticated, account } = useMoralis();

  const { networkId, eventId } = useParams();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const markerPerPage = isSmall ? 4 : 8;

  const [dataReady, setDataReady] = useState(false);
  const [eventNotFound, setEventNotFound] = useState(false);
  const [eventDataFromDb, setEventDataFromDb] = useState();
  const [eventMarkers, setEventMarkers] = useState();
  const [enabled, setEnabled] = useState(false);
  const [customMarkerEnabled, setCustomMarkerEnabled] = useState(false);
  const [canPinMarker, setCanPinMarker] = useState(true);
  const [pinnedOnlyOnce, setPinnedOnlyOnce] = useState(false);
  const [markerPaymentSettingList, setMarkerPaymentSettingList] =
    useState(null);
  const [eventPaymentSettingList, setEventPaymentSettingList] = useState(null);

  const [selectedMarker, setSelectedMarker] = useState("");
  const [pinMode, setPinMode] = useState(false);

  const eventChain = getChainIdByNetwork(networkId);

  const fetchEventPaymentSettingList = async () => {
    let options = {
      chain: eventChain,
      address: getPinyWorldEventAddress(eventChain),
      functionName: "getEventPaymentSettingList",
      abi: eventabi,
      params: { _eventId: Number.parseInt(eventId) },
    };
    // runContractFunction method return attribute array
    // [0] - tokenAddres
    // [1][0] - enabled
    // [1][1] - amount
    // [1][2] - reciever
    const paymentSettingList = await runContractFunction(options);

    const paymentSettingWithTokenInfoList = [];

    await Promise.all(
      paymentSettingList.map(async (ps) => {
        const tokenMetadata = await getTokenMetadata(
          Moralis,
          ps[0],
          eventChain
        );

        const transferAmount = tokenValue(ps[1][1], tokenMetadata.decimals);

        if (transferAmount > 0) {
          paymentSettingWithTokenInfoList.push({
            token: tokenMetadata,
            amount: transferAmount,
          });
        }
      })
    );

    paymentSettingWithTokenInfoList.sort((ps1, ps2) => {
      if (ps1 && ps1.token && ps1.token.symbol && ps2 && ps2.token) {
        return ps1.token.symbol.localeCompare(ps2.token.symbol);
      }

      return 0;
    });

    setEventPaymentSettingList(paymentSettingWithTokenInfoList);
  };

  const fetchMarkerPaymentSettingList = async () => {
    let options = {
      chain: eventChain,
      address: getPinyWorldEventAddress(eventChain),
      functionName: "isEventNoMintFee",
      abi: eventabi,
      params: { _eventId: eventId },
    };

    if (await runContractFunction(options)) {
      setMarkerPaymentSettingList([]);
      return;
    }

    options = {
      chain: eventChain,
      address: getPinyWorldMintListenerAddress(eventChain),
      functionName: "getPaymentSettingList",
      abi: mintlistenerabi,
    };

    const paymentSettingList = await runContractFunction(options);

    const paymentSettingWithTokenInfoList = [];

    await Promise.all(
      paymentSettingList.map(async (ps) => {
        const tokenMetadata = await getTokenMetadata(
          Moralis,
          ps[0],
          eventChain
        );

        const transferAmount = tokenValue(ps[1][1], tokenMetadata.decimals);

        if (transferAmount > 0) {
          paymentSettingWithTokenInfoList.push({
            token: tokenMetadata,
            amount: transferAmount,
          });
        }
      })
    );

    paymentSettingWithTokenInfoList.sort((ps1, ps2) => {
      if (ps1 && ps1.token && ps1.token.symbol && ps2 && ps2.token) {
        return ps1.token.symbol.localeCompare(ps2.token.symbol);
      }

      return 0;
    });

    setMarkerPaymentSettingList(paymentSettingWithTokenInfoList);
  };

  const fecthData = useCallback(async () => {
    if (!eventChain) {
      setDataReady(false);
      return;
    }
    
    if (
      eventDataFromDb &&
      eventMarkers &&
      markerPaymentSettingList &&
      eventPaymentSettingList
    ) {
      setDataReady(true);
      return;
    }

    setDataReady(false);

    try {
      const response = await axios.get(
        PINYWORLD_API_END_POINT +
          "event/" +
          getNetworkDatabaseNameByCode(networkId) +
          "/" +
          eventId
      );

      let options = {
        chain: eventChain,
        address: getPinyWorldEventAddress(eventChain),
        functionName: "isEventEnabled",
        abi: eventabi,
        params: { _eventId: eventId },
      };

      const enabled = await runContractFunction(options);

      setEnabled(enabled);

      if (!response.data.dto && !enabled) {
        setEventNotFound(true);
        return;
      }

      const eventData = response && response.data && response.data.dto;

      setEventDataFromDb(eventData);

      const fetchEventMarkers = async () => {
        const options = {
          chain: eventChain,
          address: getPinyWorldEventAddress(eventChain),
          functionName: "getEventMarkerImageURIList",
          abi: eventabi,
          params: { _eventId: eventId },
        };

        const markers = await runContractFunction(options);
        let eventMarkerObjList = [];

        if (markers && markers.length) {
          eventMarkerObjList = markers.map((marker) => {
            const eventMarkerObj = { imageURI: marker, mintCount: 0 };

            if (
              eventData &&
              eventData.markerImageList &&
              eventData.markerImageList.length
            ) {
              let eventDataMarkerObj = eventData.markerImageList.find(
                (markerObj) => markerObj.urlPath === marker
              );

              eventMarkerObj.mintCount =
                (eventDataMarkerObj && eventDataMarkerObj.mintCount) || 0;
            }

            return eventMarkerObj;
          });
        }

        setEventMarkers(eventMarkerObjList);
      };

      const checkCustomMarkerEnabled = async () => {
        const options = {
          chain: eventChain,
          address: getPinyWorldEventAddress(eventChain),
          functionName: "isEventCustomMarkerEnabled",
          abi: eventabi,
          params: { _eventId: eventId },
        };

        setCustomMarkerEnabled(await runContractFunction(options));
      };

      await Promise.all([
        fetchEventMarkers(),
        checkCustomMarkerEnabled(),
        fetchEventPaymentSettingList(),
        fetchMarkerPaymentSettingList(),
      ]);

      setDataReady(true);
    } catch (err) {
      setDataReady(false);
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, networkId]);

  const computeCanPinMarker = useCallback(async () => {
    if (!enabled) {
      setCanPinMarker(false);
      return;
    }

    if (!eventDataFromDb) {
      return;
    }

    if (!account || !isAuthenticated) {
      setCanPinMarker(false);
      return;
    }

    if (
      eventDataFromDb.maxMintCount > 0 &&
      eventDataFromDb.currentMintCount >= eventDataFromDb.maxMintCount
    ) {
      setCanPinMarker(false);
      return;
    }

    if (!eventDataFromDb.onlyOncePerAddress) {
      setCanPinMarker(true);
      return;
    }

    try {
      let options = {
        chain: eventChain,
        address: getPinyWorldEventAddress(eventChain),
        functionName: "hasAddressAttendedToEvent",
        abi: eventabi,
        params: { _eventId: eventId, _address: account },
      };

      const hasPinned = await runContractFunction(options);

      if (hasPinned) {
        setCanPinMarker(false);
        setPinnedOnlyOnce(true);
        return;
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
      return;
    }

    setCanPinMarker(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isAuthenticated, enabled, eventDataFromDb]);

  useEffect(() => {
    fecthData();
  }, [fecthData]);

  useEffect(() => {
    computeCanPinMarker();
  }, [computeCanPinMarker]);

  const onEventMarkerClick = (markerURI) => {
    setSelectedMarker(markerURI);
    setPinMode(true);
  };

  return (
    <Stack width="100%">
      {!dataReady ? (
        <Container
          maxWidth="md"
          sx={{
            backgroundColor: "white",
            paddingTop: "88px",
            paddingBottom: "48px",
          }}
        >
          <PageLoadingSpinner />
        </Container>
      ) : (
        <>
          <Container
            maxWidth="md"
            sx={{
              backgroundColor: "white",
              paddingTop: "32px",
              paddingBottom: pinMode ? "48px" : "",
            }}
          >
            <Stack
              flexDirection={isSmall ? "column" : "row"}
              width="100%"
              justifyContent={isSmall ? "" : "space-between"}
            >
              <Stack alignItems={isSmall ? "center" : "flex-start"}>
                <Typography
                  variant="h4"
                  textAlign={isSmall ? "center" : ""}
                  gutterBottom
                  sx={{ wordBreak: "break-all" }}
                >
                  {(eventDataFromDb && eventDataFromDb.name) || "Unnamed Event"}
                </Typography>
                <Typography
                  variant="body1"
                  textAlign={isSmall ? "center" : ""}
                  gutterBottom
                  sx={{ whiteSpace: "pre-line", wordBreak: "break-all" }}
                >
                  {(eventDataFromDb && eventDataFromDb.description) ||
                    "No description"}
                </Typography>
                <Stack
                  flexDirection="row"
                  mt="32px"
                  alignItems="center"
                  width="100%"
                  justifyContent="flex-start"
                >
                  <Typography variant="subtitle2">Share Event</Typography>
                  <Typography variant="body2" marginLeft="8px">
                    <TwitterShareButton
                      url={`${SHARE_URL}/event/${getNetworkDatabaseNameByCode(
                        networkId
                      )}/${eventId}`}
                      hashtags={["piny", "event"]}
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <FacebookShareButton
                      url={`${SHARE_URL}/event/${getNetworkDatabaseNameByCode(
                        networkId
                      )}/${eventId}`}
                      hashtag={"#piny"}
                      style={{ marginLeft: "8px" }}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                mt={isSmall ? "32px" : "16px"}
                ml={isSmall ? "0px" : "16px"}
                maxWidth={isSmall ? "100%" : "34%"}
              >
                <Stack flexDirection="column">
                  <Typography variant="subtitle2">Network</Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ height: "24px" }}
                  >
                    <img
                      src={getNetworkLogo(eventChain)}
                      alt={getChain(eventChain).name}
                      style={{
                        maxWidth: "24px",
                        maxHeight: "24px",
                        borderRadius: "50%",
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ marginLeft: "4px", whiteSpace: "nowrap" }}
                    >
                      {getChain(eventChain).name}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection="column" mt="8px">
                  <Typography variant="subtitle2">Creator</Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ height: "24px" }}
                  >
                    <Blockies
                      seed={eventDataFromDb.creator.toLowerCase()}
                      className="blockie"
                      size={6}
                    />
                    <Typography variant="body2" sx={{ marginLeft: "4px" }}>
                      {getEllipsisTxt(eventDataFromDb.creator, 8)}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection="column" mt="8px">
                  <Typography variant="subtitle2">Created at</Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ height: "24px" }}
                  >
                    <CalendarMonthIcon />
                    <Typography variant="body2" sx={{ marginLeft: "4px" }}>
                      {new Date(
                        eventDataFromDb.createTime
                      ).toLocaleDateString()}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              sx={{
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "8px",
                marginTop: isSmall ? "32px" : "16px",
                marginBottom: " 16px",
                overflow: "hidden",
              }}
              alignItems="center"
            >
              <Stack
                alignItems="flex-start"
                width="calc(100% - 16px)"
                padding="8px"
                sx={{ borderBottom: "1px solid", borderColor: "divider" }}
              >
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography variant="h5">Markers</Typography>

                  {eventMarkers && eventMarkers.length ? (
                    <Typography variant="body2">
                      Total {eventMarkers.length} marker
                    </Typography>
                  ) : null}
                </Stack>

                {canPinMarker ? (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    marginTop="8px"
                  >
                    {eventMarkers && eventMarkers.length ? (
                      <Typography variant="body2">
                        Select a marker to pin
                      </Typography>
                    ) : null}

                    {customMarkerEnabled && isAuthenticated && account ? (
                      <Link
                        component="button"
                        underline="none"
                        variant="subtitle2"
                        onClick={() => onEventMarkerClick("")}
                        sx={{
                          textTransform: "uppercase",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Create Your Own
                      </Link>
                    ) : null}
                  </Stack>
                ) : (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    marginTop="8px"
                  >
                    {!enabled ? (
                      <Typography variant="body2">
                        Event has been disabled, no more marker can be pinned.
                      </Typography>
                    ) : eventDataFromDb.maxMintCount > 0 &&
                      eventDataFromDb.currentMintCount ===
                        eventDataFromDb.maxMintCount ? (
                      <Typography variant="body2">
                        Maximum mint count has been reached, no more marker can
                        be pinned.
                      </Typography>
                    ) : pinnedOnlyOnce ? (
                      <Typography variant="body2">
                        You have already pinned marker for this event.
                      </Typography>
                    ) : !isAuthenticated || !account ? (
                      <Typography variant="body2">
                        Connect your wallet to pin marker
                      </Typography>
                    ) : null}
                  </Stack>
                )}
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                  marginTop: "16px",
                  marginBottom:
                    eventMarkers && eventMarkers.length > markerPerPage
                      ? "8px"
                      : "16px",
                }}
              >
                <EventMarkers
                  eventMarkers={eventMarkers}
                  markerPerPage={markerPerPage}
                  canPinMarker={canPinMarker}
                  onMarkerClick={onEventMarkerClick}
                  selectedMarkerUri={selectedMarker}
                />
              </Stack>
            </Stack>

            {pinMode ? (
              <EventPinMarker
                eventId={eventId}
                network={networkId}
                eventDataFromDb={eventDataFromDb}
                pinModeSetter={setPinMode}
                selectedMarker={selectedMarker}
                selectedMarkerSetter={setSelectedMarker}
                markerPaymentSettingList={markerPaymentSettingList}
                eventPaymentSettingList={eventPaymentSettingList}
              />
            ) : null}
          </Container>

          {!pinMode ? (
            <Container maxWidth="md" sx={{ backgroundColor: "white" }}>
              <Stack alignItems="center" width="100%">
                <LeafletMarkerMapWithFilter
                  textSearch
                  searchFields={{
                    name: true,
                    message: true,
                    minter: true,
                    owner: true,
                    collection: true,
                  }}
                  defaultCriteria={{
                    eventIdList: [eventId],
                    networkList: [getNetworkDatabaseNameByCode(networkId)],
                  }}
                  initialZoom={eventDataFromDb.mapZoom}
                  center={{
                    lat: eventDataFromDb.mapCenter.latitude,
                    lng: eventDataFromDb.mapCenter.longitude,
                  }}
                  style={{
                    borderRadius: "8px",
                    height: `${window.innerWidth * (9 / 16)}px`,
                    maxHeight: "600px",
                    minHeight: "480px",
                  }}
                ></LeafletMarkerMapWithFilter>
              </Stack>
            </Container>
          ) : null}

          {!pinMode ? (
            <Container
              maxWidth="md"
              sx={{
                backgroundColor: "white",
                paddingTop: "16px",
                paddingBottom: "48px",
              }}
            >
              <Stack>
                <Stack
                  flexDirection="row"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack
                    flexDirection="column"
                    alignItems="center"
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "8px",
                    }}
                    padding="8px"
                  >
                    <Typography variant="subtitle1">Total Pinned</Typography>
                    <Typography variant="h5">
                      {eventDataFromDb && eventDataFromDb.currentMintCount}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="column"
                    alignItems="center"
                    ml="16px"
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: "8px",
                    }}
                    padding="8px"
                  >
                    <Typography variant="subtitle1">Maximum Pin</Typography>
                    <Typography variant="h5">
                      {eventDataFromDb
                        ? eventDataFromDb.maxMintCount === 0
                          ? "Unlimited"
                          : eventDataFromDb.maxMintCount
                        : null}
                    </Typography>
                  </Stack>
                </Stack>

                <EventPinHistory
                  eventId={eventId}
                  network={networkId}
                  eventDataFromDb={eventDataFromDb}
                />
              </Stack>
            </Container>
          ) : null}
        </>
      )}

      <Dialog open={eventNotFound} disableScrollLock>
        <DialogTitle sx={{ textAlign: "center" }}>Event Not Found</DialogTitle>
        <DialogContent>
          <Stack>
            <Stack
              flexDirection="row"
              justifyContent="center"
              marginTop="16px"
              marginBottom="32px"
            >
              <WarningAmberOutlinedIcon
                color="warning"
                sx={{ width: "80px", height: "80px" }}
              />
            </Stack>

            <DialogContentText>
              Network: {getChain(getChainIdByNetwork(networkId)).name}
            </DialogContentText>

            <DialogContentText sx={{ marginTop: "8px" }}>
              Event Id: {eventId}
            </DialogContentText>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
}
