import { Container, Stack } from "@mui/material";
import LeafletMarkerMapWithFilter from "components/leafletMap/markerMapWithFilter";
import ExploreMarkerPinHistory from "./pinHistory";

export default function ExploreMarkerPage() {
  return (
    <Stack width="100%">
      <Container maxWidth="md" sx={{ backgroundColor: "white" }}>
        <Stack>
          <h1>Explore The World</h1>
        </Stack>
      </Container>

      <Container maxWidth="md" sx={{ backgroundColor: "white" }}>
        <LeafletMarkerMapWithFilter
          textSearch
          searchFields={{
            name: true,
            message: true,
            minter: true,
            owner: true,
            event: true,
            collection: true,
          }}
          networkFilter
          userLocation
          style={{
            borderRadius: "8px",
            height: `${window.innerWidth * (9 / 16)}px`,
            maxHeight: "600px",
            minHeight: "480px",
          }}
        ></LeafletMarkerMapWithFilter>
      </Container>

      <Container
        maxWidth="md"
        sx={{
          backgroundColor: "white",
          paddingTop: "16px",
          paddingBottom: "48px",
        }}
      >
        <ExploreMarkerPinHistory />
      </Container>
    </Stack>
  );
}
