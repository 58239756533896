import React, { useCallback, useEffect, useState } from "react";
import L from "leaflet";
import { Marker } from "react-leaflet";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MarkerInfoDialog from "components/googleMap/markerInfoDialog";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ProcessingIcon from "components/common/processingIcon";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { PINYWORLD_API_END_POINT } from "constant";
import axios from "axios";
import ToastUtils from "helpers/toaster";
import { isBlank } from "helpers/utils";
import LeafletMap from ".";

export default function LeafletMarkerMapWithFilter({
  textSearch,
  searchFields,
  networkFilter,
  defaultCriteria,
  userLocation,
  initialZoom,
  center,
  style,
}) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [zoom, setZoom] = useState(
    initialZoom || initialZoom === 0 ? initialZoom : 4
  );

  const [criteria, setCriteria] = useState();
  const [queryMarkerByAreaTimeout, setQueryMarkerByAreaTimeout] = useState();
  const [markerDataList, setMarkerDataList] = useState();
  const [markerList, setMarkerList] = useState();
  const [previousMarkerList, setPreviousMarkerList] = useState();

  const [mapBoundsChanged, setMapsBoundChanged] = useState(false);
  const [fetchingMarkers, setFetchingMarkers] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageInput, setCurrentPageInput] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalPage, setTotalPage] = useState(0);

  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showSearchFields, setShowSearchFields] = useState(false);
  const [searchFieldsChecked, setSearchFieldsChecked] = useState();
  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [filterShowPagingDetails, setFilterShowPagingDetails] = useState(false);

  const [showPagingDetails, setShowPagingDetails] = useState(false);
  const [hasFilter, setHasFilter] = useState(false);

  const [clickedMarkerData, setClickedMarkerData] = useState();

  const networks = {
    Avax: "Avalanche",
    BSC: "BSC",
    ETH: "Ethereum",
    Polygon: "Polygon",
  };

  const defaultSearchFieldsChecked = {
    name: true,
    message: true,
    minter: true,
    owner: true,
    event: true,
    collection: true,
  };

  const fetchMarkers = () => {
    if (!criteria) {
      return;
    }

    setFetchingMarkers(true);

    axios
      .post(PINYWORLD_API_END_POINT + "marker/query/criteria", {
        criteria: {
          ...criteria,
          pageSize: pageSize,
          pageNumber: currentPage - 1,
          sortRequestList: [{ sortField: "createTime", sortDirection: "DESC" }],
        },
      })
      .then((response) => {
        const markers = [];
        const previousMarkers = [];
        const markerIdSet = new Set();

        for (let i = 0; i < response.data.length; i++) {
          markers.push(response.data[i]);
          markerIdSet.add(response.data[i].id);
          previousMarkers.push(response.data[i]);
        }

        if (
          criteria.mapBoundsChanged &&
          previousMarkerList &&
          previousMarkerList.length
        ) {
          for (let i = 0; i < previousMarkerList.length; i++) {
            if (
              !markerIdSet.has(previousMarkerList[i].id) &&
              previousMarkerList[i].coordinate.latitude <=
                criteria.topRight.latitude &&
              previousMarkerList[i].coordinate.longitude <=
                criteria.topRight.longitude &&
              previousMarkerList[i].coordinate.latitude >=
                criteria.bottomLeft.latitude &&
              previousMarkerList[i].coordinate.longitude >=
                criteria.bottomLeft.longitude
            ) {
              markers.push(previousMarkerList[i]);
            }
          }
        }

        criteria.mapBoundsChanged = false;

        setMarkerDataList(markers);
        setPreviousMarkerList(previousMarkers);
      })
      .finally(() => {
        setFetchingMarkers(false);
        setMapsBoundChanged(false);
      });
  };

  const fetchMarkersByCriteria = useCallback(async () => {
    if (!criteria) {
      return;
    }

    try {
      const countResponse = await axios.post(
        PINYWORLD_API_END_POINT + "marker/query/criteria/count",
        {
          criteria: criteria,
        }
      );

      setTotalPage(
        countResponse.data
          ? countResponse.data % pageSize === 0
            ? countResponse.data / pageSize
            : parseInt(countResponse.data / pageSize) + 1
          : 1
      );

      if (currentPage === 1) {
        fetchMarkers();
      }

      setCurrentPage(1);
      setCurrentPageInput(1);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria]);

  useEffect(() => {
    fetchMarkersByCriteria();
  }, [fetchMarkersByCriteria]);

  const onMapBoundsChanged = (map) => {
    if (!map || !map.getBounds() || !map.getBounds().getNorthEast()) {
      return;
    }

    setZoom(map.getZoom());
    setMapsBoundChanged(true);

    if (queryMarkerByAreaTimeout) {
      clearTimeout(queryMarkerByAreaTimeout);
    }

    setQueryMarkerByAreaTimeout(
      setTimeout(() => {
        setCriteria({
          ...criteria,
          ...defaultCriteria,
          pageSize: pageSize,
          searchFields: searchFieldsChecked,
          topRight: {
            longitude: map.getBounds().getNorthEast().lng,
            latitude: map.getBounds().getNorthEast().lat,
          },
          bottomLeft: {
            longitude: map.getBounds().getSouthWest().lng,
            latitude: map.getBounds().getSouthWest().lat,
          },
          mapBoundsChanged: true,
        });
      }, 500)
    );
  };

  useEffect(() => {
    if (!markerDataList || !markerDataList.length) {
      setMarkerList([]);
      return;
    }

    const markers = [];

    let calcIconSize;

    if (!zoom || zoom * 16 < 48) {
      calcIconSize = 48;
    } else if (zoom * 16 > 160) {
      calcIconSize = 160;
    } else {
      calcIconSize = zoom * 16;
    }

    for (let i = 0; i < markerDataList.length; i++) {
      markers.push(
        <Marker
          key={markerDataList[i].id}
          icon={
            new L.Icon({
              iconUrl: `https://gateway.moralisipfs.com/ipfs/${markerDataList[i].imageUrl}`,
              iconSize: [calcIconSize, calcIconSize],
              iconAnchor: [calcIconSize / 2, calcIconSize],
            })
          }
          position={{
            lng: markerDataList[i].coordinate.longitude,
            lat: markerDataList[i].coordinate.latitude,
          }}
          eventHandlers={{
            click() {
              onMarkerClick(markerDataList[i]);
            },
          }}
        />
      );
    }

    setMarkerList(markers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerDataList]);

  useEffect(() => {
    fetchMarkers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const onMarkerClick = (markerData) => {
    setClickedMarkerData(markerData);
  };

  const onFilterClick = () => {
    setSearchText((criteria && criteria.searchText) || "");
    setSearchFieldsChecked(
      (criteria && criteria.searchFields) ||
        searchFields ||
        defaultSearchFieldsChecked
    );
    setSelectedNetworks((criteria && criteria.networkList) || []);

    setFilterShowPagingDetails(showPagingDetails);
    setPageSize(pageSize);

    setShowFilterDialog(true);
  };

  const onPreviousPageClick = () => {
    if (currentPage <= 1) {
      return;
    }

    let newCurrentPage = currentPage - 1;

    setCurrentPage(newCurrentPage);
    setCurrentPageInput(newCurrentPage);
  };

  const onNextPageClick = () => {
    if (currentPage >= totalPage) {
      return;
    }

    let newCurrentPage = currentPage + 1;

    setCurrentPage(newCurrentPage);
    setCurrentPageInput(newCurrentPage);
  };

  const onCurrentPageInputChange = (event) => {
    const page = parseInt(event.target.value);

    if (page && page > totalPage) {
      setCurrentPageInput(totalPage);
    } else if (page && page < 1) {
      setCurrentPageInput(1);
    } else {
      setCurrentPageInput(event.target.value);
    }
  };

  const onGoToPageClick = () => {
    const page = parseInt(currentPageInput);

    if (page && page > totalPage) {
      setCurrentPage(totalPage);
    } else if (page && page < 1) {
      setCurrentPage(1);
    } else if (!page) {
      setCurrentPage(1);
      setCurrentPageInput(1);
    } else {
      setCurrentPage(page);
      setCurrentPageInput(page);
    }
  };

  const onSearchTextChange = (event) => {
    if (!isBlank(event.target.value)) {
      setSearchText(event.target.value);
    } else {
      setSearchText("");
    }
  };

  const onSelectedNetworksChange = (event) => {
    setSelectedNetworks(event.target.value);
  };

  const toggleSearchFields = () => {
    setShowSearchFields(!showSearchFields);
  };

  const onSeachFieldCheckedChange = (field, checked) => {
    const tempSearchFieldsChecked = { ...searchFieldsChecked };
    tempSearchFieldsChecked[field] = checked;

    setSearchFieldsChecked(tempSearchFieldsChecked);
  };

  const onFilterOkClick = () => {
    let networkFiltered = false;

    if (!(selectedNetworks.length === 0 || selectedNetworks.length === 4)) {
      networkFiltered = true;
    }

    let countPerPageSelected = false;

    if (pageSize !== 50) {
      countPerPageSelected = true;
    }

    let showPagingDetailsChanged = false;

    if (filterShowPagingDetails) {
      showPagingDetailsChanged = true;
    }

    if (
      networkFiltered ||
      countPerPageSelected ||
      showPagingDetailsChanged ||
      !isBlank(searchText)
    ) {
      setHasFilter(true);
    } else {
      setHasFilter(false);
    }

    let searchTextChanged = false;

    if (searchText !== criteria.searchText) {
      searchTextChanged = true;
    }

    let searchFieldsChanged = false;

    if (
      (!isBlank(searchText) || searchTextChanged) &&
      criteria.searchFields &&
      (criteria.searchFields.name !== searchFieldsChecked.name ||
        criteria.searchFields.message !== searchFieldsChecked.message ||
        criteria.searchFields.minter !== searchFieldsChecked.minter ||
        criteria.searchFields.owner !== searchFieldsChecked.owner ||
        criteria.searchFields.event !== searchFieldsChecked.event ||
        criteria.searchFields.collection !== searchFieldsChecked.collection)
    ) {
      searchFieldsChanged = true;
    }

    let selectedNetworksChanged = false;

    if (
      ((!criteria || !criteria.networkList) && selectedNetworks.length) ||
      (criteria.networkList &&
        criteria.networkList.length !== selectedNetworks.length)
    ) {
      selectedNetworksChanged = true;
    } else if (
      criteria.networkList &&
      criteria.networkList.length &&
      selectedNetworks.length
    ) {
      for (let i = 0; i < criteria.networkList.length; i++) {
        let notInBoth = true;

        for (let j = 0; j < criteria.networkList.length; j++) {
          if (criteria.networkList[i] === selectedNetworks[j]) {
            notInBoth = false;
            break;
          }

          if (notInBoth) {
            selectedNetworksChanged = true;
            break;
          }
        }
      }
    }

    let pageSizeChanged = false;

    if (criteria.pageSize !== pageSize) {
      pageSizeChanged = true;
    }

    if (
      selectedNetworksChanged ||
      searchTextChanged ||
      searchFieldsChanged ||
      pageSizeChanged
    ) {
      setCriteria({
        ...criteria,
        ...defaultCriteria,
        pageSize,
        searchText,
        searchFields: searchFieldsChecked,
        networkList: selectedNetworks,
      });

      setPreviousMarkerList([]);
    }

    setShowPagingDetails(filterShowPagingDetails);

    setShowFilterDialog(false);
  };

  useEffect(() => {
    if (!searchFields) {
      setSearchFieldsChecked(defaultSearchFieldsChecked);
      return;
    }

    setSearchFieldsChecked({
      name: searchFields.name,
      message: searchFields.message,
      minter: searchFields.minter,
      owner: searchFields.owner,
      event: searchFields.event,
      collection: searchFields.collection,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFields]);

  return (
    <Stack width="100%">
      <LeafletMap
        userLocation={userLocation}
        zoom={zoom}
        center={center}
        whenReady={onMapBoundsChanged}
        onDragEnd={onMapBoundsChanged}
        onZoomEnd={onMapBoundsChanged}
        style={style}
      >
        {markerList}
      </LeafletMap>
      <Stack width="100%" alignItems={isSmall ? "left" : "center"}>
        <Stack
          flexDirection="row"
          alignItems="center"
          padding="4px"
          maxWidth={`${Math.min(window.innerWidth - 80, 336)}px`}
          width="fit-content"
          mt="-82px"
          mb="24px"
          backgroundColor="white"
          border="2px solid rgba(0,0,0,0.2)"
          borderRadius="4px"
          zIndex="1"
          ml={isSmall ? "8px" : ""}
          sx={{ backgroundClip: "padding-box" }}
        >
          <IconButton
            disabled={fetchingMarkers}
            onClick={onFilterClick}
            color={hasFilter ? "primary" : "default"}
          >
            {fetchingMarkers ? <ProcessingIcon /> : <FilterAltOutlinedIcon />}
          </IconButton>
          <IconButton
            ml="8px"
            onClick={onPreviousPageClick}
            disabled={currentPage <= 1 || mapBoundsChanged}
          >
            <ArrowBackIosNewOutlinedIcon />
          </IconButton>
          <IconButton
            ml="8px"
            onClick={onNextPageClick}
            disabled={currentPage >= totalPage || mapBoundsChanged}
          >
            <ArrowForwardIosOutlinedIcon />
          </IconButton>

          {showPagingDetails ? (
            totalPage > 4 ? (
              <Stack flexDirection="row" alignItems="center" ml="8px">
                <OutlinedInput
                  id="current-page-input"
                  variant="outlined"
                  type="tel"
                  value={currentPageInput}
                  onChange={onCurrentPageInputChange}
                  inputProps={{ style: { textAlign: "right" } }}
                  size="small"
                  sx={{
                    width: `${75 + parseInt(Math.log10(totalPage)) * 15}px`,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      / {totalPage}
                    </InputAdornment>
                  }
                />

                <IconButton
                  sx={{ marginLeft: "4px" }}
                  onClick={onGoToPageClick}
                >
                  <ArrowUpwardOutlinedIcon />
                </IconButton>
              </Stack>
            ) : (
              <Stack ml="8px" pr="8px">
                <Typography variant="body1">
                  {currentPage} / {totalPage}
                </Typography>
              </Stack>
            )
          ) : null}
        </Stack>
      </Stack>

      <Dialog
        open={showFilterDialog}
        sx={{
          "& .MuiPaper-root": {
            margin: isSmall ? "8px" : "",
            width: isSmall ? "100%" : "auto",
          },
        }}
      >
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <h3 style={{ margin: "0px" }}>Filter Markers</h3>
            </Stack>
            <IconButton
              onClick={() => {
                setShowFilterDialog(false);
              }}
              style={{ marginRight: "-16px", marginTop: "-16px" }}
            >
              <HighlightOffIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: isSmall ? "0px 16px 24px 16px" : "",
          }}
        >
          <Stack width={isSmall ? "100%" : "450px"}>
            {textSearch ? (
              <Stack>
                <TextField
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 64 }}
                  value={searchText}
                  onChange={onSearchTextChange}
                  placeholder="Search in marker data"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "12px",
                      paddingBottom: "12px",
                    },
                  }}
                />

                {searchFields ? (
                  <Stack
                    sx={{
                      marginTop: "8px",
                      padding: "12px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "4px",
                    }}
                  >
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ cursor: "pointer" }}
                      onClick={toggleSearchFields}
                    >
                      <Typography variant="body2">Search in fields</Typography>
                      {showSearchFields ? (
                        <KeyboardArrowUpOutlinedIcon />
                      ) : (
                        <KeyboardArrowDownOutlinedIcon />
                      )}
                    </Stack>
                    {showSearchFields ? (
                      <Stack>
                        {searchFields.name ? (
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mt="8px"
                          >
                            <Typography variant="subtitle2">
                              Marker Name
                            </Typography>
                            <Switch
                              checked={searchFieldsChecked.name}
                              onChange={(event) =>
                                onSeachFieldCheckedChange(
                                  "name",
                                  event.target.checked
                                )
                              }
                            />
                          </Stack>
                        ) : null}

                        {searchFields.message ? (
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mt="8px"
                          >
                            <Typography variant="subtitle2">
                              Marker Message
                            </Typography>
                            <Switch
                              checked={searchFieldsChecked.message}
                              onChange={(event) =>
                                onSeachFieldCheckedChange(
                                  "message",
                                  event.target.checked
                                )
                              }
                            />
                          </Stack>
                        ) : null}

                        {searchFields.minter ? (
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mt="8px"
                          >
                            <Typography variant="subtitle2">
                              Marker Minter
                            </Typography>
                            <Switch
                              checked={searchFieldsChecked.minter}
                              onChange={(event) =>
                                onSeachFieldCheckedChange(
                                  "minter",
                                  event.target.checked
                                )
                              }
                            />
                          </Stack>
                        ) : null}

                        {searchFields.owner ? (
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mt="8px"
                          >
                            <Typography variant="subtitle2">
                              Marker Owner
                            </Typography>
                            <Switch
                              checked={searchFieldsChecked.owner}
                              onChange={(event) =>
                                onSeachFieldCheckedChange(
                                  "owner",
                                  event.target.checked
                                )
                              }
                            />
                          </Stack>
                        ) : null}

                        {searchFields.event ? (
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mt="8px"
                          >
                            <Typography variant="subtitle2">
                              Marker Event
                            </Typography>
                            <Switch
                              checked={searchFieldsChecked.event}
                              onChange={(event) =>
                                onSeachFieldCheckedChange(
                                  "event",
                                  event.target.checked
                                )
                              }
                            />
                          </Stack>
                        ) : null}

                        {searchFields.collection ? (
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mt="8px"
                          >
                            <Typography variant="subtitle2">
                              Marker Collection
                            </Typography>
                            <Switch
                              checked={searchFieldsChecked.collection}
                              onChange={(event) =>
                                onSeachFieldCheckedChange(
                                  "collection",
                                  event.target.checked
                                )
                              }
                            />
                          </Stack>
                        ) : null}
                      </Stack>
                    ) : null}
                  </Stack>
                ) : null}
              </Stack>
            ) : null}

            {networkFilter ? (
              <Select
                displayEmpty
                multiple
                value={selectedNetworks}
                onChange={onSelectedNetworksChange}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Typography
                        sx={{ color: "currentColor", opacity: "0.42" }}
                      >
                        Filter by network
                      </Typography>
                    );
                  }
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={networks[value]}
                          sx={{
                            "& .MuiChip-root": {
                              height: "23px",
                            },
                          }}
                        />
                      ))}
                    </Box>
                  );
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <FilterAltOutlinedIcon />
                  </InputAdornment>
                }
                sx={{
                  marginTop: "8px",
                  "& .MuiSelect-select": {
                    paddingTop: "12px",
                    paddingBottom: "12px",
                  },
                }}
              >
                <MenuItem key="avax" value="Avax">
                  <Checkbox
                    checked={
                      selectedNetworks.findIndex(
                        (network) => network === "Avax"
                      ) > -1
                    }
                  />
                  <ListItemText primary="Avalanche" />
                </MenuItem>
                <MenuItem key="bsc" value="BSC">
                  <Checkbox
                    checked={
                      selectedNetworks.findIndex(
                        (network) => network === "BSC"
                      ) > -1
                    }
                  />
                  <ListItemText primary="BSC" />
                </MenuItem>
                <MenuItem key="eth" value="ETH">
                  <Checkbox
                    checked={
                      selectedNetworks.findIndex(
                        (network) => network === "ETH"
                      ) > -1
                    }
                  />
                  <ListItemText primary="Ethereum" />
                </MenuItem>
                <MenuItem key="polygon" value="Polygon">
                  <Checkbox
                    checked={
                      selectedNetworks.findIndex(
                        (network) => network === "Polygon"
                      ) > -1
                    }
                  />
                  <ListItemText primary="Polygon" />
                </MenuItem>
              </Select>
            ) : null}

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mt="16px"
            >
              <Typography variant="subtitle2">Show paging details</Typography>
              <Switch
                checked={filterShowPagingDetails}
                onChange={(event) =>
                  setFilterShowPagingDetails(event.target.checked)
                }
              />
            </Stack>

            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mt="8px"
            >
              <Typography variant="subtitle2">Marker count per page</Typography>
              <Select
                value={pageSize}
                onChange={(event) => setPageSize(event.target.value)}
                size="small"
                sx={{ marginLeft: "24px", fontSize: "16px" }}
              >
                <MenuItem key={1} value={25}>
                  25
                </MenuItem>
                <MenuItem key={2} value={50}>
                  50
                </MenuItem>
                <MenuItem key={3} value={100}>
                  100
                </MenuItem>
                <MenuItem key={4} value={200}>
                  200
                </MenuItem>
              </Select>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="outlined" onClick={onFilterOkClick}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <MarkerInfoDialog
        markerData={clickedMarkerData}
        markerDataSetter={setClickedMarkerData}
      />
    </Stack>
  );
}
