import DoneIcon from "@mui/icons-material/Done";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WhereToVoteOutlinedIcon from "@mui/icons-material/WhereToVoteOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ConnectWalletButton from "components/common/connectWalletButton";
import InvalidNetworkDialog from "components/common/invalidNetworkDialog";
import ProcessingIcon from "components/common/processingIcon";
import LeafletMap from "components/leafletMap";
import MarkerEditor from "components/markerEditor";
import { DEFAULT_MARKER_IMAGE_DATA } from "components/markerEditor/defaultImages";
import {
  getPinyWorldEventAddress,
  getPinyWorldEventListenerAddress,
  getPinyWorldMintListenerAddress,
} from "constant/ContractAddress";
import { freeMintTokenPaymentMethod } from "constant/paymentSettingTokens";
import { freeMintToken } from "constant/paymentSettingTokens";
import erc20abi from "contracts/abi/ERC20.json";
import eventabi from "contracts/abi/PinyWorldEvent.json";
import mintlistenerabi from "contracts/abi/PinyWorldMintListener.json";
import { getCoinMetadata } from "helpers/coins";
import {
  getEllipsisTxt,
  tokenValue,
  toTokenValue,
  ZERO_ADDRESS,
} from "helpers/formatters";
import { addMarkerToLocalStorage } from "helpers/localStorage";
import {
  getChainIdByNetwork,
  getNetworkLogo,
  networkConfigs,
} from "helpers/networks";
import ToastUtils from "helpers/toaster";
import { computePaymentData } from "helpers/tokens";
import { useCallback, useEffect, useRef, useState } from "react";
import Blockies from "react-blockies";
import { getChain, useMoralis } from "react-moralis";
import { Link } from "react-router-dom";
import L from "leaflet";
import { Marker } from "react-leaflet";

export default function EventPinMarker({
  eventId,
  network,
  eventDataFromDb,
  pinModeSetter,
  selectedMarker,
  selectedMarkerSetter,
  markerPaymentSettingList,
  eventPaymentSettingList,
}) {
  const { Moralis, isWeb3Enabled, chainId, isAuthenticated, account } =
    useMoralis();

  const mapRef = useRef();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isVerySmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const eventChain = getChainIdByNetwork(network);

  const [markerName, setMarkerName] = useState("");
  const [markerDescription, setMarkerDescription] = useState("");
  const [markerImage, setMarkerImage] = useState(DEFAULT_MARKER_IMAGE_DATA);
  const [mapMarker, setMapMarker] = useState();
  const [showMarkerEditor, setShowMarkerEditor] = useState(false);
  const [mapZoom, setMapZoom] = useState(3);
  const [markerPosition, setMarkerPosition] = useState();
  const [markerDragging, setMarkerDragging] = useState();

  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [selectedEventPaymentIndex, setSelectedEventPaymentIndex] = useState(0);
  const [eventPaymentMethod, setEventPaymentMethod] = useState(null);

  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [checkingPaymentApproval, setCheckingPaymentApproval] = useState(false);
  const [paymentApproved, setPaymentApproved] = useState(false);
  const [accountFreeMintCount, setAccountFreeMintCount] = useState(0);

  const [approvingAmount, setApprovingAmount] = useState("");
  const [approvingSymbol, setApprovingSymbol] = useState("");
  const [approvingPayment, setApprovingPayment] = useState(false);
  const [showApprovingPaymentDialog, setShowApprovingPaymentDialog] =
    useState(false);

  const [showSubmitMarkerDialog, setShowSubmitMarkerDialog] = useState(false);

  const [submitMarkerConfirmed, setSubmitMarkerConfirmed] = useState(false);
  const [uploadingImageToIPFS, setUploadingImageToIPFS] = useState(false);
  const [submittingMarker, setSubmittingMarker] = useState(false);
  const [createdMarker, setCreatedMarker] = useState(false);
  const [redirectPageAfterCreate, setRedirectPageAfterCreate] = useState("");

  const computeTotalPayment = () => {
    if (
      paymentMethod &&
      eventPaymentMethod &&
      paymentMethod.token.address === eventPaymentMethod.token.address
    ) {
      return (
        paymentMethod.amount +
        eventPaymentMethod.amount +
        " " +
        paymentMethod.token.symbol
      );
    }

    let total = "";
    if (paymentMethod) {
      total += paymentMethod.amount + " " + paymentMethod.token.symbol;
    }

    if (eventPaymentMethod) {
      total +=
        total &&
        " + " +
          eventPaymentMethod.amount +
          " " +
          eventPaymentMethod.token.symbol;
    }

    return total;
  };

  const computeMarkerScaledSize = useCallback(() => {
    if (!mapZoom || mapZoom * 16 < 48) {
      return 48;
    }

    if (mapZoom * 16 > 160) {
      return 160;
    }

    return mapZoom * 16;
  }, [mapZoom]);

  const checkPaymentApproval = useCallback(async () => {
    if (
      (!paymentMethod && !eventPaymentMethod) ||
      !isAuthenticated ||
      !account ||
      eventChain !== chainId
    ) {
      setCheckingPaymentApproval(false);
      return;
    }

    setCheckingPaymentApproval(true);
    setPaymentApproved(false);
    setInsufficientBalance(false);

    let mintPaymentApproved = false,
      insufficientMintBalance = false,
      eventPaymentApproved = false,
      insufficientEventBalance = false;

    if (paymentMethod) {
      if (paymentMethod.token.address === freeMintToken.address) {
        mintPaymentApproved = true;
      } else if (paymentMethod.token.address !== ZERO_ADDRESS) {
        let options = {
          contractAddress: paymentMethod.token.address,
          functionName: "allowance",
          abi: erc20abi,
          params: {
            owner: account,
            spender: getPinyWorldMintListenerAddress(eventChain),
          },
        };

        const allowance = tokenValue(
          await Moralis.executeFunction(options),
          paymentMethod.token.decimals
        );

        if (allowance >= paymentMethod.amount) {
          mintPaymentApproved = true;
        }

        options = {
          contractAddress: paymentMethod.token.address,
          functionName: "balanceOf",
          abi: erc20abi,
          params: {
            account: account,
          },
        };

        const balance = tokenValue(
          await Moralis.executeFunction(options),
          paymentMethod.token.decimals
        );

        if (balance < paymentMethod.amount) {
          insufficientMintBalance = true;
        }
      } else if (paymentMethod.token.address === ZERO_ADDRESS) {
        mintPaymentApproved = true;

        const options = { chain: chainId };
        const balance = tokenValue(
          (await Moralis.Web3API.account.getNativeBalance(options))["balance"],
          paymentMethod.token.decimals
        );

        if (balance < paymentMethod.amount) {
          insufficientMintBalance = true;
        }
      }
    }

    if (eventPaymentMethod) {
      if (eventPaymentMethod.token.address !== ZERO_ADDRESS) {
        let options = {
          contractAddress: eventPaymentMethod.token.address,
          functionName: "allowance",
          abi: erc20abi,
          params: {
            owner: account,
            spender: getPinyWorldEventListenerAddress(eventChain),
          },
        };

        const allowance = tokenValue(
          await Moralis.executeFunction(options),
          eventPaymentMethod.token.decimals
        );

        if (allowance >= eventPaymentMethod.amount) {
          eventPaymentApproved = true;
        }

        options = {
          contractAddress: eventPaymentMethod.token.address,
          functionName: "balanceOf",
          abi: erc20abi,
          params: {
            account: account,
          },
        };

        const balance = tokenValue(
          await Moralis.executeFunction(options),
          eventPaymentMethod.token.decimals
        );

        if (balance < eventPaymentMethod.amount) {
          insufficientEventBalance = true;
        }
      } else if (eventPaymentMethod.token.address === ZERO_ADDRESS) {
        eventPaymentApproved = true;

        const options = { chain: chainId };
        const balance = tokenValue(
          (await Moralis.Web3API.account.getNativeBalance(options))["balance"],
          eventPaymentMethod.token.decimals
        );

        if (balance < eventPaymentMethod.amount) {
          insufficientEventBalance = true;
        }
      }
    }

    if (
      (paymentMethod && !mintPaymentApproved) ||
      (eventPaymentMethod && !eventPaymentApproved)
    ) {
      setPaymentApproved(false);
    } else {
      setPaymentApproved(true);
    }

    if (
      (paymentMethod && insufficientMintBalance) ||
      (eventPaymentMethod && insufficientEventBalance)
    ) {
      setInsufficientBalance(true);
    } else {
      setInsufficientBalance(false);
    }

    setCheckingPaymentApproval(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, account, paymentMethod, eventPaymentMethod]);

  const fetchAccountFreeMintCount = useCallback(async () => {
    if (!account) {
      if (accountFreeMintCount > 0) {
        setAccountFreeMintCount(0);
      }

      return;
    }

    let options = {
      contractAddress: getPinyWorldMintListenerAddress(chainId),
      functionName: "noMintFeeMap",
      abi: mintlistenerabi,
      params: {
        _address: account,
      },
    };

    setAccountFreeMintCount(await Moralis.executeFunction(options));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    checkPaymentApproval();
  }, [checkPaymentApproval]);

  useEffect(() => {
    fetchAccountFreeMintCount();
  }, [fetchAccountFreeMintCount]);

  const onMarkerNameChange = (event) => {
    setMarkerName(event.target.value);
  };

  const onMarkerDescriptionChange = (event) => {
    setMarkerDescription(event.target.value);
  };

  const onMapClick = (event) => {
    if (!markerDragging) {
      setMarkerPosition({ lat: event.latlng.lat, lng: event.latlng.lng });
    }
  };

  const onPaymentMethodChange = (event) => {
    if (event.target.value === "0x1") {
      setSelectedPaymentIndex(-1);
      return;
    }

    if (
      !markerPaymentSettingList ||
      event.target.value > markerPaymentSettingList.length
    ) {
      return;
    }

    setSelectedPaymentIndex(parseInt(event.target.value));
  };

  const onEventPaymentMethodChange = (event) => {
    if (
      !eventPaymentSettingList ||
      event.target.value > eventPaymentSettingList.length
    ) {
      return;
    }

    setSelectedEventPaymentIndex(parseInt(event.target.value));
  };

  const onSubmitButtonClicked = () => {
    setShowSubmitMarkerDialog(true);
  };

  const cancelPinMarker = () => {
    pinModeSetter(false);
    selectedMarkerSetter("");
    setMarkerName("");
    setMarkerDescription("");
    setMarkerImage("");
    setMarkerPosition(null);
    setMapZoom(eventDataFromDb ? eventDataFromDb.mapZoom : 3);
  };

  const approvePaymentMethod = async () => {
    if (
      (!paymentMethod || !paymentMethod.token) &&
      (!eventPaymentMethod || !eventPaymentMethod.token)
    ) {
      return;
    }

    setApprovingPayment(true);
    setShowApprovingPaymentDialog(true);

    let mintPaymentApproved = true,
      eventPaymentApproved = true;

    if (
      paymentMethod &&
      paymentMethod.token.address !== ZERO_ADDRESS &&
      paymentMethod.token.address !== freeMintToken.address
    ) {
      let options = {
        contractAddress: paymentMethod.token.address,
        functionName: "allowance",
        abi: erc20abi,
        params: {
          owner: account,
          spender: getPinyWorldMintListenerAddress(eventChain),
        },
      };

      const allowance = tokenValue(
        await Moralis.executeFunction(options),
        paymentMethod.token.decimals
      );

      if (allowance < paymentMethod.amount) {
        let amount = paymentMethod.amount.toString();

        if (!/^\d+(\.\d+)?$/.test(amount)) {
          amount = paymentMethod.amount
            .toFixed(paymentMethod.token.decimals)
            .replace(/(\.0+|0+)$/, "");
        }

        setApprovingAmount(amount);
        setApprovingSymbol(paymentMethod.token.symbol);

        options = {
          contractAddress: paymentMethod.token.address,
          functionName: "approve",
          abi: erc20abi,
          params: {
            spender: getPinyWorldMintListenerAddress(chainId),
            amount: toTokenValue(
              paymentMethod.amount,
              paymentMethod.token.decimals
            ),
          },
        };

        try {
          const transaction = await Moralis.executeFunction(options);
          await transaction.wait();
        } catch (err) {
          mintPaymentApproved = false;
          if (err && err.code !== 4001) {
            ToastUtils.toastUtil("error", JSON.stringify(err));
          }
          setApprovingPayment(false);
          setShowApprovingPaymentDialog(false);
          return;
        }
      }
    }

    if (
      eventPaymentMethod &&
      eventPaymentMethod.token.address !== ZERO_ADDRESS
    ) {
      let options = {
        contractAddress: eventPaymentMethod.token.address,
        functionName: "allowance",
        abi: erc20abi,
        params: {
          owner: account,
          spender: getPinyWorldEventListenerAddress(eventChain),
        },
      };

      const allowance = tokenValue(
        await Moralis.executeFunction(options),
        eventPaymentMethod.token.decimals
      );

      if (allowance < eventPaymentMethod.amount) {
        let amount = eventPaymentMethod.amount.toString();

        if (!/^\d+(\.\d+)?$/.test(amount)) {
          amount = eventPaymentMethod.amount
            .toFixed(eventPaymentMethod.token.decimals)
            .replace(/(\.0+|0+)$/, "");
        }

        setApprovingAmount(amount);
        setApprovingSymbol(eventPaymentMethod.token.symbol);

        const options = {
          contractAddress: eventPaymentMethod.token.address,
          functionName: "approve",
          abi: erc20abi,
          params: {
            spender: getPinyWorldEventListenerAddress(chainId),
            amount: toTokenValue(
              eventPaymentMethod.amount,
              eventPaymentMethod.token.decimals
            ),
          },
        };

        try {
          const transaction = await Moralis.executeFunction(options);
          await transaction.wait();
        } catch (err) {
          eventPaymentApproved = false;
          if (err && err.code !== 4001) {
            ToastUtils.toastUtil("error", JSON.stringify(err));
          }
          setApprovingPayment(false);
          setShowApprovingPaymentDialog(false);
          return;
        }
      }
    }

    if (
      (paymentMethod && !mintPaymentApproved) ||
      (eventPaymentMethod && !eventPaymentApproved)
    ) {
      setPaymentApproved(false);
    } else {
      setPaymentApproved(true);
    }

    setApprovingPayment(false);
    setShowApprovingPaymentDialog(false);
  };

  const submitCreateMarker = async () => {
    if (
      !markerPaymentSettingList ||
      (markerPaymentSettingList.length > 0 && !paymentMethod) ||
      !eventPaymentSettingList ||
      (eventPaymentSettingList.length > 0 && !eventPaymentMethod)
    ) {
      return;
    }

    let imageHash = selectedMarker;

    setSubmitMarkerConfirmed(true);
    setUploadingImageToIPFS(true);

    if (!selectedMarker) {
      try {
        const imageFile = new Moralis.File("image.png", {
          base64: markerImage,
        });

        let path = await Moralis.Web3API.storage.uploadFolder({
          abi: [
            {
              path: "image.png",
              content: imageFile._data,
            },
          ],
        });

        // Get IPFS path from https://ipfs.moralis.io:2053/ipfs/QmRGnnNvpMD54ieTh96s7ECk9wrANkZ4H6hYGpHzEaHbHh/image.png
        imageHash = path[0].path.substring(34);
      } catch (err) {
        ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
        setSubmittingMarker(false);
        setSubmitMarkerConfirmed(false);
        return;
      }
    }

    const metadata = {
      name: markerName,
      description: markerDescription,
      image: "ipfs://" + imageHash,
      attributes: [
        { trait_type: "Latitude", value: mapMarker.props.position.lat },
        { trait_type: "Longitude", value: mapMarker.props.position.lng },
        { trait_type: "Map Zoom", value: mapZoom },
        { trait_type: "Event ID", value: eventId },
        {
          trait_type: "Event Name",
          value: eventDataFromDb && eventDataFromDb.name,
        },
      ],
    };

    let metadataFile;

    try {
      const path = await Moralis.Web3API.storage.uploadFolder({
        abi: [
          {
            path: "metadata.json",
            content: metadata,
          },
        ],
      });

      metadataFile = path[0].path.substring(34);
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || JSON.stringify(err));
      setSubmittingMarker(false);
      setSubmitMarkerConfirmed(false);
      return;
    } finally {
      setUploadingImageToIPFS(false);
    }

    const options = {
      contractAddress: getPinyWorldEventAddress(chainId),
      functionName: "mintMarker",
      abi: eventabi,
      params: {
        _eventId: eventId,
        _markerProps: [
          markerName,
          markerDescription,
          "" + markerPosition.lat,
          "" + markerPosition.lng,
          mapZoom,
          imageHash,
        ],
        _tokenURI: metadataFile,
        _mintPaymentToken: paymentMethod
          ? paymentMethod.token.address
          : ZERO_ADDRESS,
        _eventPaymentToken: eventPaymentMethod
          ? eventPaymentMethod.token.address
          : ZERO_ADDRESS,
      },
    };

    let msgValue = 0;

    if (paymentMethod && paymentMethod.token.address === ZERO_ADDRESS) {
      msgValue += paymentMethod.amount;
    }

    if (
      eventPaymentMethod &&
      eventPaymentMethod.token.address === ZERO_ADDRESS
    ) {
      msgValue += eventPaymentMethod.amount;
    }

    if (msgValue > 0) {
      options["msgValue"] = toTokenValue(
        msgValue,
        getCoinMetadata(eventChain).decimals
      );
    }

    setSubmittingMarker(true);

    try {
      const transaction = await Moralis.executeFunction(options);
      const result = await transaction.wait();

      setCreatedMarker(true);
      setRedirectPageAfterCreate(`/marker`);

      const mintEvent = result.events.find(
        (e) => e.event === "MintEventMarker"
      );

      if (
        mintEvent &&
        mintEvent.args &&
        mintEvent.args.tokenId &&
        mintEvent.args.tokenId.toString
      ) {
        const markerId = parseInt(mintEvent.args.tokenId.toString());
        const eventTokenId = mintEvent.args.eventTokenId
          ? parseInt(mintEvent.args.eventTokenId.toString())
          : 0;

        addMarkerToLocalStorage(networkConfigs[eventChain].code, markerId, {
          ...metadata,
          eventId: eventId,
          eventName: eventDataFromDb && eventDataFromDb.name,
          eventTokenId: eventTokenId,
          minter: account,
          imageUrl: imageHash,
          tokenURI: metadataFile,
          mintPaymentData: computePaymentData(paymentMethod, chainId),
          eventPaymentData: computePaymentData(eventPaymentMethod, chainId),
          createTime: new Date().getTime(),
          transactionHash: mintEvent.transactionHash,
        });

        setRedirectPageAfterCreate(
          `/marker/${networkConfigs[eventChain].code}/${markerId}`
        );
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
      return;
    } finally {
      setSubmitMarkerConfirmed(false);
      setSubmittingMarker(false);
    }
  };

  useEffect(() => {
    if (!markerPosition || !markerImage) {
      setMapMarker(undefined);
      return;
    }

    const calcIconSize = computeMarkerScaledSize();

    setMapMarker(
      <Marker
        key="1"
        icon={
          new L.Icon({
            iconUrl: selectedMarker
              ? `https://gateway.moralisipfs.com/ipfs/${selectedMarker}`
              : markerImage,
            iconSize: [calcIconSize, calcIconSize],
            iconAnchor: [calcIconSize / 2, calcIconSize],
          })
        }
        position={markerPosition}
        eventHandlers={{
          dragstart() {
            setMarkerDragging(true);
          },
          dragend(event) {
            setMarkerPosition({
              lat: event.target.getLatLng().lat,
              lng: event.target.getLatLng().lng,
            });

            setTimeout(() => {
              setMarkerDragging(false);
            }, 100);
          },
        }}
        draggable
      />
    );
  }, [selectedMarker, markerImage, markerPosition, computeMarkerScaledSize]);

  useEffect(() => {
    let markerCoord;

    if (mapMarker && mapMarker.props && mapMarker.props.position) {
      markerCoord = mapMarker.props.position;
    } else if (mapRef.current) {
      markerCoord = {
        lng: mapRef.current.getCenter().lng(),
        lat: mapRef.current.getCenter().lat(),
      };
    } else if (eventDataFromDb && eventDataFromDb.mapCenter) {
      markerCoord = {
        lat: eventDataFromDb.mapCenter.latitude,
        lng: eventDataFromDb.mapCenter.longitude,
      };
    }

    setMarkerPosition(markerCoord);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarker]);

  useEffect(() => {
    if (selectedPaymentIndex === -1) {
      setPaymentMethod(freeMintTokenPaymentMethod);
    } else if (
      !markerPaymentSettingList ||
      !markerPaymentSettingList.length ||
      selectedPaymentIndex >= markerPaymentSettingList.length
    ) {
      setPaymentMethod(null);
    } else {
      setPaymentMethod(markerPaymentSettingList[selectedPaymentIndex]);
    }
  }, [markerPaymentSettingList, selectedPaymentIndex]);

  useEffect(() => {
    if (
      !eventPaymentSettingList ||
      !eventPaymentSettingList.length ||
      selectedEventPaymentIndex >= eventPaymentSettingList.length
    ) {
      setEventPaymentMethod(null);
    } else {
      setEventPaymentMethod(eventPaymentSettingList[selectedEventPaymentIndex]);
    }
  }, [eventPaymentSettingList, selectedEventPaymentIndex]);

  useEffect(() => {
    setMapZoom(eventDataFromDb.mapZoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      <h2 style={{ marginBottom: "8px" }}>Pin Marker</h2>

      <h3 className="formLabel" style={{ marginTop: "8px" }}>
        Name
      </h3>
      <TextField
        id="marker-name-input"
        variant="outlined"
        inputProps={{ maxLength: 64 }}
        value={markerName}
        onChange={onMarkerNameChange}
      />
      <span className="formInfo">Maximum 64 characters.</span>

      <h3 className="formLabel">Message</h3>
      <TextField
        id="marker-message-input"
        variant="outlined"
        multiline
        rows={4}
        inputProps={{ maxLength: 1024 }}
        value={markerDescription}
        onChange={onMarkerDescriptionChange}
      />
      <span className="formInfo">Maximum 1024 characters.</span>

      <h3 className="formLabel">Marker Image</h3>
      <Stack flexDirection={isSmall ? "column" : "row"} alignItems="center">
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          width="336px"
          height="336px"
          border="2px gray dashed"
          padding="8px"
          borderRadius="8px"
        >
          <img
            src={
              selectedMarker
                ? `https://gateway.moralisipfs.com/ipfs/${selectedMarker}`
                : markerImage
            }
            alt="Marker"
            style={{ maxWidth: "336px", maxHeight: "336px" }}
          />
        </Stack>

        {!selectedMarker ? (
          <Button
            variant="outlined"
            startIcon={<EditLocationOutlinedIcon />}
            style={{
              marginLeft: isSmall ? "" : "16px",
              marginTop: isSmall ? "16px" : "",
            }}
            onClick={() => {
              setShowMarkerEditor(true);
            }}
          >
            Edit
          </Button>
        ) : null}
      </Stack>

      <h3
        className="formLabel"
        style={{ marginBottom: "0px", lineHeight: "1.5" }}
      >
        Location
      </h3>
      <span
        className="formInfo"
        style={{ marginTop: "0px", marginBottom: "4px" }}
      >
        Click on the map to change location
      </span>

      <LeafletMap
        zoom={mapZoom}
        center={{
          lat: eventDataFromDb.mapCenter.latitude,
          lng: eventDataFromDb.mapCenter.longitude,
        }}
        onClick={onMapClick}
        onZoomEnd={(map) => {
          setMapZoom(map.getZoom());
        }}
        style={{ borderRadius: "8px", height: "480px" }}
      >
        {mapMarker}
      </LeafletMap>

      {isAuthenticated && account ? (
        <>
          <h3 className="formLabel">Pinned by</h3>
          <Stack direction="row" alignItems="center">
            <Blockies seed={account.toLowerCase()} className="blockie" />
            <h4 style={{ margin: "0 0 0 8px " }}>
              {isVerySmall ? getEllipsisTxt(account, 15) : account}
            </h4>
          </Stack>
        </>
      ) : null}

      {markerPaymentSettingList && markerPaymentSettingList.length ? (
        <>
          <h3 className="formLabel">Pin Payment</h3>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            defaultValue={0}
            onChange={onPaymentMethodChange}
          >
            {markerPaymentSettingList.map((ps, index) => {
              let amount = ps.amount.toString();

              if (!/^\d+(\.\d+)?$/.test(amount)) {
                amount = ps.amount
                  .toFixed(ps.token.decimals)
                  .replace(/(\.0+|0+)$/, "");
              }

              return (
                <FormControlLabel
                  key={index}
                  value={index}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 28,
                        },
                      }}
                    />
                  }
                  label={`${amount} ${ps.token.symbol}`}
                  sx={{ marginTop: index === 0 ? "-8px" : "" }}
                />
              );
            })}
            {markerPaymentSettingList &&
            markerPaymentSettingList.length &&
            accountFreeMintCount > 0 ? (
              <FormControlLabel
                key="0x1"
                value="0x1"
                control={
                  <Radio
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                      },
                    }}
                  />
                }
                label={`Free Mint (${accountFreeMintCount} left)`}
              />
            ) : null}
          </RadioGroup>
        </>
      ) : null}

      {eventPaymentSettingList && eventPaymentSettingList.length ? (
        <>
          <h3 className="formLabel">Event Payment</h3>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            defaultValue={0}
            onChange={onEventPaymentMethodChange}
          >
            {eventPaymentSettingList.map((ps, index) => {
              let amount = ps.amount.toString();

              if (!/^\d+(\.\d+)?$/.test(amount)) {
                amount = ps.amount
                  .toFixed(ps.token.decimals)
                  .replace(/(\.0+|0+)$/, "");
              }

              return (
                <FormControlLabel
                  key={index}
                  value={index}
                  control={
                    <Radio
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 28,
                        },
                      }}
                    />
                  }
                  label={`${amount} ${ps.token.symbol}`}
                  sx={{ marginTop: index === 0 ? "-8px" : "" }}
                />
              );
            })}
          </RadioGroup>
        </>
      ) : null}

      <Stack
        flexDirection="row"
        mt="32px"
        justifyContent="center"
        alignItems="center"
      >
        {!isAuthenticated ? (
          <ConnectWalletButton />
        ) : (
          <Stack
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="outlined"
              onClick={cancelPinMarker}
              sx={{ padding: isVerySmall ? "4px 8px 4px 8px" : "" }}
            >
              Cancel
            </Button>

            {paymentMethod || eventPaymentMethod ? (
              <Button
                variant="outlined"
                color={
                  insufficientBalance
                    ? "error"
                    : paymentApproved
                    ? "success"
                    : "primary"
                }
                onClick={approvePaymentMethod}
                disabled={
                  checkingPaymentApproval ||
                  approvingPayment ||
                  insufficientBalance ||
                  paymentApproved
                }
                endIcon={
                  checkingPaymentApproval || approvingPayment ? (
                    <ProcessingIcon />
                  ) : paymentApproved && !insufficientBalance ? (
                    <DoneIcon />
                  ) : null
                }
                sx={{
                  marginLeft: "8px",
                  padding: isVerySmall ? "4px 8px 4px 8px" : "",
                }}
              >
                {checkingPaymentApproval
                  ? "Checking"
                  : approvingPayment
                  ? "Processing"
                  : insufficientBalance
                  ? "Insufficient Balance"
                  : paymentApproved
                  ? "Approved"
                  : "Approve Payment"}
              </Button>
            ) : null}

            <Button
              variant="outlined"
              onClick={onSubmitButtonClicked}
              disabled={
                checkingPaymentApproval ||
                approvingPayment ||
                ((paymentMethod || eventPaymentMethod) && !paymentApproved) ||
                insufficientBalance ||
                submitMarkerConfirmed ||
                createdMarker ||
                !isWeb3Enabled
              }
              sx={{
                marginLeft: "8px",
                padding: isVerySmall ? "4px 8px 4px 8px" : "",
              }}
              endIcon={submitMarkerConfirmed ? <ProcessingIcon /> : null}
            >
              Submit
            </Button>
          </Stack>
        )}
      </Stack>

      <MarkerEditor
        showDialog={showMarkerEditor}
        onDialogClose={() => {
          setShowMarkerEditor(false);
        }}
        imageSetter={setMarkerImage}
      />

      <InvalidNetworkDialog
        validChainId={eventChain}
        cancelAction={cancelPinMarker}
      />

      <Dialog open={showApprovingPaymentDialog} disableScrollLock>
        <DialogTitle>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <h3 style={{ margin: "0px" }}>Approve Payment</h3>
            </Stack>
            <IconButton
              onClick={() => {
                setShowApprovingPaymentDialog(false);
              }}
              style={{ marginRight: "-16px", marginTop: "-16px" }}
            >
              <HighlightOffIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack flexDirection="column" alignItems="center">
            <h2>
              {approvingAmount} {approvingSymbol}
            </h2>
            <Stack direction="row" alignItems="center" sx={{ height: "24px" }}>
              <img
                src={getNetworkLogo(eventChain)}
                alt={getChain(eventChain).name}
                style={{
                  maxWidth: "24px",
                  maxHeight: "24px",
                  borderRadius: "50%",
                }}
              />
              <span style={{ marginLeft: "8px" }}>
                {getChain(eventChain).name}
              </span>
            </Stack>
          </Stack>
          <DialogContentText sx={{ paddingTop: "40px", paddingBottom: "8px" }}>
            Please confirm transaction on your wallet
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {!createdMarker ? (
        <Dialog open={showSubmitMarkerDialog} disableScrollLock>
          <DialogTitle>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <h3 style={{ margin: "0px" }}>Confirm</h3>
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack flexDirection="column" alignItems="center">
              <Typography textAlign="center">
                Please be aware that you cannot change
              </Typography>
              <Typography variant="h6" textAlign="center">
                Name
              </Typography>
              <Typography variant="h6" textAlign="center">
                Message
              </Typography>
              <Typography variant="h6" textAlign="center">
                Marker image
              </Typography>
              <Typography variant="h6" textAlign="center">
                Location on the map
              </Typography>

              {paymentMethod &&
              paymentMethod.token.address !== freeMintToken.address ? (
                <>
                  <Typography textAlign="center">and</Typography>
                  <Typography textAlign="center">you will pay</Typography>
                  <Typography
                    variant="h5"
                    textAlign="center"
                    sx={{ margin: "8px" }}
                  >
                    {computeTotalPayment()}
                  </Typography>
                </>
              ) : null}

              <Typography textAlign="center">
                after confirming transaction on your wallet
              </Typography>
              <Typography
                textAlign="center"
                style={{
                  marginTop: "16px",
                  marginBottom: "4px",
                  textAlign: "center",
                }}
              >
                Marker is being created on
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ height: "24px" }}
              >
                <img
                  src={getNetworkLogo(eventChain)}
                  alt={getChain(eventChain).name}
                  style={{
                    maxWidth: "24px",
                    maxHeight: "24px",
                    borderRadius: "50%",
                  }}
                />
                <span style={{ marginLeft: "8px" }}>
                  {getChain(eventChain).name}
                </span>
              </Stack>
            </Stack>
            {uploadingImageToIPFS || submittingMarker ? (
              <DialogContentText
                sx={{
                  paddingTop: "40px",
                  paddingBottom: "8px",
                  textAlign: "center",
                }}
              >
                {uploadingImageToIPFS
                  ? "Uploading image to IPFS..."
                  : submittingMarker
                  ? "Please confirm transaction on your wallet"
                  : null}
              </DialogContentText>
            ) : null}
          </DialogContent>
          <DialogActions sx={{ padding: "16px" }}>
            <Button
              variant="outlined"
              color="error"
              disabled={submitMarkerConfirmed}
              onClick={() => {
                setShowSubmitMarkerDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              disabled={submitMarkerConfirmed}
              endIcon={submitMarkerConfirmed ? <ProcessingIcon /> : null}
              onClick={submitCreateMarker}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={createdMarker} disableScrollLock>
          <DialogTitle sx={{ textAlign: "center" }}>Marker Created</DialogTitle>
          <DialogContent>
            <Stack flexDirection="column" alignItems="center">
              <WhereToVoteOutlinedIcon
                sx={{
                  height: "120px",
                  width: "120px",
                  color: "green",
                }}
              />
            </Stack>
            <DialogContentText sx={{ marginTop: "24px", textAlign: "center" }}>
              Congratulations!
            </DialogContentText>
            <DialogContentText sx={{ marginTop: "16px", textAlign: "center" }}>
              Your marker is successfully created and pinned.
            </DialogContentText>
            <DialogContentText sx={{ marginTop: "16px", textAlign: "center" }}>
              Click OK to go to your marker's page.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: "16px", justifyContent: "center" }}>
            <Link to={redirectPageAfterCreate} className="buttonLink">
              <Button variant="outlined" onClick={() => {}}>
                OK
              </Button>
            </Link>
          </DialogActions>
        </Dialog>
      )}
    </Stack>
  );
}
