import {
  Button,
  Slider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ColorPicker from "../colorPicker";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import DropIcon from "../icons/drop";
import SquareMarkerIcon from "../icons/square";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

export default function EditMarker({
  background,
  onBackgroundChange,
  onBackgroundUploadImageChange,
  customBackground,
  opacity,
  onOpacityChange,
  color,
  onColorChange,
  border,
  onBorderChange,
  borderColor,
  onBorderColorChange,
}) {
  const handleUploadBackgroundUploadImage = (event) => {
    onBackgroundUploadImageChange(event.target.files[0]);
    document.getElementById("background-image-upload").value = null;
  };

  const handleBackgroundChange = (e, value) => {
    if (value === "0" || value) {
      onBackgroundChange(value);
    }
  };

  return (
    <Stack flexDirection="column">
      <Stack flexDirection="row" alignItems="center">
        <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>Background:</h5>
        <ToggleButtonGroup
          color="primary"
          value={background}
          exclusive
          onChange={handleBackgroundChange}
          size="small"
          sx={{ marginLeft: "4px" }}
        >
          <ToggleButton value="0">
            <DropIcon style={{ width: "20px", height: "20px" }} />
          </ToggleButton>
          <ToggleButton value="1">
            <SquareMarkerIcon />
          </ToggleButton>
          <ToggleButton value="2">
            <FavoriteBorderOutlinedIcon />
          </ToggleButton>
          {customBackground ? (
            <ToggleButton value="3">
              <ImageOutlinedIcon />
            </ToggleButton>
          ) : null}
        </ToggleButtonGroup>

        <Button
          startIcon={<AddPhotoAlternateOutlinedIcon />}
          variant="outlined"
          aria-label="upload image"
          component="label"
          sx={{
            marginLeft: "16px",
            padding: "8px 4px 8px 8px",
            minWidth: "40px",
            ".MuiButton-startIcon": { marginRight: "0px" },
          }}
        >
          <input
            hidden
            accept="image/*"
            type="file"
            id="background-image-upload"
            onChange={handleUploadBackgroundUploadImage}
          />
        </Button>
      </Stack>

      <Stack
        flexDirection="row"
        justifyContent="space-between"
        marginTop="16px"
      >
        <Stack width="50%" flexDirection="row" alignItems="center">
          <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>Color:</h5>
          <div style={{ marginLeft: "4px" }}>
            <ColorPicker color={color} onChange={onColorChange} />
          </div>
        </Stack>
        <Stack
          width="50%"
          flexDirection="row"
          alignItems="center"
          paddingLeft="8px"
        >
          <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>Opacity:</h5>
          <Slider
            sx={{ marginLeft: "12px", marginRight: "16px" }}
            onChange={(e) => onOpacityChange(e.target.value)}
            value={opacity}
          ></Slider>
        </Stack>
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        marginTop="16px"
      >
        <Stack width="50%" flexDirection="row" alignItems="center">
          <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>Border:</h5>
          <Slider
            sx={{ marginLeft: "12px", marginRight: "16px" }}
            onChange={(e) => onBorderChange(e.target.value)}
            value={border}
          ></Slider>
        </Stack>
        <Stack
          width="50%"
          flexDirection="row"
          alignItems="center"
          paddingLeft="8px"
        >
          <h5 style={{ marginTop: "4px", marginBottom: "4px" }}>
            Border Color:
          </h5>
          <div style={{ marginLeft: "4px" }}>
            <ColorPicker color={borderColor} onChange={onBorderColorChange} />
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
}
