import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ConnectWalletButton from "components/common/connectWalletButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PageLoadingSpinner from "components/common/pageLoadingSpinner";
import ProcessingIcon from "components/common/processingIcon";
import { PINYWORLD_API_END_POINT } from "constant";
import { getJwtToken, hasJwtToken } from "helpers/localStorage";
import ToastUtils from "helpers/toaster";
import { useCallback, useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";
import { isBlank } from "helpers/utils";

export default function MyMarkersPage() {
  const {
    isWeb3Enabled,
    isWeb3EnableLoading,
    isAuthenticated,
    account,
    isInitialized,
  } = useMoralis();

  const history = useHistory();

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [fetchingData, setFetchingData] = useState(true);
  const [hasEmptyCollection, setHasEmptyCollection] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [collections, setCollections] = useState([]);
  const [collectionToEdit, setCollectionToEdit] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  const [nameValidationMessage, setNameValidationMessage] = useState("");

  const fetchHasEmptyCollection = async () => {
    const response = await axios.post(
      PINYWORLD_API_END_POINT + "collection/query/criteria/count",
      { criteria: { owner: account, empty: true } }
    );

    if (response.data > 0) {
      setHasEmptyCollection(true);
    } else {
      setHasEmptyCollection(false);
    }
  };

  const fetchCollections = useCallback(async () => {
    if (!isInitialized || (isWeb3EnableLoading && isAuthenticated)) {
      setFetchingData(true);
      return;
    }

    if (!isWeb3Enabled && !account && !isAuthenticated) {
      setCollections(null);
      setFetchingData(false);
      return;
    }

    if (!isWeb3Enabled || !account || !isAuthenticated) {
      return;
    }

    if (!hasJwtToken()) {
      setFetchingData(false);
      setCollections([]);

      return;
    }

    setFetchingData(true);

    try {
      let response = await axios.post(
        PINYWORLD_API_END_POINT + "collection/query/criteria",
        {
          criteria: {
            owner: account,
            markerSliceSize: 3,
            sortRequestList: [
              { sortField: "createTime", sortDirection: "DESC" },
            ],
          },
        }
      );

      if (response && response.data) {
        setCollections(response.data);
      } else {
        setCollections([]);
      }

      fetchHasEmptyCollection();
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
    } finally {
      setFetchingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isAuthenticated, isWeb3EnableLoading, hasJwtToken]);

  useEffect(() => {
    fetchCollections();
  }, [fetchCollections]);

  const cancelEdit = () => {
    setShowDeleteDialog(false);
    setShowEditDialog(false);
    setCollectionToEdit({ name: "", description: "" });
    setEditIndex(-1);
  };

  const createCollection = async () => {
    if (!isNameValid()) {
      return;
    }

    try {
      setProcessing(true);

      const header = {
        Authorization: "Bearer " + getJwtToken(),
      };

      const response = await axios.post(
        PINYWORLD_API_END_POINT + "collection/create",
        collectionToEdit,
        { headers: header }
      );

      if (response.data.resultCode === "000000") {
        ToastUtils.toastUtil(
          "success",
          collectionToEdit.name + " successfully created."
        );

        setCollections([response.data.collection, ...collections]);
        setShowEditDialog(false);
        setCollectionToEdit({ name: "", description: "" });

        fetchHasEmptyCollection();
      } else {
        ToastUtils.toastUtil("error", response.data.resultMessage);
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
    } finally {
      setProcessing(false);
    }
  };

  const updateCollection = async () => {
    if (!isNameValid()) {
      return;
    }

    try {
      setProcessing(true);

      const header = {
        Authorization: "Bearer " + getJwtToken(),
      };

      const response = await axios.put(
        PINYWORLD_API_END_POINT + "collection/update/" + collectionToEdit.id,
        collectionToEdit,
        { headers: header }
      );

      if (response.data.resultCode === "000000") {
        ToastUtils.toastUtil("success", "Collection succesfully updated.");

        if (editIndex >= 0) {
          collections[editIndex].name = collectionToEdit.name;
          collections[editIndex].description = collectionToEdit.description;
        }

        setCollectionToEdit({ name: "", description: "" });
        setShowEditDialog(false);
      } else {
        ToastUtils.toastUtil("error", response.data.resultMessage);
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
    } finally {
      setProcessing(false);
    }
  };

  const deleteCollection = async () => {
    try {
      setProcessing(true);

      const header = {
        Authorization: "Bearer " + getJwtToken(),
      };

      const response = await axios.delete(
        PINYWORLD_API_END_POINT +
          "collection/" +
          collectionToEdit.id +
          "/remove",
        { headers: header }
      );

      if (response.data.resultCode === "000000") {
        if (editIndex >= 0) {
          collections.splice(editIndex, 1);
        }

        ToastUtils.toastUtil("success", "Collection succesfully deleted.");

        setShowDeleteDialog(false);
        setCollectionToEdit({ name: "", description: "" });

        fetchHasEmptyCollection();
      } else {
        ToastUtils.toastUtil("error", response.data.resultMessage);
      }
    } catch (err) {
      ToastUtils.toastUtil("error", err.message || err);
    } finally {
      setProcessing(false);
    }
  };

  const onAddCollectionClick = () => {
    setShowEditDialog(true);
    setIsUpdate(false);
    setCollectionToEdit({ name: "", description: "" });
    setNameValidationMessage("");
  };

  const onUpdateCollectionClick = (collection, index) => {
    setShowEditDialog(true);
    setIsUpdate(true);
    setCollectionToEdit({
      id: collection.id,
      name: collection.name,
      description: collection.description,
    });
    setEditIndex(index);
    setNameValidationMessage("");
  };

  const onDeleteCollectionClick = (collection, index) => {
    setShowDeleteDialog(true);
    setCollectionToEdit({
      id: collection.id,
      name: collection.name,
      description: collection.description,
    });
    setEditIndex(index);
  };

  const onCollectionFieldChange = (event) => {
    if (!collectionToEdit) {
      setCollectionToEdit({});
    }

    const field = event.target.name;
    const coll = { ...collectionToEdit };
    coll[field] = event.target.value;

    setCollectionToEdit(coll);
  };

  const isNameValid = () => {
    if (isBlank(collectionToEdit.name)) {
      setNameValidationMessage("Name cannot be empty");
      return false;
    }

    return true;
  };

  return (
    <Container
      maxWidth="md"
      sx={{ backgroundColor: "white", paddingBottom: "48px" }}
    >
      <Stack>
        <h1>My Collections</h1>

        {fetchingData ? (
          <PageLoadingSpinner />
        ) : !isAuthenticated || !account ? (
          <Box
            sx={{
              height: "200px",
              border: "1px solid",
              borderColor: "divider",
              borderRadius: "8px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack alignItems="center">
              <Typography>You must login to see your collections.</Typography>

              <Box sx={{ marginTop: "32px" }}>
                <ConnectWalletButton />
              </Box>
            </Stack>
          </Box>
        ) : (
          <Stack spacing="16px">
            <Box sx={{ marginTop: "16px" }}>
              {hasEmptyCollection ? (
                <Typography>
                  You can't create new collection because you have empty one.
                </Typography>
              ) : (
                <Button variant="outlined" onClick={onAddCollectionClick}>
                  Add Collection
                </Button>
              )}
            </Box>
            {!collections || !collections.length ? (
              <Box
                sx={{
                  height: "200px",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: "8px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>You don't have any collection</span>
              </Box>
            ) : (
              <Grid
                container
                spacing={2}
                sx={{ marginLeft: "-16px !important" }}
              >
                {collections.map((collection, index) => {
                  return (
                    <Grid item key={collection.id} xs={12} sm={6} md={4}>
                      <Card>
                        <CardContent sx={{ textAlign: "center" }}>
                          <Stack>
                            <Typography
                              variant="h6"
                              textAlign="center"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {collection.name}
                            </Typography>
                            <Typography
                              textAlign="left"
                              sx={{
                                minHeight: "48px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-all",
                                display: "-webkit-box",
                                "-webkit-line-clamp": "2",
                                "-webkit-box-orient": "vertical",
                              }}
                              mt="8px"
                            >
                              {collection.description}
                            </Typography>

                            <Stack
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                              mt="16px"
                            >
                              <Stack flexDirection="row" alignItems="center">
                                <FmdGoodOutlinedIcon
                                  sx={{
                                    color: "gray",
                                    width: "18px",
                                    height: "18px",
                                    marginTop: "-2px",
                                  }}
                                />

                                <Typography
                                  variant="body2"
                                  sx={{ marginLeft: "4px" }}
                                >
                                  {collection.markerCount}
                                </Typography>
                              </Stack>

                              <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ height: "24px" }}
                              >
                                <CalendarMonthIcon
                                  sx={{
                                    color: "gray",
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                                <Typography
                                  variant="body2"
                                  sx={{ marginLeft: "4px" }}
                                >
                                  {new Date(
                                    collection.createTime
                                  ).toLocaleDateString()}
                                </Typography>
                              </Stack>
                            </Stack>

                            <Box
                              sx={{
                                display: "flex",
                                marginTop: "24px",
                                minHeight: "78px",
                              }}
                            >
                              {collection.markerImageList &&
                                collection.markerImageList.map(
                                  (markerImage, i) => {
                                    return (
                                      <Stack
                                        key={markerImage.imageUrl}
                                        sx={{
                                          border: "1px solid",
                                          borderColor: "divider",
                                          borderRadius: "4px",
                                          marginLeft: i > 0 ? "4px" : "0px",
                                          padding: "2px",
                                          width: "72px",
                                          height: "72px",
                                        }}
                                      >
                                        <img
                                          src={`https://gateway.moralisipfs.com/ipfs/${markerImage.imageUrl}`}
                                          alt={markerImage.markerId}
                                          key={markerImage.imageUrl}
                                          style={{
                                            width: "100%",
                                            backgroundColor: "white",
                                          }}
                                        />
                                      </Stack>
                                    );
                                  }
                                )}
                            </Box>
                          </Stack>
                        </CardContent>
                        <CardActions sx={{ justifyContent: "flex-end" }}>
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                          >
                            {collection.notOwnedMarkerCount ? null : (
                              <Stack flexDirection="row">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    onUpdateCollectionClick(collection, index);
                                  }}
                                >
                                  <ModeEditOutlinedIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    onDeleteCollectionClick(collection, index);
                                  }}
                                  size="small"
                                  sx={{ marginLeft: "8px" }}
                                >
                                  <DeleteOutlinedIcon />
                                </IconButton>
                              </Stack>
                            )}
                            <Stack
                              flexDirection="row"
                              width="100%"
                              alignItems="center"
                              justifyContent="flex-end"
                            >
                              <IconButton
                                onClick={() =>
                                  history.push(`/collection/${collection.id}`)
                                }
                                sx={{ marginLeft: "8px" }}
                              >
                                <ArrowForwardIosIcon />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Stack>
        )}
      </Stack>

      <Dialog
        open={showEditDialog}
        disableScrollLock
        sx={{
          "& .MuiPaper-root": {
            margin: isSmall ? "8px" : "",
            width: "100%",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {isUpdate ? "Edit Collection" : "Create Collection"}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: isSmall ? "0px 16px 24px 16px" : "",
          }}
        >
          <Stack>
            <h3 className="formLabel" style={{ marginTop: "0px" }}>
              Name
            </h3>
            <TextField
              id="collection-name-input"
              name="name"
              variant="outlined"
              inputProps={{ maxLength: 64 }}
              value={collectionToEdit.name}
              onChange={onCollectionFieldChange}
            />
            <span className="formInfo">Maximum 64 characters.</span>
            {nameValidationMessage ? (
              <span className="formInfo" style={{ color: "red" }}>
                {nameValidationMessage}
              </span>
            ) : null}

            <h3 className="formLabel">Description</h3>
            <TextField
              id="collection-description-input"
              name="description"
              variant="outlined"
              multiline
              rows={4}
              inputProps={{ maxLength: 1024 }}
              value={collectionToEdit.description}
              onChange={onCollectionFieldChange}
            />
            <span className="formInfo">Maximum 1024 characters.</span>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="outlined" onClick={cancelEdit} disabled={processing}>
            Cancel
          </Button>
          {isUpdate ? (
            <Button
              variant="outlined"
              onClick={updateCollection}
              disabled={processing}
              endIcon={processing ? <ProcessingIcon /> : null}
              sx={{ marginLeft: "8px" }}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="outlined"
              disabled={processing}
              endIcon={processing ? <ProcessingIcon /> : null}
              onClick={createCollection}
            >
              Create
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={showDeleteDialog} disableScrollLock>
        <DialogTitle sx={{ textAlign: "center" }}>Confirm Delete</DialogTitle>
        <DialogContent
          sx={{
            padding: isSmall ? "0px 16px 24px 16px" : "",
          }}
        >
          <Stack>
            <Typography variant="body2">
              Are you sure you want to delete the collection?
            </Typography>
            <Typography variant="h6" sx={{ marginTop: "8px" }}>
              {collectionToEdit.name}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button variant="outlined" onClick={cancelEdit} disabled={processing}>
            Cancel
          </Button>
          <Button
            onClick={deleteCollection}
            variant="outlined"
            color="error"
            disabled={processing}
            sx={{ marginLeft: "8px" }}
            endIcon={processing ? <ProcessingIcon /> : null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
