import { useState } from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

import ConnectWalletDialog from "./connectWalletDialog";

export default function ConnectWalletButton() {
  const [showConnectWalletDialog, setShowConnectWalletDialog] = useState(false);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={() => {
          setShowConnectWalletDialog(true);
        }}
      >
        Connect Wallet
      </Button>
      <ConnectWalletDialog
        showDialog={showConnectWalletDialog}
        onDialogClose={() => {
          setShowConnectWalletDialog(false);
        }}
      />
    </Box>
  );
}
