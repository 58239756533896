import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import ProcessingIcon from "./processingIcon";

export default function ConfirmTransactionDialog({ showDialog, message }) {
  return (
    <Dialog
      open={showDialog}
      aria-labelledby="confirm-transaction-dialog-title"
      aria-describedby="confirm-transaction-dialog-description"
      disableScrollLock
    >
      <DialogTitle
        id="confirm-transaction-dialog-title"
        sx={{ textAlign: "center" }}
      >
        Confirm
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center">
          <ProcessingIcon
            color="primary"
            sx={{ width: "80px", height: "80px" }}
          />

          {message ? (
            <DialogContentText sx={{ textAlign: "center", marginTop: "32px" }}>
              {message}
            </DialogContentText>
          ) : null}

          <DialogContentText sx={{ textAlign: "center", marginTop: "32px" }}>
            Please confirm transaction on your wallet
          </DialogContentText>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
