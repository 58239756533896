export default function DropIcon({ style, color }) {
  return (
    <div style={{ width: "24px", height: "24px", ...style }}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="492.000000pt"
        height="683.000000pt"
        viewBox="0 0 492.000000 683.000000"
        preserveAspectRatio="xMidYMid meet"
        style={{ width: "100%", height: "100%" }}
      >
        <g
          transform="translate(0.000000,683.000000) scale(0.100000,-0.100000)"
          stroke="none"
          fill="currentColor"
        >
          <path
            d="M2110 6793 c-19 -2 -102 -17 -184 -34 -933 -186 -1637 -891 -1838
-1840 -32 -153 -33 -156 -33 -434 0 -300 6 -350 70 -619 154 -652 571 -1480
1194 -2376 283 -408 779 -1044 1025 -1315 l36 -40 36 40 c246 271 742 907
1025 1315 617 887 1030 1704 1188 2350 70 287 76 339 76 645 0 278 -1 281 -33
434 -201 950 -892 1642 -1838 1842 -143 30 -159 32 -419 34 -148 2 -286 1
-305 -2z m514 -679 c359 -55 671 -213 928 -468 425 -422 584 -1023 432 -1631
-124 -493 -393 -1061 -824 -1740 -203 -320 -754 -1085 -781 -1085 -11 0 -387
509 -536 725 -581 845 -963 1618 -1085 2190 -35 168 -49 426 -29 566 55 388
214 713 479 975 251 250 550 404 902 464 137 24 377 26 514 4z"
          />
        </g>
      </svg>
    </div>
  );
}
